import {
  showCriticalErrorNotification,
  hideCriticalErrorNotifications,
  hideErrorNotifications,
  showErrorNotification,
} from './actions';
import { createReducer } from 'typesafe-actions';
import { v4 } from 'uuid';
import { ErrorsState } from './types';
import { combineReducers } from 'redux';

export const initialState: ErrorsState = {
  criticalErrors: [],
  errors: [],
};

const errors = createReducer(initialState.errors)
  .handleAction(showErrorNotification, (state, action) => [
    ...state,
    {
      uuid: v4(),
      ...action.payload,
    },
  ])
  .handleAction(hideErrorNotifications, () => []);

const criticalErrors = createReducer(initialState.criticalErrors)
  .handleAction(showCriticalErrorNotification, (state, action) => [
    ...state,
    {
      uuid: v4(),
      ...action.payload,
    },
  ])
  .handleAction(hideCriticalErrorNotifications, () => []);

export default combineReducers({ errors, criticalErrors });
