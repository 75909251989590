import { PageTitle } from '@hqo/react-components-library/dist/page-header';
import { useDetailsPageHeaderInfo } from 'pages/details/details-page.hooks';
import { DetailsSubtitleWarning } from 'pages/details/details-subtitle-warning';
import { HeaderWrapper, TitleWrapper } from 'pages/details/styles';
import { DetailsSubtitle } from './details-subtitle';

export const DetailsPageHeader = (): JSX.Element => {
  const headerInfo = useDetailsPageHeaderInfo();

  return (
    <HeaderWrapper className="detail-header-wrapper">
      <TitleWrapper className="details-title-wrapper">
        <PageTitle className="page-title" data-cy="page-title">
          {headerInfo?.title}
        </PageTitle>
      </TitleWrapper>
      {headerInfo?.warning ? (
        <DetailsSubtitleWarning warning={headerInfo.warning} />
      ) : (
        <DetailsSubtitle subtitle={headerInfo?.subtitle} />
      )}
    </HeaderWrapper>
  );
};
