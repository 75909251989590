import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { Comment } from '@hqo/react-components-library/dist/icons';
import styled from 'styled-components';

export const CommentButton = styled.button`
  position: relative;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  height: 18px;
  cursor: pointer;
`;

export const NewCommentNotification = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid ${({ theme }) => theme.colors.universal.white};
  width: 8px;
  height: 8px;
  position: absolute;
  top: -2px;
  right: -2px;
`;

export const CommentIcon = styled(Comment)`
  width: 18px;
  height: 18px;
  margin-right: 6px;
`;

export const MessagesButton = styled(Button)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.palette.systemBlack};
  height: 32px;
  width: 132px;
  cursor: pointer;
`;

export const ButtonConatiner = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
