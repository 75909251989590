export interface UploadFilesResponse {
  data: UploadFilesSuccessResponse;
}
export interface UploadFilesSuccessResponse {
  type: FileUploadResponseType;
  files?: File[];
  container_id?: string;
}

export interface FilesState {
  files?: File[];
  fileType: FileUploadResponseType;
  containerId?: string;
  uploadFiles: AsyncActionTypes;
}

export interface UploadFilesRequest {
  files: FormData;
}

export enum FileUploadResponseType {
  FILE = 'files',
  CONTAINER = 'container',
}
