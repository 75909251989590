import React from 'react';

import { FormFieldsProps } from './interfaces';
import { componentsMap } from 'components/service-request-form/components-map';

export const FormFields: React.FC<FormFieldsProps> = ({ fields, fieldsAdditionalProps }: FormFieldsProps) => {
  return (
    <>
      {fields
        .sort((a, b) => a.order - b.order)
        .map(field => {
          const Component = componentsMap[field.type];

          if (!Component) return null;

          return <Component key={field.name} field={field} additionalProps={fieldsAdditionalProps} />;
        })
        .filter(Boolean)}
    </>
  );
};
