import { AdditionalFieldsProps, TextField as TextFieldProps } from '../../interfaces';
import { StyledFormField } from '../../styles';

interface Props {
  field: TextFieldProps;
  additionalProps: AdditionalFieldsProps;
}

export const TextField = ({
  field: { name, label, placeholder, value, required },
  additionalProps: { readOnly, onInputChange, onTextInputFocus, onTextInputBlur },
}: Props): JSX.Element => (
  <StyledFormField
    key={name}
    label={label}
    required={readOnly ? false : required}
    disabled={readOnly}
    value={value}
    placeholder={placeholder}
    onChange={onInputChange(name)}
    onFocus={onTextInputFocus}
    onBlur={onTextInputBlur}
    className="form-textinput"
    data-testid={`${name}-text-field`}
  />
);
