import styled from 'styled-components';
import { Loader } from '@hqo/react-components-library/dist/atoms/loader';

export const MultilevelSelectInputWrapper = styled.div`
  margin-bottom: ${(props: { isLastInput: boolean }) => (props.isLastInput ? '32px' : '8px')};
`;

export const StyledLoader = styled(Loader)`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
  margin-top: 8px;
`;
