import { StyledMobileModal, CloseButton } from './modal.styles';

import React from 'react';

interface MobileModalProps {
  children: React.ReactNode;
  onClose?: VoidFunction;
  hideMobileCloseButton?: boolean;
}

export const MobileModal = ({
  children,
  onClose: handleClose,
  hideMobileCloseButton,
}: MobileModalProps): JSX.Element => {
  return (
    <StyledMobileModal data-testid="mobile-modal-window" data-cy="modal-window">
      {children}
      {!hideMobileCloseButton && (
        <CloseButton
          onClick={handleClose}
          variant="closeButton"
          data-testid="close-visitor-details"
          data-cy="close-visitor-details"
        />
      )}
    </StyledMobileModal>
  );
};
