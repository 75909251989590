import { useIntl } from 'react-intl';
import { useFormikContext, useField } from 'formik';
import { ScheduleRequestFormValues } from './schedule-request-form.hooks';
import { useScheduleTimeOptions } from 'hooks/use-schedule-time-options.hook';
import { Picker } from 'components/shared/fields/picker';
import { FieldWrapper } from './styles';

interface StartTimeFieldProps {
  fieldName: string;
}

export const StartTimeField = ({ fieldName }: StartTimeFieldProps): JSX.Element => {
  const intl = useIntl();
  const {
    values: { startDate },
  } = useFormikContext<ScheduleRequestFormValues>();
  const { currentTimeOptions } = useScheduleTimeOptions(new Date(startDate));
  const [{ value }, { error, touched }, { setValue }] = useField<string>(fieldName);
  const label = intl.formatMessage({ id: 'schedule.start_time' });
  return (
    <FieldWrapper data-testid="schedule-form-start-time">
      <Picker
        items={currentTimeOptions}
        value={value}
        onChange={setValue}
        error={touched && error}
        label={label}
        required
      />
    </FieldWrapper>
  );
};
