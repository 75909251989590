import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'utils/constants';
import { DynamicFormsState } from 'store/dynamic-forms/types';

export const INITIAL_STATE: DynamicFormsState = {
  dynamicFormDefinitions: {},
  getDynamicFormDefinitions: {},
};

const getDynamicFormsRequestHandler = (
  state: DynamicFormsState,
  { payload }: ActionType<typeof actions.getDynamicFormDefinition.request>,
): DynamicFormsState => ({
  ...state,
  getDynamicFormDefinitions: {
    ...state.getDynamicFormDefinitions,
    [payload.formId]: {
      error: null,
      status: ACTION_STATUSES.PENDING,
    },
  },
});

const getDynamicFormsSuccessHandler = (
  state: DynamicFormsState,
  { payload }: ActionType<typeof actions.getDynamicFormDefinition.success>,
): DynamicFormsState => ({
  ...state,
  dynamicFormDefinitions: {
    ...state.dynamicFormDefinitions,
    [payload.params.formId]: payload.response,
  },
  getDynamicFormDefinitions: {
    ...state.getDynamicFormDefinitions,
    [payload.params.formId]: {
      error: null,
      status: ACTION_STATUSES.FULFILLED,
    },
  },
});

const getDynamicFormsFailureHandler = (
  state: DynamicFormsState,
  { payload }: ActionType<typeof actions.getDynamicFormDefinition.failure>,
): DynamicFormsState => ({
  ...state,
  getDynamicFormDefinitions: {
    ...state.getDynamicFormDefinitions,
    [payload.params.formId]: {
      error: payload.error,
      status: ACTION_STATUSES.REJECTED,
    },
  },
});

export default createReducer(INITIAL_STATE)
  .handleAction(actions.getDynamicFormDefinition.request, getDynamicFormsRequestHandler)
  .handleAction(actions.getDynamicFormDefinition.success, getDynamicFormsSuccessHandler)
  .handleAction(actions.getDynamicFormDefinition.failure, getDynamicFormsFailureHandler);
