import moment from 'moment';
import * as yup from 'yup';
import { useMemo, useCallback } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { ServiceRequestFrequencyEnum } from 'store/serviceRequests/enums';

export enum RecurrenceEnd {
  OCCURRENCES_COUNT = 'occurrences_count',
  DATE = 'date',
  NEVER = 'never',
}

export interface RecurringRequestFormValues {
  frequency: ServiceRequestFrequencyEnum;
  recurrenceEnds: RecurrenceEnd;
  endDate: string;
  count: number;
}

const getValidationSchema = (intl: IntlShape) =>
  yup.object().shape({
    frequency: yup
      .string()
      .nullable()
      .oneOf(Object.values(ServiceRequestFrequencyEnum))
      .required(intl.formatMessage({ id: 'field_errors.frequency_required' })),
    recurrenceEnds: yup
      .string()
      .nullable()
      .oneOf(Object.values(RecurrenceEnd))
      .required(intl.formatMessage({ id: 'field_errors.recurrence_variant_required' })),
    endDate: yup.string().when('recurrenceEnds', { is: RecurrenceEnd.DATE, then: yup.string().required() }),
    count: yup.mixed().when('recurrenceEnds', {
      is: RecurrenceEnd.OCCURRENCES_COUNT,
      then: yup
        .number()
        .nullable()
        .integer(intl.formatMessage({ id: 'field_errors.recurrence_count_integer' }))
        .required(intl.formatMessage({ id: 'field_errors.recurrence_count_required' })),
    }),
  });

const defaultValues: RecurringRequestFormValues = {
  frequency: null,
  recurrenceEnds: null,
  endDate: moment().format(),
  count: null,
};

export const useFormikData = (
  initialValues: Partial<RecurringRequestFormValues>,
  submitForm: (values: RecurringRequestFormValues) => void,
) => {
  const intl = useIntl();
  const handleSubmit = useCallback(
    (values: RecurringRequestFormValues) => {
      submitForm(values);
    },
    [submitForm],
  );

  return {
    handleSubmit,
    formikInitialValues: { ...defaultValues, ...initialValues },
    validationSchema: useMemo(() => getValidationSchema(intl), [intl]),
  };
};
