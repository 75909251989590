import { IntlShape, useIntl } from 'react-intl';
import { formatDate, formatTime, TIME_LOWER_CASE_FORMAT } from '../utils/dateFormatting';
import { useSelector } from 'react-redux';
import { selectBuildingLocale, selectBuildingTimeZone } from '../store/building/selectors';
import { ServiceRequestFrequencyEnum } from 'store/serviceRequests/enums';

enum RecurrenceVariant {
  SCHEDULE = 'schedule',
  RECURRENCE_UNITL = 'recurrence-until',
  RECURRENCE_COUNT = 'recurrence-count',
  RECURRENCE_NEVER = 'recurrence-never',
}

const ICON_VARIANTS = {
  [RecurrenceVariant.SCHEDULE]: 'calendar',
  [RecurrenceVariant.RECURRENCE_COUNT]: 'repeat',
  [RecurrenceVariant.RECURRENCE_UNITL]: 'repeat',
  [RecurrenceVariant.RECURRENCE_NEVER]: 'repeat',
};

const getScheduleVariant = (schedule: ServiceRequestSchedule) => {
  if (schedule.frequency === ServiceRequestFrequencyEnum.ONCE) {
    return RecurrenceVariant.SCHEDULE;
  }

  if (schedule.total_run_count) {
    return RecurrenceVariant.RECURRENCE_COUNT;
  }
  if (!schedule.total_run_count && !schedule.end_date) {
    return RecurrenceVariant.RECURRENCE_NEVER;
  }
  return RecurrenceVariant.RECURRENCE_UNITL;
};

const getScheduleText = (intl: IntlShape, schedule: ServiceRequestSchedule, timezone: string, locale: string) =>
  intl.formatMessage(
    { id: 'recurrence_once' },
    {
      date: formatDate(schedule.start_date, timezone, locale),
      time: formatTime(schedule.start_date, timezone, locale, TIME_LOWER_CASE_FORMAT),
    },
  );

const getRecurringUntilText = (intl: IntlShape, schedule: ServiceRequestSchedule, timezone: string, locale: string) =>
  intl.formatMessage(
    { id: 'recurrence_until' },
    {
      frequency: intl.formatMessage({ id: `frequency_values.${schedule.frequency}` }),
      date: formatDate(schedule.end_date, timezone, locale),
    },
  );

const getRecurringNeverText = (intl: IntlShape, schedule: ServiceRequestSchedule) =>
  intl.formatMessage(
    { id: 'recurrence_never' },
    {
      frequency: intl.formatMessage({ id: `frequency_values.${schedule.frequency}` }),
    },
  );

const getRecurringCountText = (intl: IntlShape, schedule: ServiceRequestSchedule) =>
  intl.formatMessage(
    { id: 'recurrence_count' },
    {
      frequency: intl.formatMessage({ id: `frequency_values.${schedule.frequency}` }),
      count: schedule.total_run_count,
    },
  );

const TEXT_BUILDERS = {
  [RecurrenceVariant.SCHEDULE]: getScheduleText,
  [RecurrenceVariant.RECURRENCE_UNITL]: getRecurringUntilText,
  [RecurrenceVariant.RECURRENCE_COUNT]: getRecurringCountText,
  [RecurrenceVariant.RECURRENCE_NEVER]: getRecurringNeverText,
};

export const useScheduleData = (schedule?: ServiceRequestSchedule) => {
  const intl = useIntl();
  const locale = useSelector(selectBuildingLocale);
  const timezone = useSelector(selectBuildingTimeZone);

  if (!schedule) {
    return {};
  }
  const scheduleVariant = getScheduleVariant(schedule);
  const isScheduleVariant = scheduleVariant === RecurrenceVariant.SCHEDULE;

  return {
    text: TEXT_BUILDERS[scheduleVariant](intl, schedule, timezone, locale),
    icon: ICON_VARIANTS[scheduleVariant],
    title: intl.formatMessage({ id: isScheduleVariant ? 'scheduled' : 'recurring' }),
  };
};
