import { useCallback, useState } from 'react';
import { MultilevelSelectInputWrapper, StyledLoader } from './styles';
import { HqoWheelPicker } from '../../../hqo-wheel-picker';
import { MultilevelSelectLevelProps } from './interfaces';
import { useChildOptions, useHandleMultiselectChange, useLoadChildren, useTranslations } from './hooks';
import { Value } from '../../../hqo-wheel-picker/interfaces';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { SelectTw } from '@hqo/react-components-library/dist/atoms/select';
import type { SelectItem } from '@hqo/react-components-library/dist/atoms/select/select.interfaces';

// eslint-disable-next-line max-lines-per-function
export const MultilevelSelectLevel = ({
  currentLevel,
  name,
  required,
  fieldValue,
  onChange,
  options,
}: MultilevelSelectLevelProps): JSX.Element => {
  const [value, setValue] = useState<Value>();
  const handleLoadChildren = useLoadChildren(name, setValue);
  const childOptions = useChildOptions(name, value);
  const hasChildOptions = childOptions.length > 0;
  const hasNextLevel = value && hasChildOptions;
  const childOptionsLoading = value && !hasChildOptions;
  const handleChange = useHandleMultiselectChange(options, handleLoadChildren, onChange, setValue, fieldValue);
  const isSmallViewport = useIsSmallViewportWidth();

  const { label, placeholder } = useTranslations(name, currentLevel);

  const dropdownOptions: SelectItem[] = options?.map(option => {
    return { ...option, label: option.label?.toLocaleString(), value: option.value?.toLocaleString() };
  });
  const handleDropdownChange = useCallback(event => handleChange(event.target.value), [handleChange]);

  return (
    <>
      <MultilevelSelectInputWrapper isLastInput={!value}>
        {isSmallViewport ? (
          <HqoWheelPicker
            required={required}
            items={options}
            value={value || fieldValue}
            onChange={handleChange}
            label={label}
            placeholder={placeholder}
          />
        ) : (
          <SelectTw
            name={name}
            items={dropdownOptions}
            value={value?.toLocaleString() || fieldValue?.toLocaleString()}
            required={required}
            label={label}
            placeholder={placeholder}
            onChange={handleDropdownChange}
          />
        )}
      </MultilevelSelectInputWrapper>
      {childOptionsLoading && <StyledLoader size="32px" />}
      {hasNextLevel && (
        <MultilevelSelectLevel
          currentLevel={currentLevel + 1}
          name={name}
          required={required}
          fieldValue={fieldValue}
          onChange={onChange}
          options={childOptions}
        />
      )}
    </>
  );
};
