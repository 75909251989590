import { PinWrapper, StyledIcon } from 'components/floor-plan/styles';
import React from 'react';

interface PinProps {
  clickX: number;
  clickY: number;
  showPin: boolean;
}

export const Pin = React.forwardRef<HTMLDivElement, PinProps>((props, ref) => {
  return (
    <PinWrapper
      clickX={props.clickX}
      clickY={props.clickY}
      showPin={props.showPin}
      ref={ref as React.MutableRefObject<HTMLDivElement>}
      data-testId="location-pin"
    >
      <StyledIcon icon={['fas', 'location-dot']} />
    </PinWrapper>
  );
});

Pin.displayName = 'Pin';
