import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { saveExternalConfig } from 'store/config/actions';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { ViewParams } from 'types';

export const useViewParams = () => {
  const searchParams = useSearchParams<Partial<ViewParams>>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchParams) {
      dispatch(saveExternalConfig(searchParams));
    }
  }, [dispatch, searchParams]);
};
