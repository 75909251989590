import { Dispatch, useCallback } from 'react';
import { FieldTranslationProperties } from '../../interfaces';
import { useIntl } from 'react-intl';
import { useLookupData } from 'hooks/use-lookup-data';
import { MultilevelSelectItems } from 'types/lookup-data';
import { useSelector } from 'react-redux';
import { selectCachedValue } from 'store/lookup-data/selectors';
import { Value } from '../../../hqo-wheel-picker/interfaces';

interface Translations {
  label: string;
  placeholder: string;
}

export const useTranslations = (name: string, currentLevel: number): Translations => {
  const intl = useIntl();

  const getTranslation = useCallback(
    (property: FieldTranslationProperties) =>
      intl.formatMessage({ id: `creation_form.${name}_${currentLevel}.${property}` }),
    [currentLevel, intl, name],
  );

  const label = getTranslation(FieldTranslationProperties.LABEL);
  const placeholder = getTranslation(FieldTranslationProperties.PLACEHOLDER);

  return { label, placeholder };
};

type HandleChange = (value: Value) => void;
type HandleLoadChildren = (newValue: Value) => Promise<void>;

export const useLoadChildren = (
  name: string,
  setValue: Dispatch<React.SetStateAction<Value | undefined>>,
): HandleLoadChildren => {
  const getLookupData = useLookupData();
  const handleLoadChildren = useCallback(
    async (newValue: Value) => {
      setValue(newValue);
      getLookupData(name, newValue);
    },
    [setValue, getLookupData, name],
  );

  return handleLoadChildren;
};

export const useChildOptions = (name: string, value: Value): MultilevelSelectItems => {
  const options = useSelector(selectCachedValue(name, value));

  return options || [];
};

export const useHandleMultiselectChange = (
  options: MultilevelSelectItems,
  handleLoadChildren: HandleLoadChildren,
  onChange: (value?: Value) => void,
  setValue: Dispatch<React.SetStateAction<Value | undefined>>,
  fieldValue?: Value,
): HandleChange => {
  const resetFieldValue = useCallback(() => {
    if (fieldValue !== undefined) {
      onChange(undefined);
    }
  }, [onChange, fieldValue]);

  return useCallback(
    async (newValue: Value) => {
      setValue(undefined);

      const selectedItem = options?.find(item => item.value === newValue);

      if (selectedItem?.hasChildren) {
        resetFieldValue();
        handleLoadChildren(newValue);
      } else {
        onChange(newValue);
      }
    },
    [handleLoadChildren, onChange, options, setValue, resetFieldValue],
  );
};
