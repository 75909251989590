import { useIntl } from 'react-intl';
import { PageTitle } from '@hqo/react-components-library/dist/page-header';
import { FloorPlanPinner } from 'components/floor-plan';
import { FloorPlanPinWrapper, FloorPlanSubtitle } from '../styles';
import { useFloorPlanPinPage } from './floor-plan-pin-page.hooks';
import { Buttons } from './buttons';

interface FloorPlanPinPageProps {
  closeFloorPlanStep: () => void;
}

export const FloorPlanPinPage = ({ closeFloorPlanStep }: FloorPlanPinPageProps): JSX.Element => {
  const intl = useIntl();
  const { floorPlan, coordinates, setCoordinates, showPin, setShowPin, removePin, savePin } =
    useFloorPlanPinPage(closeFloorPlanStep);

  return (
    <FloorPlanPinWrapper data-testId="floor-plan-pin-contents">
      <PageTitle>{intl.formatMessage({ id: 'floor_plan_pin.title' })}</PageTitle>
      <FloorPlanSubtitle>{intl.formatMessage({ id: 'floor_plan_pin.subtitle' })}</FloorPlanSubtitle>
      <FloorPlanPinner
        imgSrc={floorPlan.url}
        height={floorPlan.height}
        width={floorPlan.width}
        coordinates={coordinates}
        setCoordinates={setCoordinates}
        showPin={showPin}
        setShowPin={setShowPin}
      />
      <Buttons showPin={showPin} savePin={savePin} removePin={removePin} />
    </FloorPlanPinWrapper>
  );
};
