import {
  IconCol,
  MessageCol,
  SkeletonBubble,
  SkeletonIcon,
  SkeletonName,
  ChatSkeletonWrapper,
} from 'pages/details/styles';
import { ChatSkeletonVariants } from 'pages/details/utils';
import { Ref } from 'react';

interface Props {
  variant: ChatSkeletonVariants;
  ref?: Ref<HTMLDivElement>;
}

export const SkeletonMessage = ({ variant, ref }: Props): JSX.Element => (
  <ChatSkeletonWrapper
    ref={ref}
    className="chat-bubble-skeleton-wrapper"
    variant={variant}
    data-testid={`${variant}-skeleton`}
  >
    <IconCol>
      <SkeletonIcon className="skeleton-icon" />
    </IconCol>
    <MessageCol>
      <SkeletonName className="skeleton-name" variant={variant} />
      <SkeletonBubble />
    </MessageCol>
  </ChatSkeletonWrapper>
);
