import { CategoryDetails, Subcategory } from 'pages/details/styles';
import { AssigneeBlock } from 'pages/details/assignee-block';

interface Props {
  currentServiceRequest: ServiceRequest;
}

export const CategoryDetailsBlock = ({
  currentServiceRequest: { description, assignee, secondary_type: secondaryType },
}: Props): JSX.Element => (
  <CategoryDetails data-testid="category-details" data-cy="category-details">
    {secondaryType && <Subcategory>{secondaryType.name}</Subcategory>}
    {description}
    {assignee && <AssigneeBlock assignee={assignee} />}
  </CategoryDetails>
);
