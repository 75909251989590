import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectBrandTheme } from 'store/theme/selectors';
import { DefaultThemeWithFonts } from '@hqo/react-components-library/dist/tenant-web/elements/theme';
import { hqoTheme } from '@hqo/react-components-library/dist/hqo-theme-provider';

export const useBuildingTheme = (): DefaultThemeWithFonts => {
  const brandTheme = useSelector(selectBrandTheme);

  return useMemo(() => {
    if (brandTheme) {
      return {
        colors: {
          ...hqoTheme.colors,
          $primary: brandTheme.primary_color,
          $secondary: brandTheme.secondary_color,
          primary: brandTheme.primary_color,
          secondary: brandTheme.secondary_color,
          fontColor: brandTheme.primary_font_color ?? hqoTheme.colors.greys.adminBlack,
        },
        fonts: brandTheme.font?.body?.font_family ? [`${brandTheme.font?.body?.font_family}`, ...hqoTheme.fonts] : null,
      };
    }
    return hqoTheme;
  }, [brandTheme]);
};
