import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getCriticalErrorNotifications } from '../../store/errors/selectors';
import { useHistory } from 'react-router-dom';
import { ERR_DEFAULT } from '../../utils/constants';
import { hideCriticalErrorNotifications } from 'store/errors/actions';
import { goBack } from 'connected-react-router';
import { ErrorNotification } from '@hqo/react-components-library/dist/molecules/errorNotification';
import { Wrapper } from 'pages/error/styles';
import { getErrorCode } from 'components/error-modal/error-modal';

export const ErrorPage = (): JSX.Element => {
  const { formatMessage, messages } = useIntl();
  const { hasErrorNotifications, criticalErrors } = useSelector(getCriticalErrorNotifications);

  const dispatch = useDispatch();
  const { action } = useHistory();
  const handleClick = useCallback(() => {
    if (action !== 'POP') {
      dispatch(goBack());
      dispatch(hideCriticalErrorNotifications());
    }
  }, [action, dispatch]);

  const errorCode = hasErrorNotifications ? getErrorCode(criticalErrors, messages, 'criticalErrors') : ERR_DEFAULT;

  return (
    <Wrapper>
      <ErrorNotification
        title={formatMessage({ id: `notifications.criticalErrors.${errorCode}.title` })}
        description={formatMessage({ id: `notifications.criticalErrors.${errorCode}.description` })}
        buttonText={formatMessage({ id: `notifications.criticalErrors.${errorCode}.buttonText` })}
        onClick={handleClick}
      />
    </Wrapper>
  );
};
