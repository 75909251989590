import { ErrorBoundary, init as initSentry } from '@sentry/react';

import { App } from 'components/app';
import { AppThemeProvider } from '@hqo/react-components-library/dist/tenant-web/elements/theme';
import { BrowserRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { DeviceProvider } from '@hqo/react-components-library/dist/device';
import { ErrorPage } from 'pages/error';
import { Integrations } from '@sentry/tracing';
import { IntlProvider } from '../intl-provider';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { history } from 'store/router/history';
import { hqoTheme } from '@hqo/react-components-library/dist/hqo-theme-provider';
import { store } from 'store';
import { MiniappSdkProvider } from '../miniapp-sdk-provider/miniapp-sdk-provider';
import { initFontAwesomeIconsLibrary } from 'components/root/root.utils';

initFontAwesomeIconsLibrary();

initSentry({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_VERSION,
  autoSessionTracking: true,
  environment: process.env.NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
});

export const Root: React.FC = (): JSX.Element => (
  <ReduxProvider store={store}>
    <MiniappSdkProvider>
      <AppThemeProvider theme={hqoTheme}>
        <BrowserRouter>
          <ConnectedRouter history={history}>
            <IntlProvider>
              <ErrorBoundary fallback={<ErrorPage />}>
                <DeviceProvider>
                  <App />
                </DeviceProvider>
              </ErrorBoundary>
            </IntlProvider>
          </ConnectedRouter>
        </BrowserRouter>
      </AppThemeProvider>
    </MiniappSdkProvider>
  </ReduxProvider>
);
