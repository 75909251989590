import { Redirect, Route, RouteProps } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';

import { currentUser } from 'store/user/selectors';

interface PrivateRouteProps extends RouteProps {
  redirectTo?: string;
}
export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  redirectTo = '/',
  ...routeProps
}: PrivateRouteProps): JSX.Element | null => {
  const user = useSelector(currentUser);

  if (user) {
    return <Route {...routeProps} />;
  }

  return <Redirect to={redirectTo} />;
};
