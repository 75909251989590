import { SubTitle, SubTitleWrapper } from 'pages/details/styles';

interface DetailsSubtitleProps {
  subtitle?: string;
}

export const DetailsSubtitle = ({ subtitle }: DetailsSubtitleProps): JSX.Element => (
  <SubTitleWrapper className="details-subtitle-wrapper">
    <SubTitle className="details-subtitle" data-testid="details-page-subtitle">
      {subtitle}
    </SubTitle>
  </SubTitleWrapper>
);
