import { MultilevelSelectItems } from 'types/lookup-data';
import { Value, Items } from '../hqo-wheel-picker/interfaces';

export enum FORM_FIELDS {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  SELECT = 'select',
  FILE = 'file',
  SWITCH = 'switch',
  MULTILEVEL_SELECT = 'multilevel_select',
}

export enum FieldTranslationProperties {
  LABEL = 'label',
  PLACEHOLDER = 'placeholder',
}

interface CommonFieldProps {
  type: FORM_FIELDS;
  name: string;
  order: number;
}

export interface MultilevelSelectField extends CommonFieldProps {
  type: FORM_FIELDS.MULTILEVEL_SELECT;
  required: boolean;
  value?: Value;
  options: MultilevelSelectItems;
}

export interface SwitchFieldProperties extends CommonFieldProps {
  type: FORM_FIELDS.SWITCH;
  persistValueToForm?: boolean;
  switchDetailsLabel?: JSX.Element;
  label: string;
  switchDetailsEditButtonLabel?: string;
  value?: boolean;
}

export interface FileField extends CommonFieldProps {
  type: FORM_FIELDS.FILE;
  required: boolean;
  label: string;
  value?: string[];
  accept?: string;
  fileLimit?: number;
  maxSizeTotal?: number;
  maxSizePerFile?: number;
}

export interface SelectField extends CommonFieldProps {
  type: FORM_FIELDS.SELECT;
  options: Items;
  label: string;
  placeholder: string;
  required: boolean;
  value?: Value;
}

export interface TextareaField extends CommonFieldProps {
  type: FORM_FIELDS.TEXTAREA;
  label: string;
  required?: boolean;
  visible?: boolean;
  visibilityControlledBy?: string;
  value?: string;
  placeholder?: string;
}

export interface TextField extends CommonFieldProps {
  type: FORM_FIELDS.TEXT;
  label: string;
  required?: boolean;
  value?: string;
  placeholder?: string;
}

export type ServiceRequestField =
  | MultilevelSelectField
  | SwitchFieldProperties
  | FileField
  | SelectField
  | TextareaField
  | TextField;

export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export interface AdditionalFieldsProps {
  readOnly: boolean;
  onInputChange: (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  onTextInputFocus: () => void;
  onTextInputBlur: () => void;
  handleSelectChange: (name: string, value?: Value | null) => void;
  onFileDelete: (files: Array<File>) => void;
  onFileUpload: (files: Array<File>) => void;
  onRecurrenceSwitchChange: (name: string, value: boolean) => void;
  onSwitchDetailsEdit: (name: string) => void;
  onStatefulSwitchChange: (name: string) => void;
}

export interface ServiceRequestFormProps extends Omit<AdditionalFieldsProps, 'onInputChange'> {
  fields: ServiceRequestField[];
  className?: string;
  title: string;
  subtitle?: string;
  handleInputChange: (name: string, value?: string) => void;
  buttonLabel: string;
  buttonDisabled: boolean;
  onSubmit: () => void;
  statusText?: string;
  statusMessage?: string;
  hideActionButton?: boolean;
  buttonVariant?: ButtonVariant;
}

export interface FormFieldsProps {
  fields: ServiceRequestField[];
  fieldsAdditionalProps: AdditionalFieldsProps;
}
