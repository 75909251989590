import { useSteps } from 'hooks/use-steps.hook';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createServiceRequest, updateSetRecurringServiceRequest } from 'store/serviceRequests/actions';
import { DynamicFormDto } from '@hqo/dynamic-forms-sdk/types';
import { selectShowRecurringServiceRequest } from 'store/serviceRequests/selectors';
import { RecurringRequestFormValues } from './recurring-request-form/recurring-request-form.hooks';
import { openRecurringStep as trackRecurring } from 'utils/trackingUtils';
import { NewRequestData } from '../create-service-request-step-content';

export enum ServiceRequestSteps {
  DATA = 'data',
  RECURRING = 'recurring',
  FORM = 'form',
}

export const useServiceRequestSteps = (isDynamicFormExists: boolean) => {
  const isRecurringActive = useSelector(selectShowRecurringServiceRequest);

  const steps = [
    ServiceRequestSteps.DATA,
    isRecurringActive && ServiceRequestSteps.RECURRING,
    isDynamicFormExists && ServiceRequestSteps.FORM,
  ].filter(Boolean);
  const { step, index, openNextStep, openPreviousStep, openStepByIndex } = useSteps(steps, ServiceRequestSteps.DATA);

  return {
    index,
    isDataOpened: step === ServiceRequestSteps.DATA,
    isFormOpened: step === ServiceRequestSteps.FORM,
    isRecurringOpen: step === ServiceRequestSteps.RECURRING,
    openNextStep,
    openPreviousStep,
    openStepByIndex,
    steps,
  };
};

export const useRequestData = (openNextStep: VoidFunction) => {
  const [newRequestData, setRequestData] = useState<NewRequestData>();

  const saveNewRequestData = useCallback(
    (data: NewRequestData) => {
      setRequestData(data);
      openNextStep();
    },
    [openNextStep],
  );

  return { newRequestData, saveNewRequestData };
};

const useSubmitFormStep = (newRequestData: NewRequestData) => {
  const dispatch = useDispatch();

  return useCallback(
    (dynamicForm: DynamicFormDto) => {
      dispatch(
        createServiceRequest.request({
          ...newRequestData,
          custom_form: dynamicForm,
        }),
      );
    },
    [dispatch, newRequestData],
  );
};

export const useServiceRequestSubmit = (openNextStep: VoidFunction, hasSecondStep: boolean) => {
  const { newRequestData, saveNewRequestData } = useRequestData(openNextStep);
  const dispatch = useDispatch();

  const submitDataStep = useCallback(
    (data: NewRequestData) => {
      if (hasSecondStep) {
        saveNewRequestData(data);
      } else {
        dispatch(createServiceRequest.request(data));
      }
    },
    [dispatch, hasSecondStep, saveNewRequestData],
  );

  return {
    submitDataStep,
    submitFormStep: useSubmitFormStep(newRequestData),
  };
};

export const useRecurringStep = (
  isRecurringOpen: boolean,
  steps: ServiceRequestSteps[],
  openStepByIndex: (stepIndex: number) => void,
) => {
  const dispatch = useDispatch();
  const dataIndex = steps.findIndex(step => step === ServiceRequestSteps.DATA);
  useEffect(() => {
    const recurringIndex = steps.findIndex(step => step === ServiceRequestSteps.RECURRING);
    if (recurringIndex > 0) {
      openStepByIndex(recurringIndex);
    }
  }, [openStepByIndex, steps]);

  const openRecurringStep = useCallback(() => {
    trackRecurring();
    dispatch(updateSetRecurringServiceRequest(true));
  }, [dispatch]);

  const closeRecurringStep = useCallback(() => {
    if (isRecurringOpen) {
      dispatch(updateSetRecurringServiceRequest(false));
      openStepByIndex(dataIndex);
    }
  }, [dataIndex, dispatch, isRecurringOpen, openStepByIndex]);

  return { openRecurringStep, closeRecurringStep };
};

export const useRecurringData = (closeRecurringStep: () => void) => {
  const [data, setData] = useState<RecurringRequestFormValues>();

  const saveRecurringDataAndCloseStep = useCallback(
    (values: RecurringRequestFormValues) => {
      setData(values);
      closeRecurringStep();
    },
    [closeRecurringStep],
  );
  const clearRecurringData = () => {
    setData(null);
  };
  return { recurringData: data, saveRecurringDataAndCloseStep, clearRecurringData };
};
