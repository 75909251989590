import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { addPinToFloorPlan, removePinFromFloorPlan, updateFloorPlanPin } from 'store/serviceRequests/actions';
import { selectCurrentServiceRequest } from 'store/serviceRequests/selectors';

const useCreateOrUpdatePin = (dispatch: Dispatch, floorPlan: FloorPlan, coordinates: PinCoordinates) => {
  return useCallback(() => {
    if (floorPlan.pin) {
      dispatch(
        updateFloorPlanPin.request({
          floor_plan_id: floorPlan.id,
          floor_plan_pin: {
            id: floorPlan.pin.id,
            coordinates,
          },
        }),
      );
    } else {
      dispatch(
        addPinToFloorPlan.request({
          floor_plan_id: floorPlan.id,
          pin_coordinates: coordinates,
        }),
      );
    }
  }, [coordinates, dispatch, floorPlan.id, floorPlan.pin]);
};

const useSavePin = (
  showPin: boolean,
  floorPlan: FloorPlan,
  coordinates: PinCoordinates,
  closeFloorPlanStep: () => void,
) => {
  const dispatch = useDispatch();
  const createOrUpdatePin = useCreateOrUpdatePin(dispatch, floorPlan, coordinates);

  return useCallback(() => {
    if (showPin && JSON.stringify(floorPlan.pin?.coordinates) !== JSON.stringify(coordinates)) {
      createOrUpdatePin();
    } else if (!showPin && floorPlan.pin) {
      dispatch(removePinFromFloorPlan.request());
    }
    closeFloorPlanStep();
  }, [closeFloorPlanStep, coordinates, createOrUpdatePin, dispatch, floorPlan.pin, showPin]);
};

export const useFloorPlanPinPage = (closeFloorPlanStep: () => void) => {
  const floorPlan = useSelector(selectCurrentServiceRequest)?.floor_plan;
  const [showPin, setShowPin] = useState(!!floorPlan.pin);
  const [coordinates, setCoordinates] = useState<PinCoordinates>(floorPlan.pin?.coordinates || { x: 0, y: 0 });
  const removePin = useCallback(() => setShowPin(false), []);

  return {
    floorPlan,
    coordinates,
    setCoordinates,
    showPin,
    setShowPin,
    removePin,
    savePin: useSavePin(showPin, floorPlan, coordinates, closeFloorPlanStep),
  };
};
