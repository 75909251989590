import { useCallback } from 'react';
import { SwitchFieldProperties } from '../../interfaces';

export const useSwitchProps = (
  field: SwitchFieldProperties,
  onStatefulSwitchChange: (name: string) => void,
  onRecurrenceSwitchChange: (name: string, value: boolean) => void,
) => {
  let handleChange;
  let checked: boolean;
  let showSwitchDetails: boolean;
  const { name, value, persistValueToForm, switchDetailsLabel } = field;
  const handleRecurrenceSwitchChange = useCallback(
    event => onRecurrenceSwitchChange(name, event.target.checked),
    [name, onRecurrenceSwitchChange],
  );
  const handleStatefulSwitchChange = useCallback(() => onStatefulSwitchChange(name), [name, onStatefulSwitchChange]);

  if (persistValueToForm) {
    handleChange = handleStatefulSwitchChange;
    checked = !!value;
    showSwitchDetails = false;
  } else {
    handleChange = handleRecurrenceSwitchChange;
    checked = !!switchDetailsLabel;
    showSwitchDetails = !!switchDetailsLabel;
  }

  return { handleChange, checked, showSwitchDetails };
};
