import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetCreateServiceRequest } from '../../../store/serviceRequests/actions';
import { resetFiles } from '../../../store/files/actions';

export const useResetRequestCreation = (shouldReset: boolean) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (shouldReset) {
      history.replace('/service-requests/error');
      dispatch(resetCreateServiceRequest());
      dispatch(resetFiles());
    }
  }, [dispatch, history, shouldReset]);
};
