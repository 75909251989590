import { MetadataFlags } from 'shared/consts/metadata-flags-enum';
import { selectCurrentServiceRequest } from 'store/serviceRequests/selectors';
import { useFieldMetadata } from 'hooks/use-ui-metadata.hook';
import { useSelector } from 'react-redux';

export const useFeedAvailability = () => {
  const messageFeedEnabled = useFieldMetadata(MetadataFlags.MESSAGE_FEED_ENABLED);
  const request = useSelector(selectCurrentServiceRequest);
  const messagesExist = !!request?.messages;

  return messagesExist && messageFeedEnabled;
};
