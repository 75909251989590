import { useCallback } from 'react';
import moment, { Moment } from 'moment';
import { useDatePickerButtons } from './use-date-select-buttons.hook';
import { HqoDatePickerStyled } from './styles';
import { UserDialogButton } from '@hqo/react-components-library/dist/molecules/modals/userDialogModal';
import { useLocale } from 'hooks/use-locale.hook';

interface DateSelectProps {
  label?: string;
  placeholder?: string;
  required?: boolean;
  value: string;
  onChange: (value: string) => void;
}

export const DateSelect = ({ label, placeholder, required, value, onChange }: DateSelectProps): JSX.Element => {
  const locale = useLocale();
  const handleChange = useCallback(
    (date: Moment) => {
      onChange(date.format());
    },
    [onChange],
  );
  const buttons = useDatePickerButtons(handleChange);

  return (
    <HqoDatePickerStyled
      required={required}
      value={moment(value)}
      onChange={handleChange}
      locale={locale}
      label={label}
      placeholder={placeholder}
      minDate={moment()}
      isFlexStyle
      buttons={buttons as UserDialogButton[]}
      dataTestId="date-picker"
    />
  );
};
