export const mapSRLocationField = (response: ServiceRequestUnmappedResponse): ServiceRequest => {
  if (!response.location) {
    return response as ServiceRequest;
  }

  return {
    ...response,
    location: Array.isArray(response.location) ? response.location : [response.location],
  };
};
