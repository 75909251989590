import { Button } from '@hqo/react-components-library/dist/atoms/button';
import styled from 'styled-components';

export const EstimateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  padding: 24px 22px 30px;
  border: 1px solid ${({ theme }) => theme.colors.greys.adminGreyMedium};
  border-radius: 8px;
`;

export const EstimateContainer = styled.div`
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-drection: row;
  align-self: flex-end;
  width: 100%;
  margin-top: 20px;

  .estimate-approve-button {
    margin-right: 22px;
  }
`;

export const StyledButton = styled(Button)`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  height: 48px;
  width: 100%;
`;

export const EstimateFigures = styled.span`
  font-weight: 500;
`;
