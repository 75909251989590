import { useDispatch, useSelector } from 'react-redux';

import { UserEvents } from 'shared/consts';
import { getRequestsInitializedState } from 'store/serviceRequests/selectors';
import { initializeServiceRequests } from 'store/serviceRequests/actions';
import { track } from '@hqo/web-tracking';
import { useAnalytics } from 'hooks/use-analytics.hook';
import { useEffect } from 'react';

export const useAppTracking = (): void => {
  const dispatch = useDispatch();
  const requestsInitializedState = useSelector(getRequestsInitializedState);
  useAnalytics();
  useEffect(() => {
    if (requestsInitializedState) {
      track(
        UserEvents.SERVICE_REQUESTS_INITIALIZED,
        {
          type: 'impression',
        },
        { sendToPendo: true, sendToHqoTracking: false },
      );

      dispatch(initializeServiceRequests());
    }
  }, [requestsInitializedState, dispatch]);
};
