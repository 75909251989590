import {
  faChevronLeft,
  faComment,
  faRedo,
  faTimes,
  faExclamationTriangle as fasExclamationTriangle,
  faLocationDot,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faExclamationCircle,
  faExclamationTriangle,
  faMapMarkerAlt,
  faImage,
  faVideo,
  faMusic,
  faFile,
  faRepeat,
  faCalendar,
} from '@fortawesome/pro-regular-svg-icons';

import { faWrench, faKey } from '@fortawesome/pro-light-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

export function initFontAwesomeIconsLibrary() {
  library.add(
    faWrench,
    faKey,
    faExclamationCircle,
    faExclamationTriangle,
    fasExclamationTriangle,
    faMapMarkerAlt,
    faTimes,
    faComment,
    faChevronLeft,
    faRedo,
    faImage,
    faVideo,
    faMusic,
    faFile,
    faRepeat,
    faCalendar,
    faLocationDot,
  );
}
