import { AssigneeName, AssigneeBody } from 'pages/details/styles';
import { useIntl } from 'react-intl';
import { Avatar } from 'pages/details/feed-content/avatar';

interface Props {
  assignee: Assignee;
}

export const AssigneeBlock = ({ assignee: { first_name, last_name, avatar } }: Props): JSX.Element => {
  const intl = useIntl();
  return (
    <AssigneeBody>
      {intl.formatMessage({ id: 'request_assigned' })}
      <AssigneeName>
        <Avatar first_name={first_name} last_name={last_name} avatar_src={avatar} assignee />
        {first_name} {last_name}
      </AssigneeName>
    </AssigneeBody>
  );
};
