import * as userActions from './actions';

import { UserState } from './types';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

export const initialState: UserState = {
  user: null,
  loading: false,
  error: null,
};

const loading = createReducer(initialState.loading)
  .handleAction(userActions.getCurrentUser.request, () => true)
  .handleAction(userActions.getCurrentUser.success, () => false)
  .handleAction(userActions.getCurrentUser.failure, () => false);

const user = createReducer(initialState.user)
  .handleAction(userActions.getCurrentUser.success, (_, action) => action.payload)
  .handleAction(userActions.getCurrentUser.failure, () => null);

const error = createReducer(initialState.error)
  .handleAction(userActions.getCurrentUser.request, () => null)
  .handleAction(userActions.getCurrentUser.success, () => null)
  .handleAction(userActions.getCurrentUser.failure, (_state, action) => action.payload.error);

export default combineReducers({
  loading,
  user,
  error,
});
