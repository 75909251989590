import { MessageBar } from '@hqo/react-components-library/dist/molecules/message-bar';
import styled from 'styled-components';

export const RequestFeedWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  min-height: 0;
`;

export const FeedInputWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const StyledMessageBar = styled(MessageBar)`
  .message-textarea {
    box-sizing: border-box;
  }
`;
