import { HqoTheme, hqoTheme } from '@hqo/react-components-library/dist/hqo-theme-provider';

import { AppContentWrapperStyles } from 'components/app-content-wrapper/app-content-wrapper.styles';
import { AppThemeProvider } from '@hqo/react-components-library/dist/tenant-web/elements/theme';
import { Container } from 'components/hocs/shared-styles';
import { CustomFonts } from 'components/custom-fonts';
import React from 'react';
import { Router } from 'components/router';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { SwitchAltBuildingModal } from '../alt-buildings-modal/alt-building-modal';
import { useAppLoading } from './use-app-loading.hook';
import { useAppTracking } from './use-app-tracking.hook';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks';
import { useLaunchDarklyIdentify } from 'hooks/use-launch-darkly-identify.hook';

export const App: React.FC = (): JSX.Element => {
  useLaunchDarklyIdentify();
  useAppTracking();
  const { loading, altBuildings, theme } = useAppLoading();
  const { value: visible, setFalse: handleClose } = useBooleanState(true);
  if (loading) {
    return (
      <Container isLoading>
        <Spinner size="10em" color={hqoTheme.colors.$greyLight} />
      </Container>
    );
  }

  if (altBuildings?.length && visible) {
    return (
      <>
        <AppContentWrapperStyles customThemeFonts={theme.fonts} />
        <CustomFonts />
        <AppThemeProvider theme={theme as HqoTheme}>
          <SwitchAltBuildingModal altBuildings={altBuildings} isVisible={visible} onClose={handleClose} />
        </AppThemeProvider>
      </>
    );
  }

  return <Router />;
};
