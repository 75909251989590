import { useCallback, useState } from 'react';
import { DynamicFormsClient } from '@hqo/dynamic-forms-client';
import { useDynamicFormDefinition } from '../../../../store/dynamic-forms/hooks';
import { useResetRequestCreation } from '../use-reset-request-creation.hook';
import { Container } from './custom-form-step.styles';
import { DynamicFormDto, FormData } from '@hqo/dynamic-forms-sdk/types';
import { useDynamicFormsLocaleConfig, useDynamicFormsLocalizationValues } from './custom-form-step.hooks';
import { DynamicFormData } from '@hqo/dynamic-forms-sdk/form';
import { CustomFormSkeleton } from './custom-form-skeleton';

interface CustomForm {
  onSubmit: (formDefinition: DynamicFormDto) => void;
  formId: string;
}

export const CustomFormStep = ({ onSubmit: handleSubmit, formId }: CustomForm): JSX.Element => {
  const [formDefinition, isFormDefintionLoading, error] = useDynamicFormDefinition(formId);
  const [index, setIndex] = useState(0);
  const isFormLoaded = formDefinition && !isFormDefintionLoading;
  const localizationValues = useDynamicFormsLocalizationValues();
  const localeConfig = useDynamicFormsLocaleConfig();
  const handleDynamicFormSubmit = useCallback(
    (formData: FormData.DynamicFormDataDto) => {
      handleSubmit(formDefinition.toJSON(DynamicFormData.fromFormDataJson(formData)));
    },
    [handleSubmit, formDefinition],
  );

  useResetRequestCreation(!!error);

  return !isFormLoaded ? (
    <CustomFormSkeleton />
  ) : (
    <Container>
      <DynamicFormsClient
        formDefinition={formDefinition}
        index={index}
        onChangeIndex={setIndex}
        onSubmit={handleDynamicFormSubmit}
        localizationValues={localizationValues}
        localeConfig={localeConfig}
      />
    </Container>
  );
};
