import { AttachmentWrapper } from 'pages/details/styles';

interface Props {
  fileSrc: string;
  alt: string;
}

export const ViewAttachment = ({ fileSrc, alt }: Props) => {
  return (
    <AttachmentWrapper data-testid="view-attachment">
      <img src={fileSrc} alt={alt} width="100%" />
    </AttachmentWrapper>
  );
};
