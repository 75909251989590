import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { DIMENSIONS } from 'utils/constants';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 100px;
  padding-top: 135px;
  justify-content: space-between;

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 0 16px;
    padding-top: 14px;
  }
`;

export const TabsContainer = styled.div`
  max-width: 700px;
  margin: 0 auto;
  width: 100%;
  padding: 11px 0 32px;

  .active {
    font-weight: 500;
  }
`;

export const EmptyContainer = styled.div`
  margin-top: 80px;
`;

export const ListContainer = styled.div`
  min-height: 300px;
  margin-bottom: 88px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    flex-direction: column;
  }
`;

export const WebButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.$primary};
  outline-color: ${({ theme }) => theme.colors.$primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.$primary};
  }

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    display: none;
  }
`;

export const MobileButtonContainer = styled.div`
  display: none;
  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.$white};
    display: flex;
    height: 75px;
    left: 0px;
    bottom: 0px;
    position: fixed;
  }
`;

export const MobileButton = styled(WebButton)`
  display: none;

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    display: flex;
    height: 50px;
    bottom: 35px;
    left: 10px;
    width: 95%;
    position: fixed;
    font-size: 16px;
    font-weight: 500;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
