import { SkeletonTile } from 'components/request-skeleton-loader.tsx/styles';

export const RequestSkeletonLoader = (): JSX.Element => {
  return (
    <>
      <SkeletonTile data-testid="request-skeleton-loader" />
      <SkeletonTile data-testid="request-skeleton-loader" />
      <SkeletonTile data-testid="request-skeleton-loader" />
    </>
  );
};
