import { DEFAULT_LOCALE, useLocaleData } from 'components/intl-provider/intl-provider.hooks';
import { useLocale } from 'hooks/use-locale.hook';

import React, { ComponentProps } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { DEFAULT_RICH_TEXT_ELEMENTS } from './rich-text-elements';

interface IntlProviderProps extends Partial<ComponentProps<typeof ReactIntlProvider>> {
  children: React.ReactNode;
}

export const IntlProvider: React.FC<IntlProviderProps> = ({ children, ...props }: IntlProviderProps): JSX.Element => {
  const locale = useLocale();

  const { messages } = useLocaleData(locale);

  return (
    <ReactIntlProvider
      messages={messages}
      locale={locale}
      defaultLocale={DEFAULT_LOCALE}
      defaultRichTextElements={DEFAULT_RICH_TEXT_ELEMENTS}
      {...props}
    >
      {children}
    </ReactIntlProvider>
  );
};
