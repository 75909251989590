import { PAST_REQUEST_STATUSES, UPCOMING_REQUEST_STATUSES } from '../../utils/constants';

import { REQUEST_STATUS_TO_NAME } from 'utils/constants';
import { RootState } from '../reducer';
import { createSelector } from 'reselect';

export const serviceRequestStateSelector = (state: RootState): ServiceRequestState => state.serviceRequests;

export const serviceRequestsSelector = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.serviceRequests,
);

export const selectServiceRequestMetadata = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.serviceRequestMetadata,
);

export const selectSecondaryTypes = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.serviceRequestMetadata.secondaryTypes,
);

export const selectServiceRequestsByUuid = createSelector([serviceRequestsSelector], serviceRequests => {
  const serviceRequestsByUuid: Record<string, ServiceRequest> = {};

  serviceRequests.forEach((request: ServiceRequest) => {
    serviceRequestsByUuid[request.uuid] = request;
  });
  return serviceRequestsByUuid;
});

export const selectOpenServiceRequests = createSelector(
  [serviceRequestsSelector],
  serviceRequests =>
    serviceRequests?.filter(request => UPCOMING_REQUEST_STATUSES.includes(REQUEST_STATUS_TO_NAME[request.status])) ??
    [],
);

export const selectClosedServiceRequests = createSelector(
  [serviceRequestsSelector],
  serviceRequests =>
    serviceRequests?.filter(request => PAST_REQUEST_STATUSES.includes(REQUEST_STATUS_TO_NAME[request.status])) ?? [],
);

export const selectCurrentServiceRequest = createSelector(
  serviceRequestStateSelector,
  serviceRequestState => serviceRequestState.currentServiceRequest,
);

export const selectMetadataRequestTypes = createSelector([selectServiceRequestMetadata], metadata =>
  metadata?.secondaryTypes?.map(secondaryType => {
    return { value: `${secondaryType.primary_type}`, label: secondaryType.request_type.description };
  }),
);

export const selectMetadataFields = createSelector([selectServiceRequestMetadata], metadata => metadata?.fields);

export const selectUniqueMetadataRequestDescriptions = createSelector([selectMetadataRequestTypes], requests =>
  Array.from(new Set(requests?.map((request: { label: string; value: string }) => request.label))),
);

export const selectSecondaryRequestTypesById = createSelector([selectServiceRequestMetadata], metadata => {
  const requestTypesById: Record<string, SecondaryRequestType> = {};
  metadata?.secondaryTypes?.forEach(secondaryType => {
    requestTypesById[`${secondaryType.primary_type}`] = secondaryType;
  });

  return requestTypesById;
});

export const selectMetadataLocationIds = createSelector(
  [selectServiceRequestMetadata],
  metadata => metadata?.locations?.map(location => location.id) ?? [],
);

export const selectServiceRequestAllUuids = createSelector([serviceRequestsSelector], serviceRequests =>
  serviceRequests.map((request: ServiceRequest) => request.uuid),
);

export const selectServiceRequestsStatus = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.getServiceRequests.status,
);

export const selectCurrentServiceRequestStatus = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.getCurrentServiceRequest.status,
);

export const selectCreateServiceRequestStatus = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.createServiceRequest.status,
);

export const selectCancelServiceRequestStatus = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.cancelServiceRequest.status,
);

export const selectServiceRequestMetadataStatus = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.getServiceRequestMetadata.status,
);

export const getRequestsInitializedState = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => !serviceRequestState.serviceRequestsInitialized,
);

export const createMessageRequestStatus = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.createMessageRequest.status,
);

export const getMessagesRequestStatus = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.getMessagesRequest.status,
);

export const selectUpdateLastReadMessageStatus = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.updateLastReadTimestampRequest.status,
);

export const selectUpdateEstimateStatus = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.updateEstimateStatusRequest.status,
);

export const getSecondaryTypesStatus = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.getSecondaryTypesRequest.status,
);

export const selectFailedMessages = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.failedMessages,
);

export const selectSendingMessageText = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.createMessageRequest.sendingMessageText,
);

export const selectShowRecurringServiceRequest = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.showSetRecurringServiceRequest,
);

export const selectShowScheduleServiceRequest = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.showSetScheduleServiceRequest,
);

export const selectShowAttachment = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.showAttachment,
);

export const updateServiceRequestsStatus = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.updateServiceRequest.status,
);

export const selectCurrentServiceRequestFeedback = createSelector(
  serviceRequestStateSelector,
  serviceRequestState => serviceRequestState.currentServiceRequest?.feedback,
);

export const selectShowFloorPlanPinner = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.showFloorPlanPinner,
);

export const selectShowFeed = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.showFeed,
);

export const selectSubmitFeedbackStatus = createSelector(
  [serviceRequestStateSelector],
  serviceRequestState => serviceRequestState.submitFeedbackRequest.status,
);
