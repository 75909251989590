import { AdditionalFieldsProps, SelectField } from '../../interfaces';
import { HqoWheelPickerContainer } from './styles';
import { HqoWheelPicker } from '../../../hqo-wheel-picker/component';
import { useCallback } from 'react';
import { Value } from '../../../hqo-wheel-picker/interfaces';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { SelectTw } from '@hqo/react-components-library/dist/atoms/select';
import type { SelectItem } from '@hqo/react-components-library/dist/atoms/select/select.interfaces';

interface Props {
  field: SelectField;
  additionalProps: AdditionalFieldsProps;
}

// eslint-disable-next-line max-lines-per-function
export const Select = ({ field, additionalProps: { handleSelectChange, readOnly } }: Props): JSX.Element => {
  const { name, options, value, label, placeholder, required } = field;
  const isSmallViewport = useIsSmallViewportWidth();

  const dropdownOptions: SelectItem[] = options?.map(option => {
    return { ...option, label: option.label?.toLocaleString(), value: option.value?.toLocaleString() };
  });

  const handleChange = useCallback(
    (selectedValue: Value) => handleSelectChange(name, selectedValue),
    [name, handleSelectChange],
  );
  const handleDropdownChange = useCallback(
    event => handleSelectChange(name, event.target.value),
    [handleSelectChange, name],
  );

  return isSmallViewport ? (
    <HqoWheelPickerContainer data-testid={`${name}-select-field`}>
      <HqoWheelPicker
        items={options}
        value={value}
        onChange={handleChange}
        label={label}
        placeholder={placeholder}
        required={required}
        disabled={readOnly || options?.length === 0}
      />
    </HqoWheelPickerContainer>
  ) : (
    <HqoWheelPickerContainer data-testid={`${name}-select-field`}>
      <SelectTw
        name={name}
        items={dropdownOptions}
        value={value?.toLocaleString()}
        required={required}
        placeholder={placeholder}
        onChange={handleDropdownChange}
        label={label}
        disabled={readOnly || options?.length === 0}
      />
    </HqoWheelPickerContainer>
  );
};
