import { useGoBack } from 'hooks/use-go-back-handler.hook';
import { SwipeModal } from 'shared/styles/swipe-modal';
import { ServiceRequestModalProps } from '../service-request-modal-props.types';
import { useDynamicFormIds } from '../use-dynamic-form-ids.hook';
import { useServiceRequestSteps, useRecurringStep } from './service-request-mobile-modal.hooks';
import { ServiceRequestMobileModalContent } from './service-request-mobile-modal-content';
import { useCallback } from 'react';

// eslint-disable-next-line max-lines-per-function
export const ServiceRequestMobileModal = ({ requestData }: ServiceRequestModalProps): JSX.Element => {
  const handleClose = useGoBack();
  const { firstDynamicFormId, handleFormIdSelect } = useDynamicFormIds();
  const { index, steps, isDataOpened, isFormOpened, isRecurringOpen, openPreviousStep, openNextStep, openStepByIndex } =
    useServiceRequestSteps(!!firstDynamicFormId);
  const { openRecurringStep, closeRecurringStep } = useRecurringStep(isRecurringOpen, steps, openStepByIndex);
  const onPressBack = useCallback(() => {
    if (isDataOpened) handleClose();
    else if (isRecurringOpen) closeRecurringStep();
    else openPreviousStep();
  }, [closeRecurringStep, handleClose, isDataOpened, isRecurringOpen, openPreviousStep]);
  return (
    <SwipeModal
      content={
        <ServiceRequestMobileModalContent
          requestData={requestData}
          stepsData={{ index, steps, openNextStep, openStepByIndex }}
          formId={firstDynamicFormId}
          handleFormIdSelect={handleFormIdSelect}
          closeRecurringStep={closeRecurringStep}
          openRecurringStep={openRecurringStep}
        />
      }
      withLeftExitButton={isDataOpened}
      withLeftArrowButton={isFormOpened || isRecurringOpen}
      onClose={handleClose}
      onPressBack={onPressBack}
      reverseAnimation={false}
    />
  );
};
