import { ErrorNotification, ErrorsState } from './types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';

type ErrorNotificationResponse = { hasErrorNotifications: boolean; errors: ErrorNotification[] };
type CriticalErrorNotificationResponse = { hasErrorNotifications: boolean; criticalErrors: ErrorNotification[] };

export const errorsSelect = (state: RootState): RootState['errors'] => state.errors;
export const getErrorNotifications = createSelector(
  errorsSelect,
  (state: ErrorsState): ErrorNotificationResponse => ({
    hasErrorNotifications: state.errors.length > 0,
    errors: state.errors,
  }),
);

export const getCriticalErrorNotifications = createSelector(
  errorsSelect,
  (state: ErrorsState): CriticalErrorNotificationResponse => ({
    hasErrorNotifications: state.criticalErrors.length > 0,
    criticalErrors: state.criticalErrors,
  }),
);
