import { useIntl } from 'react-intl';
import { DateSelect } from 'components/shared/fields/date-select';
import { FieldWrapper } from './styles';
import { useField } from 'formik';

interface StartDateFieldProps {
  fieldName: string;
}

export const StartDateField = ({ fieldName }: StartDateFieldProps) => {
  const intl = useIntl();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ value }, _, { setValue }] = useField<string>(fieldName);

  return (
    <FieldWrapper data-testid="schedule-form-start-date">
      <DateSelect
        label={intl.formatMessage({ id: 'schedule.start_date' })}
        placeholder={intl.formatMessage({ id: 'select_date' })}
        required
        value={value}
        onChange={setValue}
      />
    </FieldWrapper>
  );
};
