import { ChangeEvent, useCallback } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { submitFeedback } from 'store/serviceRequests/actions';
import { useFieldMetadata } from 'hooks/use-ui-metadata.hook';
import { MetadataFlags } from 'shared/consts/metadata-flags-enum';
import { useFlag } from 'hooks/use-flag.hook';
import { LDFlags } from 'shared/consts';
import * as yup from 'yup';
import { REQUEST_STATUS } from 'utils/constants';

interface FeedBackFormValues {
  rating: number;
  comment: string;
}

const MINIMAL_RATING_FOR_OPTIONAL_COMMENT = 3;
const RATING_OR_COMMENT_REQUIRED_ERROR_KEY = 'feedback_box.rating_or_comment_required';
const COMMENT_REQUIRED_ERROR_KEY = 'feedback_box.comment_required';

const validationSchema = yup.object().shape({
  rating: yup.number(),
  comment: yup
    .string()
    .test('comment', RATING_OR_COMMENT_REQUIRED_ERROR_KEY, (value, context) => {
      return !!value || !!context.parent.rating;
    })
    .when('rating', {
      is: (rating: number) => rating < MINIMAL_RATING_FOR_OPTIONAL_COMMENT,
      then: schema => schema.required(COMMENT_REQUIRED_ERROR_KEY),
    }),
});

const useFeedBackFormik = (serviceRequestId?: string) => {
  const dispatch = useDispatch();

  return useFormik<FeedBackFormValues>({
    initialValues: {
      rating: 0,
      comment: '',
    },
    onSubmit: ({ comment, rating }) => {
      dispatch(
        submitFeedback.request({
          requestId: serviceRequestId,
          ...(!!comment && { comment }),
          ...(!!rating && { rating }),
        }),
      );
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
  });
};

export const useFeedBackForm = (serviceRequestId?: string) => {
  const { values, setFieldValue, handleSubmit, isValid, setErrors, errors } = useFeedBackFormik(serviceRequestId);
  const handleRatingChange = useCallback(
    (rating: number) => {
      setFieldValue('rating', rating);
      setErrors({});
    },
    [setFieldValue, setErrors],
  );
  const handleCommentChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setFieldValue('comment', event.target.value);
      setErrors({});
    },
    [setFieldValue, setErrors],
  );

  return {
    values,
    handleRatingChange,
    handleCommentChange,
    handleSubmit,
    isValid,
    isCommentRequired: values.rating < MINIMAL_RATING_FOR_OPTIONAL_COMMENT && !!values.rating,
    errorTranslationId: errors.comment ?? RATING_OR_COMMENT_REQUIRED_ERROR_KEY,
  };
};

export const useIsFeedBackFormAvailable = (serviceRequest?: Pick<ServiceRequest, 'feedback' | 'status'>): boolean => {
  const isFeedbackEnabled = useFieldMetadata(MetadataFlags.FEEDBACK_ENABLED);
  const isCompletedServiceRequest = serviceRequest?.status === REQUEST_STATUS.COMPLETED;
  const showFeedbackBox = useFlag(LDFlags.SHOW_SERVICE_REQUESTS_FEEDBACK);

  return isFeedbackEnabled && showFeedbackBox && isCompletedServiceRequest && !serviceRequest?.feedback;
};
