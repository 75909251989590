import { API_PATHS, HTTP_METHODS, SERVICE_REQUEST_VERTICAL_NAME } from 'utils/constants';
import { AjaxResponse, ajax } from 'rxjs/ajax';
import { ApiRequestHeaders, JSON_CONTENT_TYPE } from 'store/api-client/api-client.consts';

import { AltBuilding } from 'store/alt-buildings/types';
import { BuildingResponse } from 'store/building/types';
import { BuildingTheme } from 'store/theme/types';
import { Observable } from 'rxjs';
import { UploadFilesResponse } from 'store/files/types';
import { User } from 'store/user/types';
import { stringify } from 'qs';
import { GetDynamicFormDefinitionDto } from 'store/dynamic-forms/types';
import { OwnerParams } from 'shared/types/owner-params';
import { UiMetadata } from 'store/ui-metadata/types';
import { LookupDataResponse } from 'store/lookup-data/types';
import { LookupDataId } from 'types/lookup-data';

export class ApiClient {
  constructor(
    private baseUrl: string,
    private authToken?: string | null,
    private hqoBuildingUuid?: string | null,
    private altBuildingUuid?: string | null,
  ) {}

  private get DefaultHeaders(): Record<string, string> {
    return {
      [ApiRequestHeaders.CONTENT_TYPE]: JSON_CONTENT_TYPE,
      ...(this.authToken ? { [ApiRequestHeaders.AUTHORIZATION]: this.authToken } : {}),
      ...(this.hqoBuildingUuid ? { [ApiRequestHeaders.HQO_BUILDING_UUID]: this.hqoBuildingUuid } : {}),
      ...(this.altBuildingUuid ? { [ApiRequestHeaders.ALT_BUILDING_UUID]: this.altBuildingUuid } : {}),
    };
  }

  getCurrentUser(): Observable<AjaxResponse<User>> {
    return ajax.get(`${this.baseUrl}/api${API_PATHS.USER}`, this.DefaultHeaders);
  }

  getServiceRequests(buildingUuid: string): Observable<AjaxResponse<ServiceRequestsResponse>> {
    return ajax.get(
      `${this.baseUrl}/api${API_PATHS.SERVICE_REQUEST}/${buildingUuid}/requests?limit=100`,
      this.DefaultHeaders,
    );
  }

  getCurrentServiceRequest(
    buildingUuid: string,
    serviceRequestId: string,
  ): Observable<AjaxResponse<ServiceRequestResponse>> {
    return ajax.get(
      `${this.baseUrl}/api${API_PATHS.SERVICE_REQUEST}/${buildingUuid}/requests/${serviceRequestId}`,
      this.DefaultHeaders,
    );
  }

  createServiceRequest(
    buildingUuid: string,
    payload: ServiceRequestPayload,
  ): Observable<AjaxResponse<ServiceRequestResponse>> {
    return ajax({
      headers: this.DefaultHeaders,
      url: `${this.baseUrl}/api${API_PATHS.SERVICE_REQUEST}/${buildingUuid}/requests`,
      method: HTTP_METHODS.POST,
      body: payload,
    });
  }

  cancelServiceRequest(buildingUuid: string, requestId: string): Observable<AjaxResponse<void>> {
    return ajax.delete(
      `${this.baseUrl}/api${API_PATHS.SERVICE_REQUEST}/${buildingUuid}/requests/${requestId}`,
      this.DefaultHeaders,
    );
  }

  getServiceRequestMetadata(buildingUuid: string): Observable<AjaxResponse<ServiceRequestMetadataResponse>> {
    return ajax.get(`${this.baseUrl}/api${API_PATHS.SERVICE_REQUEST}/${buildingUuid}/config`, this.DefaultHeaders);
  }

  uploadFiles(payload: FormData, buildingUuid: string): Observable<AjaxResponse<UploadFilesResponse>> {
    return ajax.post(`${this.baseUrl}/api${API_PATHS.SERVICE_REQUEST}/${buildingUuid}/file-upload`, payload, {
      Authorization: this.DefaultHeaders.Authorization,
    });
  }

  getBuilding(buildingUuid: string): Observable<AjaxResponse<BuildingResponse>> {
    return ajax.get(`${this.baseUrl}/api/tenant/v1/buildings/${buildingUuid}`, this.DefaultHeaders);
  }

  getBuildingTheme(buildingUuid: string): Observable<AjaxResponse<BuildingTheme>> {
    return ajax.get(`${this.baseUrl}/api/buildings/${buildingUuid}/theme`, this.DefaultHeaders);
  }

  getAltBuildings(buildingUuid: string): Observable<AjaxResponse<AltBuilding[]>> {
    return ajax.get(
      `${this.baseUrl}/api/tenant/v1/buildings/alt_buildings/${buildingUuid}?${stringify({
        vertical_name: SERVICE_REQUEST_VERTICAL_NAME,
      })}`,
      this.DefaultHeaders,
    );
  }

  updateLastReadTimestamp(buildingUuid: string, params: LastReadTimestampRequest): Observable<AjaxResponse<void>> {
    return ajax({
      headers: this.DefaultHeaders,
      url: `${this.baseUrl}/api${API_PATHS.SERVICE_REQUEST}/${buildingUuid}/requests/${params.request_uuid}/last-read`,
      method: HTTP_METHODS.POST,
      body: params.payload,
    });
  }

  createMessage(
    buildingUuid: string,
    requestId: string,
    payload: CreateMessagePayload,
  ): Observable<AjaxResponse<CreateMessageResponse>> {
    return ajax.post(
      `${this.baseUrl}/api${API_PATHS.SERVICE_REQUEST}/${buildingUuid}/requests/${requestId}/messages`,
      payload,
      this.DefaultHeaders,
    );
  }

  getMessages(buildingUuid: string, requestId: string): Observable<AjaxResponse<GetMessagesResponse>> {
    return ajax.get(
      `${this.baseUrl}/api${API_PATHS.SERVICE_REQUEST}/${buildingUuid}/requests/${requestId}/messages`,
      this.DefaultHeaders,
    );
  }

  patchUpdateEstimateStatus(
    buildingUuid: string,
    requestId: string,
    estimateId: string,
    payload: UpdateEstimateStatusPayload,
  ): Observable<AjaxResponse<ServiceRequestResponse>> {
    return ajax.patch(
      `${this.baseUrl}/api${API_PATHS.SERVICE_REQUEST}/${buildingUuid}/requests/${requestId}/estimates/${estimateId}/status`,
      payload,
      this.DefaultHeaders,
    );
  }

  getDynamicFormDefinition(
    buildingUuid: string,
    formId: string,
  ): Observable<AjaxResponse<GetDynamicFormDefinitionDto>> {
    return ajax.get(
      `${this.baseUrl}/api${API_PATHS.SERVICE_REQUEST}/${buildingUuid}/forms/${formId}`,
      this.DefaultHeaders,
    );
  }

  fetchUiMetadata({ ownerType, ownerId }: OwnerParams): Observable<AjaxResponse<UiMetadata>> {
    return ajax.get(
      `${
        this.baseUrl
      }/api/verticals/${SERVICE_REQUEST_VERTICAL_NAME}/${ownerType.toUpperCase()}/${ownerId}/ui-metadata`,
      this.DefaultHeaders,
    );
  }

  updateServiceRequest(
    buildingUuid: string,
    requestId: string,
    payload: ServiceRequestPayload,
  ): Observable<AjaxResponse<ServiceRequestResponse>> {
    return ajax.put(
      `${this.baseUrl}/api${API_PATHS.SERVICE_REQUEST}/${buildingUuid}/requests/${requestId}`,
      payload,
      this.DefaultHeaders,
    );
  }

  submitFeedback(
    buildingUuid: string,
    requestId: string,
    payload: Feedback,
  ): Observable<AjaxResponse<SubmitFeedbackResponse>> {
    return ajax.post(
      `${this.baseUrl}/api${API_PATHS.SERVICE_REQUEST}/${buildingUuid}/requests/${requestId}/feedback`,
      payload,
      this.DefaultHeaders,
    );
  }

  addPinToFloorPlan(
    buildingUuid: string,
    requestId: string,
    payload: AddPinToFloorPlanPayload,
  ): Observable<AjaxResponse<FloorPlanResponse>> {
    return ajax.post(
      `${this.baseUrl}/api${API_PATHS.SERVICE_REQUEST}/${buildingUuid}/requests/${requestId}/floor-plan-pin`,
      payload,
      this.DefaultHeaders,
    );
  }

  updateFloorPlanPin(
    buildingUuid: string,
    requestId: string,
    payload: UpdateFloorPlanPinPayload,
  ): Observable<AjaxResponse<FloorPlanResponse>> {
    return ajax.patch(
      `${this.baseUrl}/api${API_PATHS.SERVICE_REQUEST}/${buildingUuid}/requests/${requestId}/floor-plan-pin`,
      payload,
      this.DefaultHeaders,
    );
  }

  removePinFromFloorPlan(buildingUuid: string, requestId: string): Observable<AjaxResponse<void>> {
    return ajax.delete(
      `${this.baseUrl}/api${API_PATHS.SERVICE_REQUEST}/${buildingUuid}/requests/${requestId}/floor-plan-pin`,
      this.DefaultHeaders,
    );
  }

  getSecondaryTypes(
    buildingUuid: string,
    primaryType: string,
  ): Observable<AjaxResponse<SecondaryRequestTypesResponse>> {
    return ajax.get(
      `${this.baseUrl}/api${API_PATHS.SERVICE_REQUEST}/${buildingUuid}/secondary-types?primary_type=${primaryType}`,
      this.DefaultHeaders,
    );
  }

  getLookupData(
    buildingUuid: string,
    fieldName: string,
    primaryUuid: LookupDataId,
  ): Observable<AjaxResponse<LookupDataResponse>> {
    return ajax.get(
      `${this.baseUrl}/api${API_PATHS.SERVICE_REQUEST}/${buildingUuid}/lookups/${fieldName}?primary_uuid=${primaryUuid}`,
      this.DefaultHeaders,
    );
  }
}
