import { useDispatch, useSelector } from 'react-redux';

import { UserEvents } from 'shared/consts';
import { selectCurrentServiceRequest } from '../store/serviceRequests/selectors';
import { track } from '@hqo/web-tracking';
import { updateEstimateStatus } from 'store/serviceRequests/actions';
import { useCallback } from 'react';
import { ServiceRequestEstimateStatusEnum } from 'store/serviceRequests/enums';

interface EstimateTracking {
  onDeclineTrack: () => void;
  onApproveTrack: () => void;
}

interface EstimateHook {
  handleApprove: () => void;
  handleDecline: () => void;
  estimate: Estimate;
}

export const useEstimateTrack = (estimate: Estimate, workOrderType: number | string): EstimateTracking => {
  const onDeclineTrack = useCallback(() => {
    track(
      UserEvents.WORK_ORDER_ESTIMATE_DECLINED,
      {
        type: 'action',
        min_estimate: estimate?.min_estimate,
        max_estimate: estimate?.max_estimate,
        work_order_type: workOrderType,
      },
      { sendToPendo: true, sendToHqoTracking: false },
    );
  }, [estimate, workOrderType]);

  const onApproveTrack = useCallback(() => {
    track(
      UserEvents.WORK_ORDER_ESTIMATE_APPROVED,
      {
        type: 'action',
        min_estimate: estimate?.min_estimate,
        max_estimate: estimate?.max_estimate,
        work_order_type: workOrderType,
      },
      { sendToPendo: true, sendToHqoTracking: false },
    );
  }, [estimate, workOrderType]);
  return { onDeclineTrack, onApproveTrack };
};

export const useEstimate = (): EstimateHook => {
  const dispatch = useDispatch();
  const { id, estimate, request_type } = useSelector(selectCurrentServiceRequest);
  const { onApproveTrack, onDeclineTrack } = useEstimateTrack(estimate, request_type);
  const handleUpdateEstimateStatus = useCallback(
    (status: ServiceRequestEstimateStatusEnum) => {
      if (estimate) {
        dispatch(
          updateEstimateStatus.request({
            request_uuid: id,
            estimate_uuid: estimate.id,
            payload: { status },
          }),
        );
      }
    },
    [dispatch, id, estimate],
  );
  const handleApprove = useCallback(() => {
    handleUpdateEstimateStatus(ServiceRequestEstimateStatusEnum.APPROVED);
    onApproveTrack();
  }, [handleUpdateEstimateStatus, onApproveTrack]);
  const handleDecline = useCallback(() => {
    handleUpdateEstimateStatus(ServiceRequestEstimateStatusEnum.DECLINED);
    onDeclineTrack();
  }, [handleUpdateEstimateStatus, onDeclineTrack]);
  return { handleApprove, handleDecline, estimate };
};
