import { ModalWrapper } from '@hqo/react-components-library/dist/molecules/modals/wrapper';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';

import styled from 'styled-components';

export interface CustomSizes {
  width?: string;
  height?: string;
  paddings?: Array<string>;
}

export const Wrapper = styled.div<CustomSizes>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 528px;

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 100%;
    height: 100%;
  } ;
`;
export const StyledModalWrapper = styled(ModalWrapper)`
  padding: 32px 0;
  width: 984px;
  @media screen and (max-width: ${DIMENSIONS.LARGE}) {
    width: 100%;
    height: 100%;
  } ;
`;
