import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useMobileHeaderVisibility } from 'hooks/use-mobile-header-visibility.hook';
import { SwipeableViewsStyled } from 'pages/details/styles';

import {
  useDetailsSteps,
  useScheduleStep,
  useSaveScheduleData,
  useAttachmentStep,
  useFeedStep,
} from 'pages/details/details-mobile-page/details-mobile-page.hooks';
import { DetailsMobilePageRightIcon } from 'pages/details/details-mobile-page/details-mobile-page-right-icon';
import { RequestFeed } from '../feed-content/request-feed';
import { RequestDetailsSteps, useFloorPlanStep } from './details-mobile-page.hooks';
import { DetailsPageContents } from '../details-page-contents';
import { SwipeModal } from 'shared/styles/swipe-modal';
import { ScheduleFormSubmit, ScheduleRequestForm } from 'pages/details/schedule-request-form/schedule-request-form';
import { selectShowAttachment } from '../../../store/serviceRequests/selectors';
import { ViewAttachment } from 'pages/details/view-attachment';
import { FloorPlanPinPage } from 'pages/details/floor-plan-pin-page';

interface DetailsMobilePageProps {
  onClose: VoidFunction;
}
const getStepComponents = (
  isFeedOpened: boolean,
  openScheduleStep: () => void,
  openFloorPlanStep: () => void,
  closeFloorPlanStep: () => void,
  saveScheduleDataAndCloseStep: ScheduleFormSubmit,
  currentFileRef: string,
  fileName: string,
) => ({
  [RequestDetailsSteps.DETAILS]: (
    <DetailsPageContents openScheduleStep={openScheduleStep} openFloorPlanStep={openFloorPlanStep} />
  ),
  [RequestDetailsSteps.SCHEDULE]: <ScheduleRequestForm onSubmit={saveScheduleDataAndCloseStep} />,
  [RequestDetailsSteps.FEED]: <RequestFeed isVisible={isFeedOpened} />,
  [RequestDetailsSteps.ATTACHMENT]: <ViewAttachment fileSrc={currentFileRef} alt={fileName} />,
  [RequestDetailsSteps.FLOOR_PLAN]: <FloorPlanPinPage closeFloorPlanStep={closeFloorPlanStep} />,
});
// eslint-disable-next-line max-lines-per-function
export const DetailsMobilePage: React.FC<DetailsMobilePageProps> = ({
  onClose: handleClose,
}: DetailsMobilePageProps): JSX.Element => {
  const {
    index,
    isFeedOpened,
    isDetailsOpened,
    isScheduleOpened,
    isAttachmentOpened,
    isFloorPlanOpened,
    openPreviousStep,
    openStepByIndex,
    steps,
  } = useDetailsSteps();
  const { openScheduleStep, closeScheduleStep } = useScheduleStep(isScheduleOpened, steps, openStepByIndex);
  const { closeAttachmentStep } = useAttachmentStep(isAttachmentOpened, steps, openStepByIndex);
  const { openFloorPlanStep, closeFloorPlanStep } = useFloorPlanStep(isFloorPlanOpened, steps, openStepByIndex);
  const { saveScheduleDataAndCloseStep } = useSaveScheduleData(closeScheduleStep);
  const { openFeedStep, closeFeedStep } = useFeedStep(isFeedOpened, steps, openStepByIndex);
  const { attachmentRef, attachmentName } = { ...useSelector(selectShowAttachment) };
  const activeSteps = getStepComponents(
    isFeedOpened,
    openScheduleStep,
    openFloorPlanStep,
    closeFloorPlanStep,
    saveScheduleDataAndCloseStep,
    attachmentRef,
    attachmentName,
  );
  useMobileHeaderVisibility();
  const onPressBack = useCallback(() => {
    if (isDetailsOpened) handleClose();
    else if (isScheduleOpened) closeScheduleStep();
    else if (isAttachmentOpened) closeAttachmentStep();
    else if (isFloorPlanOpened) closeFloorPlanStep();
    else if (isFeedOpened) closeFeedStep();
    else openPreviousStep();
  }, [
    closeAttachmentStep,
    closeFeedStep,
    closeFloorPlanStep,
    closeScheduleStep,
    handleClose,
    isAttachmentOpened,
    isDetailsOpened,
    isFeedOpened,
    isFloorPlanOpened,
    isScheduleOpened,
    openPreviousStep,
  ]);

  return (
    <SwipeModal
      content={
        <SwipeableViewsStyled index={index} onChangeIndex={openStepByIndex} disabled>
          {steps.map(step => activeSteps[step])}
        </SwipeableViewsStyled>
      }
      withLeftExitButton={isDetailsOpened}
      withLeftArrowButton={!isDetailsOpened}
      rightIcon={
        (isDetailsOpened || isFeedOpened) && (
          <DetailsMobilePageRightIcon isFeedOpened={isFeedOpened} onOpenFeed={openFeedStep} />
        )
      }
      onClose={handleClose}
      onPressBack={onPressBack}
      noContentPadding={isFeedOpened}
      reverseAnimation={false}
    />
  );
};
