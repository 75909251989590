import { NewRequestData } from '../create-service-request-step-content';
import { ServiceRequestModalProps } from '../service-request-modal-props.types';
import { ServiceRequestSteps, useServiceRequestSubmit, useRecurringData } from './service-request-mobile-modal.hooks';
import { CustomFormStep } from './custom-form-step';
import { SwipeableViewsStyled } from './service-request-mobile-modal-content.styles';
import { DynamicFormDto } from '@hqo/dynamic-forms-sdk/types';
import { RecurringRequestForm, RecurringFormSubmit } from './recurring-request-form/recurring-request-form';
import { RecurringRequestFormValues } from './recurring-request-form/recurring-request-form.hooks';
import { CreateServiceRequestStep } from '../create-service-request-step';

interface StepsData {
  index: number;
  steps: ServiceRequestSteps[];
  openNextStep: VoidFunction;
  openStepByIndex: (index: number) => void;
}

interface ServiceRequestMobileModalContentProps extends ServiceRequestModalProps {
  formId: string;
  handleFormIdSelect: (fieldName: string, formId: string | null) => void;
  stepsData: StepsData;
  closeRecurringStep: () => void;
  openRecurringStep: () => void;
}

const getStepComponents = (
  requestData: ServiceRequestModalProps['requestData'],
  handleFormIdSelect: (fieldName: string, formId: string | null) => void,
  handleSubmitDataStep: (data: NewRequestData) => void,
  handleSubmitFormStep: (formDefinition: DynamicFormDto) => void,
  recurringData: Partial<RecurringRequestFormValues>,
  saveRecurringDataAndCloseStep: RecurringFormSubmit,
  openRecurringStep: () => void,
  clearRecurringData: () => void,
  formId?: string,
) => ({
  [ServiceRequestSteps.DATA]: (
    <CreateServiceRequestStep
      requestData={requestData}
      onFormIdSelected={handleFormIdSelect}
      handleStepSubmit={handleSubmitDataStep}
      hasNextStep={!!formId}
      openRecurringStep={openRecurringStep}
      clearRecurringData={clearRecurringData}
      recurringData={recurringData}
    />
  ),
  [ServiceRequestSteps.RECURRING]: (
    <RecurringRequestForm initialValues={recurringData} onSubmit={saveRecurringDataAndCloseStep} />
  ),
  [ServiceRequestSteps.FORM]: <CustomFormStep onSubmit={handleSubmitFormStep} formId={formId} />,
});
export const ServiceRequestMobileModalContent = ({
  requestData,
  formId,
  handleFormIdSelect,
  stepsData: { index, steps, openNextStep, openStepByIndex },
  closeRecurringStep,
  openRecurringStep,
}: ServiceRequestMobileModalContentProps): JSX.Element => {
  const { submitDataStep, submitFormStep } = useServiceRequestSubmit(openNextStep, !!formId);
  const { recurringData, saveRecurringDataAndCloseStep, clearRecurringData } = useRecurringData(closeRecurringStep);
  const stepComponents = getStepComponents(
    requestData,
    handleFormIdSelect,
    submitDataStep,
    submitFormStep,
    recurringData,
    saveRecurringDataAndCloseStep,
    openRecurringStep,
    clearRecurringData,
    formId,
  );

  return (
    <SwipeableViewsStyled index={index} onChangeIndex={openStepByIndex} disabled>
      {steps.map(step => stepComponents[step])}
    </SwipeableViewsStyled>
  );
};
