import { AppThemeProvider } from '@hqo/react-components-library/dist/tenant-web/elements/theme';
import { hqoTheme } from '@hqo/react-components-library/dist/hqo-theme-provider';

import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'components/intl-provider';
import { Provider } from 'react-redux';
import React, { useCallback } from 'react';
import { RootState } from 'store/reducer';
import { createStore } from 'redux';
import { ConfigState } from 'store/config/types';
import { BuildingState } from 'store/building/types';
import { ACTION_STATUSES } from 'utils/constants';
import { mock } from 'test-utils/mock';
import { OnErrorFn } from '@formatjs/intl';
import { Args } from 'types/args';
import { DEFAULT_RICH_TEXT_ELEMENTS } from 'components/intl-provider/rich-text-elements';

const MOCKED_CONFIG = {
  apiUrl: 'url',
} as ConfigState;

const MOCKED_BUILDING = {
  building: {
    uuid: '1',
    locale: 'en',
    timezone: 'Europe/Paris',
  },
  getBuilding: {
    status: ACTION_STATUSES.FULFILLED,
    error: null,
  },
} as BuildingState;

const MOCK_DEFAULT_RICH_TEXT_ELEMENTS = Object.fromEntries(
  Object.keys(DEFAULT_RICH_TEXT_ELEMENTS).map(key => [key, (str: string[]) => `<${key}>${str}</${key}>`]),
);

interface TestWrapperProps {
  children?: React.ReactNode;
  state: Partial<RootState>;
  onTranslationError?: OnErrorFn;
}
export const TestWrapper: React.FC<TestWrapperProps> = ({
  children,
  state,
  onTranslationError,
}: TestWrapperProps): JSX.Element => {
  const MOCKED_STATE = mock<RootState>({
    ...state,
  });

  MOCKED_STATE.config = state?.config || MOCKED_CONFIG;
  MOCKED_STATE.building = state?.building || MOCKED_BUILDING;

  const store = createStore(() => MOCKED_STATE);
  const handleTranslationError = useCallback(
    (...args: Args<OnErrorFn>) => {
      onTranslationError?.(...args);
    },
    [onTranslationError],
  );

  return (
    <Provider store={store}>
      <BrowserRouter>
        <IntlProvider onError={handleTranslationError} defaultRichTextElements={MOCK_DEFAULT_RICH_TEXT_ELEMENTS}>
          <AppThemeProvider theme={hqoTheme}>{children}</AppThemeProvider>
        </IntlProvider>
      </BrowserRouter>
    </Provider>
  );
};
