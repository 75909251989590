import { useDetailsSteps, useAttachmentStep } from 'pages/details/details-mobile-page/details-mobile-page.hooks';
import { StyledIconBlock, StyledIconBlockAttachment } from 'pages/details/styles';
import { FileTypes } from 'pages/details/utils';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectShowAttachment } from 'store/serviceRequests/selectors';

interface Props {
  key: number;
  fileType: FileTypes;
  title: string;
  url: string;
}

export const AttachmentListItem = ({ key, fileType, title, url }: Props) => {
  const { attachmentOpened } = { ...useSelector(selectShowAttachment) };
  const { steps, openStepByIndex } = useDetailsSteps();
  const { openAttachmentStep } = useAttachmentStep(attachmentOpened, steps, openStepByIndex);
  const isImage = fileType === FileTypes.IMAGE;
  const handleClick = useCallback(() => {
    openAttachmentStep(url, title);
  }, [openAttachmentStep, title, url]);
  return isImage ? (
    <StyledIconBlockAttachment
      key={key}
      icon={fileType}
      shouldOpenInNewTab
      iconType="far"
      title={title}
      onClick={handleClick}
      dataTestId="viewable-attachment"
    />
  ) : (
    <StyledIconBlock
      key={key}
      linkRef={url}
      linkText={title}
      icon={fileType}
      shouldOpenInNewTab
      iconType="far"
      title=""
    />
  );
};
