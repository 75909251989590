import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getLookupData } from '../store/lookup-data/actions';
import { LookupDataId } from 'types/lookup-data';

export const useLookupData = () => {
  const dispatch = useDispatch();

  const loadLookupData = useCallback(
    async (fieldName: string, primaryUuid: LookupDataId) => {
      dispatch(getLookupData.request({ fieldName, primaryUuid }));
    },
    [dispatch],
  );

  return loadLookupData;
};
