import styled from 'styled-components';

export const FileUploadContainer = styled.div`
  margin-bottom: 60px;
`;

export const FilesContainer = styled.div`
  padding: 5px 0px 15px 16px;
  display: flex;
  flex-direction: column;
`;

export const FileText = styled.div`
  font: normal 500 12px/21px ${({ theme }) => theme.fonts.join()};
  width: 275px;
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const FileLabel = styled.div`
  font: 14px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.$greyDark};
`;
