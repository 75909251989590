import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

interface WrapperProps {
  clickX: number;
  clickY: number;
  showPin: boolean;
}

export const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 42px;
  color: ${({ theme }) => theme.colors.$primary};
`;

export const PinWrapper = styled.div<WrapperProps>`
  position: absolute;
  top: ${({ clickY }) => `${clickY}px`};
  left: ${({ clickX }) => `${clickX}px`};
  visibility: ${({ showPin }) => (showPin ? 'visible' : 'hidden')};
`;

export const FloorPlanImg = styled.img`
  padding: 0;
  margin: 0;
  z-index: 99;
`;

export const FloorPlanWrapper = styled.div`
  position: relative;
`;
