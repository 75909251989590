import { useState, useCallback } from 'react';

export const useAvatarLoading = () => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [imageErrored, setImageErrored] = useState<boolean>(false);
  const handleImageLoaded = useCallback(() => {
    setImageLoaded(true);
  }, []);
  const handleImageError = useCallback(() => {
    setImageErrored(true);
  }, []);

  return { imageLoaded, imageErrored, handleImageLoaded, handleImageError };
};
