import styled, { css } from 'styled-components';

import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconBlock } from '@hqo/react-components-library/dist/molecules/iconBlock';
import { Link } from 'react-router-dom';
import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';
import { ChatBubbleVariants, ChatSkeletonVariants, DetailsSkeletonVariants } from 'pages/details/utils';
import SwipeableViews from 'react-swipeable-views';
import { skeletonAnimation } from 'utils/skeleton-animation';
import { Rating } from '@hqo/react-components-library/dist/molecules/rating';

export const DetailsPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60vh;
  width: 50vw;

  ${mediaMaxWidth(css`
    height: 100%;
    width: 100%;
  `)}
`;

export const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0 16px;
`;

export const FloorPlanPinWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;

  ${mediaMaxWidth(css`
    height: 100%;
  `)}
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 16px 16px 16px;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: start;

  ${mediaMaxWidth(css`
    margin: 0 16px 0px 16px;
  `)}
`;

export const TitleWrapper = styled.header`
  display: flex;
`;

export const Divider = styled.div`
  ${mediaMaxWidth(css`
    width: 100vw;
    border-bottom: 8px solid ${({ theme }): string => theme.colors.greys.adminGreyLightAlt};
    margin: 16px 0;
  `)}
`;

export const SubTitle = styled.div`
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const SubTitleWrapper = styled.div`
  margin-top: 5px;
  margin-right: 16px;
`;

export const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  font-family: ${({ theme }) => theme.fonts.join()};
  overflow: hidden;
`;

export const StyledIconBlock = styled(IconBlock)`
  margin: 16px 0;

  .icon-block-icon {
    height: 16px;
    width: 16px;
  }

  .icon-block-subtitle {
    line-height: 24px;
  }

  .icon-block-link {
    color: ${({ theme }) => theme.colors.$primary};
    cursor: pointer;
  }

  .icon-block-text-container {
    width: 100%;
  }
`;

export const StyledIconBlockAttachment = styled(IconBlock)`
  margin: 16px 0;

  .icon-block-title {
    font: 500 16px/19px ${({ theme }) => theme.fonts.join()};
    color: ${({ theme }) => theme.colors.$primary};
    text-decoration: underline;
    cursor: pointer;
  }

  .icon-block-icon {
    height: 16px;
    width: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: auto;

  ${mediaMaxWidth(css`
    width: 100%;
  `)}
`;

export const ButtonsWrapper = styled(ButtonWrapper)`
  flex-direction: column;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;

  ${mediaMaxWidth(css`
    width: 100%;
    margin: 0 16px;
  `)}
`;

export const StyledButton = styled(Button)`
  height: 50px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  margin: 35px 0;

  ${mediaMaxWidth(css`
    width: 100%;
  `)}
`;

export const SavePinButton = styled(StyledButton)`
  margin-top: 0;
`;

export const RemovePinButton = styled(StyledButton)`
  height: 22px;
  font-weight: 400;
  margin: 16px 0;
`;

export const Icon = styled(FontAwesomeIcon)`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const IconButtonWrapper = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
`;

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  margin-top: 16px;
  width: 100%;
  align-self: center;
  scroll-behavior: smooth;
  padding-bottom: 32px;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
`;

export const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const StyledAvatar = styled.div<{ assignee: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background-color: ${({ theme }) => theme.colors.greys.adminGreyMediumAlt};
  color: ${({ theme }) => theme.colors.universal.white};
  border-radius: 50%;
  margin-right: ${({ assignee }) => (assignee ? '10px' : '0px')};
`;

const chatBubbleVariantStyles: Record<ChatBubbleVariants, ReturnType<typeof css>> = {
  [ChatBubbleVariants.SENDER]: css`
    margin-left: auto;
  `,
  [ChatBubbleVariants.RECEIVER]: css`
    margin-right: auto;
  `,
  [ChatBubbleVariants.SYSTEM]: css`
    margin-right: auto;
  `,
};

export const BubbleWrapper = styled.div<{ variant: ChatBubbleVariants }>`
  display: flex;
  margin-top: 24px;
  ${({ variant }) => chatBubbleVariantStyles[variant]};
`;

const skeletonWrapperVariantStyles: Record<ChatSkeletonVariants, ReturnType<typeof css>> = {
  [ChatSkeletonVariants.SENDER]: css`
    flex-direction: row-reverse;
    align-self: flex-end;
    margin-right: 8px;
  `,
  [ChatSkeletonVariants.RECEIVER]: css`
    flex-direction: row;
    align-self: flex-start;
    margin-left: 8px;
  `,
};

const skeletonNameVariantStyles: Record<ChatSkeletonVariants, ReturnType<typeof css>> = {
  [ChatSkeletonVariants.SENDER]: css`
    align-self: flex-end;
  `,
  [ChatSkeletonVariants.RECEIVER]: css`
    align-self: flex-start;
  `,
};

export const ChatSkeletonWrapper = styled.div<{ variant: ChatSkeletonVariants }>`
  display: flex;
  margin-top: 24px;
  align-items: flex-end;
  ${({ variant }) => skeletonWrapperVariantStyles[variant]};
`;

export const SkeletonBubble = styled.span`
  border-radius: 8px;
  height: 56px;
  width: 50vw;
  max-width: 192px;
  margin-top: 8px;
  ${skeletonAnimation}
`;

export const SkeletonName = styled.span<{ variant: ChatSkeletonVariants }>`
  border-radius: 4px;
  height: 14px;
  width: 80px;
  ${({ variant }) => skeletonNameVariantStyles[variant]};
  ${skeletonAnimation}
`;

export const SkeletonIcon = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  ${skeletonAnimation}
`;

const detailsSkeletonVariantStyles: Record<DetailsSkeletonVariants, ReturnType<typeof css>> = {
  [DetailsSkeletonVariants.TITLE]: css`
    height: 28px;
    margin: 16px 16px auto;
  `,
  [DetailsSkeletonVariants.SUBTITLE]: css`
    height: 16px;
    width: 60%;
    margin: 12px auto 16px 16px;
  `,
  [DetailsSkeletonVariants.SECTION]: css`
    height: 20px;
    width: 120px;
    margin-bottom: 32px;
  `,
  [DetailsSkeletonVariants.ICON]: css`
    height: 16px;
    width: 16px;
  `,
  [DetailsSkeletonVariants.LONG_DETAILS]: css`
    height: 16px;
    width: 180px;
    margin-bottom: 12px;
  `,
  [DetailsSkeletonVariants.MEDIUM_DETAILS]: css`
    height: 16px;
    width: 120px;
    margin-bottom: 12px;
  `,
  [DetailsSkeletonVariants.SHORT_DETAILS]: css`
    height: 16px;
    width: 60px;
    margin-bottom: 12px;
  `,
};

export const DetailsSectionSkeletonWrapper = styled.div`
  display: flex;
  margin-right: auto;
`;

export const IconSkeletonCol = styled.div`
  padding-right: 8px;
`;

export const DetailsSkeletonCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin-bottom: 20px;
`;

export const DetailsSkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DetailsSkeleton = styled.div<{ variant: DetailsSkeletonVariants }>`
  ${({ variant }) => detailsSkeletonVariantStyles[variant]};
  ${skeletonAnimation}
  border-radius: 4px;
`;

export const AvatarImage = styled.img`
  border-radius: 50%;
  height: 40px;
  width: 40px;
`;

export const AvatarImageWrapper = styled.div<{ display: boolean }>`
  display: ${({ display }) => (display ? 'flex' : 'none')};
`;

export const MessageCol = styled.div`
  display: flex;
  flex-flow: column;
  margin-left: 8px;
  margin-right: 8px;
`;

export const IconCol = styled.div`
  display: flex;
  align-self: flex-end;
`;

export const MessagesDate = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
  font: normal 16px/18px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
`;

export const SwipeableViewsStyled = styled(SwipeableViews)`
  &,
  .react-swipeable-view-container {
    height: 100%;
  }
`;

export const CollapsibleHeader = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin: 20px 0;
`;

export const Answer = styled.div`
  margin-bottom: 11px;
`;

export const Question = styled(Answer)`
  font-weight: 500;
  margin-top: 5px;
`;

export const FormAnswersDivider = styled.div`
  ${mediaMaxWidth(css`
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.greys.adminGreyMedium};
  `)}
`;

export const AttachmentsTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  font-family: ${({ theme }) => theme.fonts.join()};
  overflow: hidden;
`;

export const RatingTitle = styled.div`
  margin: 16px 0 8px;
  font-weight: 700;
`;

export const CommentWrapper = styled.div`
  line-height: 24px;
  margin-bottom: 28px;
`;

export const CollapsibleWrapper = styled.div`
  margin-bottom: -12px;

  .caret {
    margin: auto auto auto 12px;
  }
`;

export const RatingStyled = styled(Rating)`
  justify-content: flex-start;
`;

export const CategoryDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Subcategory = styled.div`
  margin-bottom: 8px;
`;

export const AssigneeName = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const AssigneeBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  color: ${({ theme }) => theme.colors.greys.darkGrey7};
`;

export const Image = styled.img`
  height: auto;
  width: 100%;
  max-width: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const WarningIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.admin.orange};
`;

export const WarningWrapper = styled.div`
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.greys.adminGreyMedium};
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
`;

export const WarningTitle = styled.div`
  padding-bottom: 8px;
`;

export const Warning = styled.span`
  padding-left: 4px;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.greys.adminBlack};
`;

export const AttachmentWrapper = styled.div`
  margin: 24px 16px auto;
`;

export const SkeletonAttachment = styled.div`
  ${skeletonAnimation}
  border-radius: 8px;
  height: 200px;
  width: 100%;
  margin: 24px 16px 0;
`;

export const OpenFloorPlanButton = styled.div`
  font: 500 16px/19px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.$primary};
  text-decoration: underline;
  cursor: pointer;
`;

export const PinAddedWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font: 400 16px/19px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const EditFloorPlanButton = styled.div`
  font: 500 16px/19px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.$primary};
  cursor: pointer;
`;

export const FloorPlanButtonWrapper = styled.div`
  margin-top: 8px;
`;

export const FloorPlanSubtitle = styled.div`
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
`;
