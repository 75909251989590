import { Pin } from 'components/floor-plan/pin';
import { FloorPlanImg, FloorPlanWrapper } from 'components/floor-plan/styles';
import { useRef, MouseEvent, useCallback } from 'react';

interface Props {
  imgSrc: string;
  width: number;
  height: number;
  coordinates: PinCoordinates;
  setCoordinates: React.Dispatch<React.SetStateAction<PinCoordinates>>;
  showPin: boolean;
  setShowPin: React.Dispatch<React.SetStateAction<boolean>>;
}

// eslint-disable-next-line max-lines-per-function
export const FloorPlanPinner = ({ imgSrc, width, height, coordinates, setCoordinates, showPin, setShowPin }: Props) => {
  const pinRef = useRef<HTMLDivElement>(null);
  const floorPlanRef = useRef<HTMLImageElement>(null);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const pinWidth = pinRef.current?.clientWidth;
      const pinHeight = pinRef.current?.clientHeight;
      const floorPlanRefOffsets = floorPlanRef?.current?.getBoundingClientRect();
      setCoordinates({
        x: event.clientX - floorPlanRefOffsets.left - pinWidth / 2,
        y: event.clientY - floorPlanRefOffsets.top - pinHeight,
      });
      if (!showPin) {
        setShowPin(true);
      }
    },
    [setCoordinates, setShowPin, showPin],
  );

  return (
    <FloorPlanWrapper>
      <FloorPlanImg
        src={imgSrc}
        width={width}
        height={height}
        alt="floor-plan"
        onMouseDown={handleClick}
        ref={floorPlanRef}
        data-testId="floor-plan-img"
      />
      <Pin showPin={showPin} clickX={coordinates.x} clickY={coordinates.y} ref={pinRef} />
    </FloorPlanWrapper>
  );
};
