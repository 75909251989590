import React from 'react';
import { Divider, Wrapper } from './styles';

interface Props {
  children: React.ReactNode;
  dataTestId?: string;
}

export const DetailsContentsWrapper = ({ children, dataTestId }: Props): JSX.Element => {
  return (
    <>
      <Divider />
      <Wrapper data-testid={dataTestId}>{children}</Wrapper>
    </>
  );
};
