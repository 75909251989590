import {
  GetDynamicFormDefinitionErrorDto,
  GetDynamicFormDefinitionParams,
  GetDynamicFormDefinitionResponseDto,
} from 'store/dynamic-forms/types';
import { createAsyncAction } from 'typesafe-actions';

export const getDynamicFormDefinition = createAsyncAction(
  'dynamic-forms/GET_DEFINITION_REQUEST',
  'dynamic-forms/GET_DEFINITION_SUCCESS',
  'dynamic-forms/GET_DEFINITION_FAILURE',
)<GetDynamicFormDefinitionParams, GetDynamicFormDefinitionResponseDto, GetDynamicFormDefinitionErrorDto>();
