import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';
import styled from 'styled-components';
import { Button } from '@hqo/react-components-library/dist/atoms/button';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px;

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 16px 16px 35px 16px;
    height: 100%;
    box-sizing: border-box;
  }
`;

export const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
`;

export const Subtitle = styled.div`
  line-height: 22px;
  margin: 16px 0 24px;
`;

export const ButtonStyled = styled(Button)`
  margin-top: auto;
  height: 50px;
`;

export const FieldWrapper = styled.div`
  margin: 8px 0;
`;
