import { DynamicForm } from '@hqo/dynamic-forms-sdk/form';
import { DynamicFormBuilder } from '@hqo/dynamic-forms-sdk/builder';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDynamicForm, selectDynamicFormRequestState } from 'store/dynamic-forms/selectors';
import { ACTION_STATUSES } from 'utils/constants';
import { getDynamicFormDefinition } from 'store/dynamic-forms/actions';
import { DynamicFormDto } from '@hqo/dynamic-forms-sdk/types';

const useFormDefinition = (formDefinitionDto: DynamicFormDto) => {
  return useMemo(() => {
    if (formDefinitionDto) {
      try {
        return [DynamicFormBuilder.fromJSON(formDefinitionDto).build(), null];
      } catch (error) {
        return [null, error];
      }
    }

    return [null, null];
  }, [formDefinitionDto]);
};

export const useDynamicFormDefinition = (formId?: string | null): [DynamicForm | null, boolean, Error | null] => {
  const getDynamicFormDefinitionRequestState = useSelector(selectDynamicFormRequestState(formId));
  const formDefinitionDto = useSelector(selectDynamicForm(formId));
  const dispatch = useDispatch();

  useEffect(() => {
    if (formId && !formDefinitionDto && !getDynamicFormDefinitionRequestState?.status) {
      dispatch(getDynamicFormDefinition.request({ formId }));
    }
  }, [formId, getDynamicFormDefinitionRequestState, formDefinitionDto, dispatch]);

  const [formDefinition, error] = useFormDefinition(formDefinitionDto);

  return [
    formDefinition,
    getDynamicFormDefinitionRequestState?.status === ACTION_STATUSES.PENDING,
    error ?? getDynamicFormDefinitionRequestState?.error ?? null,
  ];
};
