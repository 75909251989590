import { DetailsPageContents } from 'pages/details/details-page-contents';
import React from 'react';
import { ModalWrapper } from '@hqo/react-components-library/dist/molecules/modals/wrapper';

interface DetailsDesktopPageProps {
  onClose: VoidFunction;
}

export const DetailsDesktopPage: React.FC<DetailsDesktopPageProps> = ({
  onClose: handleClose,
}: DetailsDesktopPageProps): JSX.Element => {
  return (
    <ModalWrapper
      visible
      content={<DetailsPageContents />}
      exitOnBackgroundClick
      withExitButton
      withBackButton={false}
      onClose={handleClose}
      onPressBack={handleClose}
      backButtonVariant="chevron"
      backButtonText=""
      className="modal-wrapper"
    />
  );
};
