import { EstimateFigures } from 'components/estimates-box/styles';
import { useSelector } from 'react-redux';
import { selectBuilding } from 'store/building/selectors';
import { useIntl } from 'react-intl';
import { useEstimateFormatting } from 'hooks/use-estimate-formatting.hook';

interface Props {
  estimate: Estimate;
}

export const EstimatesBoxText = ({ estimate }: Props): JSX.Element => {
  const intl = useIntl();
  const building = useSelector(selectBuilding);
  const estimateRange = useEstimateFormatting(estimate, building?.locale);

  return (
    <div data-testid="estimate-box-text">
      {intl.formatMessage({ id: 'estimated_approval' })}
      <EstimateFigures data-testid="estimate-figures">{estimateRange}</EstimateFigures>
      {intl.formatMessage({ id: '.' })}
    </div>
  );
};
