import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { configSelector } from 'store/config/selectors';

export const useDynamicFormsLocalizationValues = () => {
  const intl = useIntl();

  return useMemo(
    () => ({
      continueButton: intl.formatMessage({ id: 'continue' }),
      skipButton: intl.formatMessage({ id: 'skip' }),
      submitButton: intl.formatMessage({ id: 'submit_request' }),
    }),
    [intl],
  );
};

export const useDynamicFormsLocaleConfig = () => {
  const config = useSelector(configSelector);

  return useMemo(() => ({ defaultCurrencyCode: 'USD', locale: config.locale }), [config.locale]);
};
