import { AttachmentsList } from 'pages/details/attachments-list';
import { DetailsContentsWrapper } from 'pages/details/details-contents-wrapper';
import { RequestDetails } from 'pages/details/request-details';
import { useSelector } from 'react-redux';
import { selectBuilding } from 'store/building/selectors';
import { selectCurrentServiceRequest } from 'store/serviceRequests/selectors';
import { FormAnswers } from './form-answers';
import { FeedbackSection } from './feedback-section';

interface DetailsPageContentsProps {
  openScheduleStep?: () => void;
  openFloorPlanStep?: () => void;
}

// eslint-disable-next-line max-lines-per-function
export const DetailsPageBody = ({ openScheduleStep, openFloorPlanStep }: DetailsPageContentsProps): JSX.Element => {
  const currentServiceRequest = useSelector(selectCurrentServiceRequest);
  const building = useSelector(selectBuilding);
  const { files } = { ...currentServiceRequest };

  const showDetails = !!building && !!currentServiceRequest;
  const showAttachments = !!files?.length;

  return (
    <>
      {currentServiceRequest?.feedback && (
        <DetailsContentsWrapper dataTestId="feedback-section">
          <FeedbackSection feedback={currentServiceRequest.feedback} />
        </DetailsContentsWrapper>
      )}
      <DetailsContentsWrapper>
        {showDetails && (
          <RequestDetails
            currentServiceRequest={currentServiceRequest}
            building={building}
            openScheduleStep={openScheduleStep}
            openFloorPlanStep={openFloorPlanStep}
          />
        )}
        {currentServiceRequest?.custom_form && <FormAnswers form={currentServiceRequest.custom_form} />}
      </DetailsContentsWrapper>
      {showAttachments && (
        <DetailsContentsWrapper>
          <AttachmentsList files={files} />
        </DetailsContentsWrapper>
      )}
    </>
  );
};
