import { FeedbackBox } from '@hqo/react-components-library/dist/molecules/feedback-box';
import { FC } from 'react';
import {
  useFeedBackForm,
  useIsFeedBackFormAvailable,
} from 'pages/details/details-feedback-box/details-feedback-box.hooks';
import { FeedBackBoxWrapper } from 'pages/details/details-feedback-box/details-feedback-box.styles';
import { useIntl } from 'react-intl';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks';
import { useFieldMetadata } from 'hooks/use-ui-metadata.hook';
import { MetadataFlags } from 'shared/consts/metadata-flags-enum';

interface DetailsFeedBackBoxProps {
  serviceRequest?: Pick<ServiceRequest, 'id' | 'feedback' | 'status'>;
}

export const DetailsFeedBackBox: FC<DetailsFeedBackBoxProps> = ({ serviceRequest }) => {
  const isFeedbackAvailable = useIsFeedBackFormAvailable(serviceRequest);
  const form = useFeedBackForm(serviceRequest?.id);
  const { formatMessage } = useIntl();
  const { value: isExpanded, toggle: toggleCommentInput } = useBooleanState();
  const maxRating = useFieldMetadata(MetadataFlags.MAX_RATING);

  return isFeedbackAvailable ? (
    <FeedBackBoxWrapper data-testid="feedback-box">
      <FeedbackBox
        title={formatMessage({ id: 'feedback_box.title' })}
        subtitle={formatMessage({ id: 'feedback_box.subtitle' })}
        buttonText={formatMessage({ id: 'feedback_box.button_text' })}
        rating={form.values.rating}
        max={maxRating}
        commentText={form.values.comment}
        placeholder={formatMessage({ id: 'feedback_box.placeholder' })}
        errorText={formatMessage({ id: form.errorTranslationId })}
        error={!form.isValid}
        onRatingChange={form.handleRatingChange}
        onCommentChange={form.handleCommentChange}
        onSubmit={form.handleSubmit}
        isCommentInputExpanded={isExpanded || form.isCommentRequired}
        isCommentRequired={form.isCommentRequired}
        onCommentInputToggle={toggleCommentInput}
      />
    </FeedBackBoxWrapper>
  ) : null;
};
