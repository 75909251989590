import { DetailsPageWrapper, HeaderWrapper, TitleWrapper } from 'pages/details/styles';
import { useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import { PageTitle } from '@hqo/react-components-library/dist/page-header';
import { UserEvents } from 'shared/consts';
import { track } from '@hqo/web-tracking';
import { ServiceRequestFeedContent } from 'pages/details/feed-content/feed-content';

interface RequestFeedProps {
  isVisible?: boolean;
}
export const RequestFeed = ({ isVisible }: RequestFeedProps) => {
  useEffect(() => {
    track(
      UserEvents.REQUEST_MESSAGE_IMPRESSION,
      { type: 'impression' },
      { sendToPendo: true, sendToHqoTracking: false },
    );
  }, []);
  return (
    <DetailsPageWrapper className="feed-page-wrapper" data-testid="feed-modal">
      <HeaderWrapper className="feed-header-wrapper">
        <TitleWrapper className="feed-title-wrapper">
          <PageTitle className="page-title">
            <FormattedMessage id="messages" />
          </PageTitle>
        </TitleWrapper>
      </HeaderWrapper>
      <ServiceRequestFeedContent isVisible={isVisible} />
    </DetailsPageWrapper>
  );
};
