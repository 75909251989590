import { IntlShape } from 'react-intl';
import { FIELD_NAMES } from 'utils/constants';

export const getTileTitle = (request: ServiceRequest, metadataFields: Array<MetadataField>, intl: IntlShape) => {
  const requestTypeField = metadataFields?.find(field => field.name === FIELD_NAMES.REQUEST_TYPE);
  if (requestTypeField) {
    const selectedRequestType = requestTypeField.options?.find(
      option => option.value?.toString() === request.request_type?.toString(),
    );
    return intl.formatMessage({
      id: `issueTypes.${selectedRequestType?.name}`,
      defaultMessage: selectedRequestType?.name ?? request.request_type.toString(),
    });
  }
  return request.description;
};
