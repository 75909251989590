import * as altBuildingActions from 'store/alt-buildings/actions';
import * as buildingActions from 'store/building/actions';
import * as configActions from 'store/config/actions';
import * as filesActions from 'store/files/actions';
import * as notificationsActions from 'store/errors/actions';
import * as serviceRequestsActions from 'store/serviceRequests/actions';
import * as themeActions from 'store/theme/actions';
import * as userActions from 'store/user/actions';
import * as dynamicFormsActions from 'store/dynamic-forms/actions';
import * as uiMetadataActions from 'store/ui-metadata/actions';
import * as lookupDataActions from 'store/lookup-data/actions';

import { CallHistoryMethodAction, routerActions } from 'connected-react-router';
import { LocationState, Path } from 'history';

import { ActionType } from 'typesafe-actions';

// TODO: remove RouterActions custom types after https://github.com/supasate/connected-react-router/issues/286 fixed
type Push = (path: Path, state?: LocationState) => CallHistoryMethodAction<[Path, LocationState?]>;
type Replace = (path: Path, state?: LocationState) => CallHistoryMethodAction<[Path, LocationState?]>;

interface RouterActions {
  push: Push;
  replace: Replace;
  // go: Go; etc.
}

const actions = {
  router: routerActions as RouterActions,
  user: userActions,
  serviceRequests: serviceRequestsActions,
  files: filesActions,
  building: buildingActions,
  altBuildings: altBuildingActions,
  config: configActions,
  theme: themeActions,
  notifications: notificationsActions,
  dynamicForms: dynamicFormsActions,
  uiMetadata: uiMetadataActions,
  lookupData: lookupDataActions,
};

export type RootAction = ActionType<typeof actions>;
export default actions;
