export enum UserEvents {
  SERVICE_REQUESTS_INITIALIZED = 'SERVICE_REQUESTS_INITIALIZED',
  REQUEST_MESSAGE_IMPRESSION = 'REQUEST_MESSAGE_IMPRESSION',
  REQUEST_MESSAGE_SUBMITTED = 'REQUEST_MESSAGE_SUBMITTED',
  WORK_ORDER_ESTIMATE_APPROVED = 'WORK_ORDER_ESTIMATE_APPROVED',
  WORK_ORDER_ESTIMATE_DECLINED = 'WORK_ORDER_ESTIMATE_DECLINED',
  REQUEST_SUBMITTED = 'REQUEST_SUBMITTED',
  REQUEST_CANCELED = 'REQUEST_CANCELED',
  REQUEST_SELECTED = 'REQUEST_SELECTED',
  ADD_REQUEST_CLICK = 'ADD_REQUEST_CLICK',
  REQUEST_MESSAGE_FAILED = 'REQUEST_MESSAGE_FAILED',
  RECURRING_WORK_ORDER_SELECTED = 'RECURRING_WORK_ORDER_SELECTED',
  SCHEDULED_WORK_ORDER_SELECTED = 'SCHEDULED_WORK_ORDER_SELECTED',
}
