import { ACTION_STATUSES, ERR_DEFAULT } from 'utils/constants';
import { compose } from 'redux';
import { AjaxError } from 'rxjs/ajax';

const reduxDevToolsExtensionCompose =
  process.env.NODE_ENV === 'development' && window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
export const composeEnhancers = reduxDevToolsExtensionCompose || compose;

export const isActionComplete = (action: string): boolean =>
  action === ACTION_STATUSES.FULFILLED || action === ACTION_STATUSES.REJECTED;

export function getErrorCode(error: Error): string {
  if (error instanceof AjaxError) {
    return error.response?.errorCode ?? ERR_DEFAULT;
  }

  return ERR_DEFAULT;
}
