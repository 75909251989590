import { RootState } from '../reducer';
import { createSelector } from 'reselect';
import { DynamicFormsState } from 'store/dynamic-forms/types';

export const dynamicFormsStateSelector = (state: RootState): DynamicFormsState => state.dynamicForms;

export const selectDynamicForm = (formId: string) =>
  createSelector(dynamicFormsStateSelector, state => state.dynamicFormDefinitions[formId]);

export const selectDynamicFormRequestState = (formId: string) =>
  createSelector(dynamicFormsStateSelector, state => state.getDynamicFormDefinitions[formId]);
