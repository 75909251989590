import { Avatar } from 'pages/details/feed-content/avatar';
import { BubbleWrapper } from 'pages/details/styles';
import { ChatBubbleStyled } from 'pages/details/feed-content/message-bubble.styles';
import { ChatBubbleVariants } from 'pages/details/utils';
import { currentUser } from 'store/user/selectors';
import { selectSendingMessageText } from 'store/serviceRequests/selectors';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export const SendingMessageBubble = (): JSX.Element => {
  const sendingText = useSelector(selectSendingMessageText);
  const { first_name, last_name } = useSelector(currentUser);
  const { formatMessage } = useIntl();
  const sending = formatMessage({ id: 'sending_message' });

  return (
    <BubbleWrapper
      variant={ChatBubbleVariants.SENDER}
      className="chat-bubble-skeleton-wrapper"
      data-testid="sender-skeleton"
    >
      <ChatBubbleStyled
        messageBody={sendingText}
        variant={ChatBubbleVariants.SENDER}
        timeStamp={sending}
        userName={`${first_name} ${last_name}`}
        avatar={<Avatar first_name={first_name} last_name={last_name} />}
      />
    </BubbleWrapper>
  );
};
