import React, { useCallback } from 'react';
import { TileContainer } from './styles';
import { ServiceRequestTile } from '../service-request-tile/component';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectBuilding } from 'store/building/selectors';
import { selectMetadataFields } from 'store/serviceRequests/selectors';
import { getTileTitle } from 'utils/getTileTitle';
import { requestSelectTrack } from '../../utils/trackingUtils';

interface Props {
  showStatus: boolean;
  request: ServiceRequest;
  isLastItem: boolean;
  redirectToDetailsView: (id: string) => void;
}

export const ServiceRequestListItem: React.FC<Props> = ({ showStatus, request, isLastItem, redirectToDetailsView }) => {
  const intl = useIntl();
  const building = useSelector(selectBuilding);
  const metadataFields = useSelector(selectMetadataFields);
  const building_name = building?.display_name ?? building?.name;

  const handleRequestClick = useCallback(() => {
    redirectToDetailsView(request.id);
    requestSelectTrack(request);
  }, [redirectToDetailsView, request]);

  return (
    <TileContainer lastTile={isLastItem}>
      <ServiceRequestTile
        showStatus={showStatus}
        onClick={handleRequestClick}
        location={building_name}
        request={request}
        title={getTileTitle(request, metadataFields, intl)}
      />
    </TileContainer>
  );
};
