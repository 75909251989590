import { useField } from 'formik';
import { Picker, generatePickerItem } from 'components/shared/fields/picker';
import { useIntl, IntlShape } from 'react-intl';
import { FieldWrapper } from './styles';
import { ServiceRequestFrequencyEnum } from 'store/serviceRequests/enums';

const getItems = (intl: IntlShape) =>
  [
    ServiceRequestFrequencyEnum.DAILY,
    ServiceRequestFrequencyEnum.WEEKLY,
    ServiceRequestFrequencyEnum.MONTHLY,
    ServiceRequestFrequencyEnum.QUARTERLY,
    ServiceRequestFrequencyEnum.SEMI_ANNUALLY,
    ServiceRequestFrequencyEnum.ANNUALLY,
  ].map(generatePickerItem(intl, 'frequency_values'));

interface FrequencyFieldProps {
  fieldName: string;
}

export const FrequencyField = ({ fieldName }: FrequencyFieldProps): JSX.Element => {
  const intl = useIntl();
  const [{ value }, { error, touched }, { setValue }] = useField<ServiceRequestFrequencyEnum>(fieldName);

  return (
    <FieldWrapper>
      <Picker
        label={intl.formatMessage({ id: 'frequency' })}
        placeholder={intl.formatMessage({ id: 'select_one' })}
        items={getItems(intl)}
        required
        value={value}
        onChange={setValue}
        error={touched && error}
      />
    </FieldWrapper>
  );
};
