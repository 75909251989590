/* eslint-disable max-lines-per-function */
import { DetailsContentsWrapper } from 'pages/details/details-contents-wrapper';
import {
  DetailsPageWrapper,
  DetailsSectionSkeletonWrapper,
  DetailsSkeleton,
  DetailsSkeletonCol,
  DetailsSkeletonWrapper,
  IconSkeletonCol,
} from 'pages/details/styles';
import { DetailsSkeletonVariants } from 'pages/details/utils';

export const DetailsPageLoader = (): JSX.Element => {
  return (
    <DetailsPageWrapper className="details-page-wrapper">
      <DetailsSkeletonWrapper data-testid="details-page-skeleton">
        <DetailsSkeleton variant={DetailsSkeletonVariants.TITLE} />
        <DetailsSkeleton variant={DetailsSkeletonVariants.SUBTITLE} />
        <DetailsContentsWrapper>
          <DetailsSkeleton variant={DetailsSkeletonVariants.SECTION} />
          <DetailsSectionSkeletonWrapper>
            <IconSkeletonCol>
              <DetailsSkeleton variant={DetailsSkeletonVariants.ICON} />
            </IconSkeletonCol>
            <DetailsSkeletonCol>
              <DetailsSkeleton variant={DetailsSkeletonVariants.LONG_DETAILS} />
              <DetailsSkeleton variant={DetailsSkeletonVariants.MEDIUM_DETAILS} />
              <DetailsSkeleton variant={DetailsSkeletonVariants.SHORT_DETAILS} />
            </DetailsSkeletonCol>
          </DetailsSectionSkeletonWrapper>
          <DetailsSectionSkeletonWrapper>
            <IconSkeletonCol>
              <DetailsSkeleton variant={DetailsSkeletonVariants.ICON} />
            </IconSkeletonCol>
            <DetailsSkeletonCol>
              <DetailsSkeleton variant={DetailsSkeletonVariants.MEDIUM_DETAILS} />
              <DetailsSkeleton variant={DetailsSkeletonVariants.SHORT_DETAILS} />
            </DetailsSkeletonCol>
          </DetailsSectionSkeletonWrapper>
        </DetailsContentsWrapper>
      </DetailsSkeletonWrapper>
    </DetailsPageWrapper>
  );
};
