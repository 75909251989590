import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import { RecurringRequestFormValues, useFormikData } from './recurring-request-form.hooks';
import { Wrapper, Title, Subtitle } from './styles';
import { Body } from './body';

export type RecurringFormSubmit = (values: RecurringRequestFormValues) => void;

interface RecurringRequestFormProps {
  initialValues: Partial<RecurringRequestFormValues>;
  onSubmit: RecurringFormSubmit;
}

export const RecurringRequestForm = ({ initialValues, onSubmit }: RecurringRequestFormProps): JSX.Element => {
  const intl = useIntl();
  const { formikInitialValues, validationSchema, handleSubmit } = useFormikData(initialValues, onSubmit);

  return (
    <Formik<RecurringRequestFormValues>
      initialValues={formikInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Wrapper data-testid="recurring-form-wrapper">
        <Title>{intl.formatMessage({ id: 'create_recurring_request' })}</Title>
        <Subtitle>{intl.formatMessage({ id: 'recurring_request_note' })}</Subtitle>
        <Body />
      </Wrapper>
    </Formik>
  );
};
