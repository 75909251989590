import { AltBuilding } from 'store/alt-buildings/types';
import { DefaultThemeWithFonts } from '@hqo/react-components-library/dist/tenant-web/elements/theme';
import { useAltBuildings } from 'hooks/alt-building.hook';
import { useBuildingTheme } from 'hooks/use-building-theme.hook';
import { useLoadBuildingTheme } from 'hooks/use-load-building-theme.hook';
import { useLoadCurrentUser } from 'hooks/use-load-current-user.hook';
import { useSelector } from 'react-redux';
import { useViewParams } from 'hooks/use-view-params.hook';
import { userLoading } from 'store/user/selectors';

interface AppLoadingData {
  loading: boolean;
  altBuildings: AltBuilding[];
  theme: DefaultThemeWithFonts;
}

export const useAppLoading = (): AppLoadingData => {
  useLoadCurrentUser();
  useViewParams();
  useLoadBuildingTheme();
  const loading = useSelector(userLoading);
  const altBuildings = useAltBuildings();
  const theme = useBuildingTheme();

  return { loading, altBuildings, theme };
};
