import { WheelPicker } from '@hqo/react-components-library/dist/molecules/wheel-picker-view';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  .picker-field {
    box-sizing: border-box;
    border: ${({ theme }) => `1px solid ${theme.colors.greys.adminGreyMedium}`};
  }
`;

export const WheelPickerStyled = styled(WheelPicker)`
  position: fixed;
  bottom: 0;
  width: auto;
  left: 16px;
  right: 16px;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.universal.white};
  z-index: 99;

  .wheel-picker-item {
    font-size: 23px;
    box-sizing: border-box;
  }
` as typeof WheelPicker;
