import { useMemo } from 'react';
import { StatusPill } from '@hqo/react-components-library/dist/atoms';
import { useIntl } from 'react-intl';
import { HqoTheme, useTheme } from 'styled-components';
import { StatusDisplay } from 'store/serviceRequests/enums';

interface StatusPillWrapperProps {
  status?: StatusDisplay;
}

const getColorMappings = (statusPills: HqoTheme['colors']['statusPills']): Record<StatusDisplay, string> => ({
  [StatusDisplay.Open]: statusPills.blue,
  [StatusDisplay.In_Progress]: statusPills.systemTeal,
  [StatusDisplay.Pending]: statusPills.orange,
  [StatusDisplay.Complete]: statusPills.green,
  [StatusDisplay.Canceled]: statusPills.grey,
  [StatusDisplay.On_Hold]: statusPills.systemPurple,
  [StatusDisplay.Denied]: statusPills.pink,
  [StatusDisplay.Failed]: statusPills.orange,
});

export const StatusPillWrapper = ({ status = StatusDisplay.Open }: StatusPillWrapperProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const colorMappings = useMemo(() => getColorMappings(theme.colors.statusPills), [theme.colors]);

  return (
    <StatusPill
      status={status}
      color={colorMappings[status]}
      variant="rounded"
      displayText={intl.formatMessage({ id: `status_display.${status}` })}
    />
  );
};
