import {
  SkeletonContainer,
  HeaderSkeleton,
  Header2Skeleton,
  SubtitleSkeleton,
  BodySkeleton,
} from 'pages/service-requests/serviceRequestModal/service-request-mobile-modal/custom-form-step.styles';

export const CustomFormSkeleton = () => (
  <SkeletonContainer data-testid="custom-form-skeleton">
    <HeaderSkeleton />
    <Header2Skeleton />
    <SubtitleSkeleton />
    <BodySkeleton />
  </SkeletonContainer>
);
