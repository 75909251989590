import { ModalWrapper } from '@hqo/react-components-library/dist/molecules/modals/wrapper';
import { NewRequestData } from '../create-service-request-step-content';
import { useGoBack } from 'hooks/use-go-back-handler.hook';
import { ServiceRequestModalProps } from '../service-request-modal-props.types';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { createServiceRequest } from '../../../../store/serviceRequests/actions';
import { CreateServiceRequestStep } from '../create-service-request-step';

export const ServiceRequestDesktopModal = ({ requestData }: ServiceRequestModalProps): JSX.Element => {
  const handleClose = useGoBack();
  const dispatch = useDispatch();

  const handleStepSubmit = useCallback(
    (newRequestData: NewRequestData) => {
      dispatch(createServiceRequest.request(newRequestData));
    },
    [dispatch],
  );

  return (
    <ModalWrapper
      visible
      content={<CreateServiceRequestStep requestData={requestData} handleStepSubmit={handleStepSubmit} />}
      withExitButton
      onClose={handleClose}
    />
  );
};
