import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { getCurrentUser } from 'store/user/actions';
import { hasToken as hasTokenSelector } from 'store/config/selectors';
import { currentUser } from 'store/user/selectors';

export const useLoadCurrentUser = () => {
  const user = useSelector(currentUser);
  const hasToken = useSelector(hasTokenSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user && hasToken) {
      dispatch(getCurrentUser.request());
    }
  }, [user, hasToken, dispatch]);
};
