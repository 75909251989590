import { useDispatch, useSelector } from 'react-redux';

import { Building } from 'store/building/types';
import { getBuilding } from 'store/building/actions';
import { selectBuilding, selectBuildingStatus } from 'store/building/selectors';
import { useEffect } from 'react';
import { configSelector } from 'store/config/selectors';

export const useBuilding = (): Building => {
  const { buildingUuid } = useSelector(configSelector);
  const dispatch = useDispatch();
  const building = useSelector(selectBuilding);
  const buildingFetchingStatus = useSelector(selectBuildingStatus);

  useEffect(() => {
    if (!building && buildingUuid && !buildingFetchingStatus) {
      dispatch(getBuilding.request(buildingUuid));
    }
  }, [building, buildingUuid, dispatch, buildingFetchingStatus]);

  return building;
};
