import { useCallback } from 'react';
import { MultilevelSelectLevel } from './multilevel-select-level';
import { Value } from '../../../hqo-wheel-picker/interfaces';
import { AdditionalFieldsProps, MultilevelSelectField } from '../../interfaces';

interface Props {
  field: MultilevelSelectField;
  additionalProps: AdditionalFieldsProps;
}

export const MultilevelSelect = ({
  field: { name, required, options, value },
  additionalProps: { handleSelectChange },
}: Props): JSX.Element => {
  const handleChange = useCallback(
    (selectedValue?: Value) => handleSelectChange(name, selectedValue),
    [name, handleSelectChange],
  );

  return (
    <MultilevelSelectLevel
      name={name}
      required={required}
      fieldValue={value}
      options={options}
      onChange={handleChange}
      currentLevel={1}
    />
  );
};
