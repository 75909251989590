import { StyledIconBlock } from 'pages/details/styles';
import { useIntl } from 'react-intl';

interface Props {
  currentServiceRequest: ServiceRequest;
}

export const AccessBlock = ({
  currentServiceRequest: { entry_access_granted, entry_access_notes },
}: Props): JSX.Element => {
  const intl = useIntl();
  return (
    <StyledIconBlock
      title={intl.formatMessage({
        id: entry_access_granted ? 'access.provider_may_enter' : 'access.provider_may_not_enter',
      })}
      titleType="bold"
      icon="key"
      subtitle={entry_access_granted && entry_access_notes}
      iconType="fal"
    />
  );
};
