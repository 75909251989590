import * as userActions from 'store/user/actions';
import * as buildingActions from 'store/building/actions';
import * as filesActions from 'store/files/actions';
import * as serviceRequestsActions from 'store/serviceRequests/actions';
import * as altBuildingActions from 'store/alt-buildings/actions';

import { concatMap, filter, map } from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { showCriticalErrorNotification, showErrorNotification } from './actions';
import { ERROR_PATH, ERR_DEFAULT } from 'utils/constants';
import { replace } from 'connected-react-router';
import { of } from 'rxjs';

export const handleFailedRequests: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(
      isActionOf([
        filesActions.uploadFiles.failure,
        serviceRequestsActions.getCurrentServiceRequest.failure,
        serviceRequestsActions.cancelServiceRequest.failure,
        serviceRequestsActions.getServiceRequestMetadata.failure,
        serviceRequestsActions.updateServiceRequest.failure,
        serviceRequestsActions.submitFeedback.failure,
      ]),
    ),
    map(action =>
      showErrorNotification({
        action: action.type,
        error: action.payload.error,
        errorCode: action.payload.errorCode ?? ERR_DEFAULT,
      }),
    ),
  );

export const handleCriticalErrors: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(
      isActionOf([
        altBuildingActions.getAltBuildings.failure,
        buildingActions.getBuilding.failure,
        userActions.getCurrentUser.failure,
      ]),
    ),
    concatMap(action =>
      of(
        showCriticalErrorNotification({
          action: action.type,
          error: action.payload.error,
          errorCode: action.payload.errorCode ?? ERR_DEFAULT,
        }),
        replace(ERROR_PATH),
      ),
    ),
  );
