import React from 'react';
import { usePicker } from './use-picker.hook';
import { Container, WheelPickerStyled } from './styles';
import { HqoPicker } from '@hqo/react-components-library/dist/molecules/hqo-picker';
import { useIntl } from 'react-intl';
import { Value, Items } from './interfaces';
import { WHEEL_PICKER_ITEM_HEIGHT } from 'utils/constants';

interface PickerProps {
  items: Items;
  label: string;
  placeholder: string;
  required?: boolean;
  disabled?: boolean;
  value?: Value;
  onChange: (value: Value) => void;
  error?: string;
}

// eslint-disable-next-line max-lines-per-function
export const HqoWheelPicker: React.FC<PickerProps> = ({
  items,
  label,
  placeholder,
  required,
  disabled,
  value,
  onChange,
  error,
}: PickerProps) => {
  const intl = useIntl();
  const { isPickerActive, pickerLabel, selectedValue, onItemClick, closePicker, tooglePickerActive, wheelPickerRef } =
    usePicker(items, value);

  return (
    <Container ref={wheelPickerRef} data-testid={disabled ? 'read-only-select' : undefined}>
      <HqoPicker
        label={label}
        placeholder={placeholder}
        isActive={isPickerActive}
        value={pickerLabel}
        onClick={tooglePickerActive}
        required={required}
        disabled={disabled}
        error={error}
      />
      {isPickerActive && (
        <WheelPickerStyled<Value>
          itemHeight={WHEEL_PICKER_ITEM_HEIGHT}
          defaultValue={selectedValue}
          items={items}
          onChange={onChange}
          onItemClick={onItemClick}
          outsideClickParams={{ onClickOutside: closePicker, outsideClickRef: wheelPickerRef }}
          doneButton={intl.formatMessage({ id: 'done' })}
          className="wheel-picker"
        />
      )}
    </Container>
  );
};
