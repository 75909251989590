import { formatFileName, getFileType } from 'pages/details/utils';
import { AttachmentsTitle } from './styles';
import { useIntl } from 'react-intl';
import { AttachmentListItem } from 'pages/details/attachment-list-item';

interface Props {
  files: ServiceRequestFile[];
}

export const AttachmentsList = ({ files }: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <>
      <AttachmentsTitle data-testid="attachments-list-title">
        {intl.formatMessage({ id: 'attachments' })}
      </AttachmentsTitle>
      {files.map(file => {
        const { path, url, mime_type, id, name } = { ...file };
        const fileType = getFileType(mime_type);
        const fileName = formatFileName(name ?? path);

        return <AttachmentListItem key={id} fileType={fileType} title={fileName} url={url} />;
      })}
    </>
  );
};
