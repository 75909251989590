import { useIntl } from 'react-intl';
import { SavePinButton, RemovePinButton, ButtonsWrapper } from '../styles';

interface ButtonsProps {
  showPin: boolean;
  removePin: () => void;
  savePin: () => void;
}

export const Buttons = ({ showPin, removePin, savePin }: ButtonsProps): JSX.Element => {
  const intl = useIntl();

  return (
    <ButtonsWrapper className="buttons-wrapper">
      {showPin && (
        <RemovePinButton
          variant="primaryLink"
          data-testid="remove-pin-button"
          text={intl.formatMessage({ id: 'floor_plan_pin.remove_pin' })}
          onClick={removePin}
        />
      )}
      <SavePinButton data-testid="save-pin-button" text={intl.formatMessage({ id: 'save' })} onClick={savePin} />
    </ButtonsWrapper>
  );
};
