import 'moment/min/locales';

import moment from 'moment-timezone';

export const TIME_LOWER_CASE_FORMAT = 'h:mm a';

export const formatMonthDayYear = (value: string, locale = 'en') => {
  return moment(value).locale(locale).format('L');
};

export const formatDate = (value: string | Date, timezone?: string, locale = 'en') => {
  return value ? moment.tz(value, timezone).locale(locale).format('LL') : null;
};

export const formatTime = (value: string | Date, timezone: string, locale: string, format = 'LT') => {
  return moment.tz(value, timezone).locale(locale).format(format);
};

export const formatTimeLabel = (dateTime: Date, locale: string): string =>
  dateTime.toLocaleTimeString(locale, { timeStyle: 'short' });

export const formatTimeValue = (dateTime: Date, timeZone?: string): string =>
  dateTime.toLocaleTimeString(undefined, {
    timeZone,
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
  });
