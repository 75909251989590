import { useFloorPlanPinning } from 'hooks/use-ui-metadata.hook';
import { useSelector } from 'react-redux';
import { selectCurrentServiceRequest } from '../../store/serviceRequests/selectors';
import { ExactLocationPin } from 'pages/details/exact-location-pin';
import { useIntl } from 'react-intl';

interface LocationTextProps {
  currentServiceRequest: ServiceRequest;
  openFloorPlanStep: () => void;
}
export const LocationSubtitle = ({
  currentServiceRequest: { location: locations, location_details: locationDetails, unit_code },
  openFloorPlanStep,
}: LocationTextProps): JSX.Element => {
  const intl = useIntl();
  const isPinningAvailable = useFloorPlanPinning();
  const floorPlan = useSelector(selectCurrentServiceRequest)?.floor_plan;
  const locationName = locations?.map(location => {
    return <div key={location.id}>{location.name}</div>;
  });

  return (
    <>
      {unit_code && `${intl.formatMessage({ id: 'unit' })} ${unit_code}`}
      {locationName}
      {locationDetails && <br />}
      {locationDetails}
      {floorPlan && isPinningAvailable && (
        <ExactLocationPin
          openFloorPlanStep={openFloorPlanStep}
          pin={floorPlan.pin}
          data-testid="floor-plan-pinning-button"
        />
      )}
    </>
  );
};
