import {
  ButtonConatiner,
  CommentButton,
  CommentIcon,
  MessagesButton,
  NewCommentNotification,
} from 'pages/details/details-mobile-page/feed-icon.styles';

import { selectCurrentServiceRequest } from 'store/serviceRequests/selectors';
import { useFeedAvailability } from 'hooks/use-feed-availability.hook';
import { useSelector } from 'react-redux';
import { useServiceRequestLoading } from 'pages/details/details-page.hooks';
import { useIntl } from 'react-intl';

interface FeedIconProps {
  onClick?: VoidFunction;
}

export const FeedIcon = ({ onClick: handleClick }: FeedIconProps) => {
  const isFeedButtonVisible = useFeedAvailability();
  const isLoading = useServiceRequestLoading();
  const currentServiceRequest = useSelector(selectCurrentServiceRequest);
  const showNewMessagesNotification = currentServiceRequest?.has_new_messages;
  const intl = useIntl();

  if (!isFeedButtonVisible || isLoading) {
    return null;
  }

  return (
    <ButtonConatiner onClick={handleClick} data-testid="open-feed-button">
      <MessagesButton
        iconDirection="left"
        Icon={
          <CommentButton type="button">
            <CommentIcon variant="adminBlack" />
            {showNewMessagesNotification && <NewCommentNotification data-testid="new-messages-notification" />}
          </CommentButton>
        }
        text={intl.formatMessage({ id: 'messages' })}
        variant="Outline"
      />
    </ButtonConatiner>
  );
};
