import { SectionTitle } from 'pages/details/styles';
import { useIntl } from 'react-intl';
import { useFieldMetadata } from 'hooks/use-ui-metadata.hook';
import { Collapsible } from '@hqo/react-components-library/dist/molecules/collapsible';
import { CollapsibleHeader, CommentWrapper, RatingTitle, CollapsibleWrapper, RatingStyled } from './styles';
import { MetadataFlags } from 'shared/consts/metadata-flags-enum';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks';

interface FeedbackProps {
  feedback: Feedback;
}

export const FeedbackSection = ({ feedback: { rating, comment } }: FeedbackProps) => {
  const intl = useIntl();
  const maxRating = useFieldMetadata(MetadataFlags.MAX_RATING);
  const { value: isOpened, toggle: toggleIsOpened } = useBooleanState(false);

  return (
    <>
      <SectionTitle>{intl.formatMessage({ id: 'your_feedback' })}</SectionTitle>
      {rating && (
        <>
          <RatingTitle data-testid="rating">{intl.formatMessage({ id: 'rating' })}</RatingTitle>
          <RatingStyled disabled value={rating} max={maxRating} size="sm" />
        </>
      )}
      {comment && (
        <CollapsibleWrapper data-testid="comment-collapsible">
          <Collapsible
            title={<CollapsibleHeader>{intl.formatMessage({ id: 'comments' })}</CollapsibleHeader>}
            isOpened={isOpened}
            onToggle={toggleIsOpened}
          >
            <CommentWrapper>{comment}</CommentWrapper>
          </Collapsible>
        </CollapsibleWrapper>
      )}
    </>
  );
};
