import {
  ButtonContainer,
  Container,
  FormContainer,
  PageContainer,
  StatusMessageContainer,
  StyledButton,
  Subtitle,
  Title,
  TitleContainer,
} from './styles';

import React, { useCallback } from 'react';

import { ServiceRequestFormProps } from './interfaces';
import { StatusMessage } from '@hqo/react-components-library/dist/molecules/statusMessage';
import { FormFields } from './form-fields';

// eslint-disable-next-line max-lines-per-function
export const ServiceRequestForm: React.FC<ServiceRequestFormProps> = ({
  title,
  subtitle,
  fields = [],
  handleInputChange,
  handleSelectChange,
  onFileDelete,
  onFileUpload,
  buttonLabel,
  buttonDisabled,
  onSubmit,
  onTextInputFocus,
  onTextInputBlur,
  statusMessage,
  statusText,
  hideActionButton,
  readOnly,
  className,
  buttonVariant,
  onRecurrenceSwitchChange,
  onSwitchDetailsEdit,
  onStatefulSwitchChange,
}: ServiceRequestFormProps) => {
  const onInputChange = useCallback(
    (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      handleInputChange(fieldName, event?.target?.value);
    },
    [handleInputChange],
  );
  const additionalFieldsProps = {
    readOnly,
    onInputChange,
    onTextInputFocus,
    onTextInputBlur,
    handleSelectChange,
    onFileDelete,
    onFileUpload,
    onRecurrenceSwitchChange,
    onSwitchDetailsEdit,
    onStatefulSwitchChange,
  };

  return (
    <Container data-testid="service-request-form" className={className}>
      <PageContainer>
        <TitleContainer>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </TitleContainer>
        <FormContainer data-testid="service-request-form-container" className="form-container">
          {statusMessage && (
            <StatusMessageContainer>
              <StatusMessage statusTitle={statusText} statusMessage={statusMessage} messageBold />
            </StatusMessageContainer>
          )}
          {!!fields && <FormFields fields={fields} fieldsAdditionalProps={additionalFieldsProps} />}
        </FormContainer>
      </PageContainer>
      {!hideActionButton && (
        <ButtonContainer>
          <StyledButton
            text={buttonLabel}
            onClick={onSubmit}
            variant={buttonVariant}
            disabled={buttonDisabled}
            data-testid="submit-button"
            data-cy="submit-button"
          />
        </ButtonContainer>
      )}
    </Container>
  );
};
