import { ResolvedIntlConfig } from 'react-intl';
import styled from 'styled-components';

const FontMedium = styled.span`
  font-weight: 500;
`;

export const DEFAULT_RICH_TEXT_ELEMENTS: ResolvedIntlConfig['defaultRichTextElements'] = {
  m: (str: string[]) => <FontMedium>{str}</FontMedium>,
};
