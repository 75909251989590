import { PROVIDERS_STATUSES } from '@hqo/react-components-library/dist/utils';
import { REQUEST_STATUS } from 'utils/constants';

const requestStatusMap: Record<number, string> = {
  [REQUEST_STATUS.NEW]: PROVIDERS_STATUSES.PENDING,
  [REQUEST_STATUS.ASSIGNED]: PROVIDERS_STATUSES.OPEN,
  [REQUEST_STATUS.PENDING]: PROVIDERS_STATUSES.OPEN,
  [REQUEST_STATUS.COMPLETED]: PROVIDERS_STATUSES.CLOSED,
  [REQUEST_STATUS.CANCELED]: PROVIDERS_STATUSES.CLOSED,
  [REQUEST_STATUS.DENIED]: PROVIDERS_STATUSES.CLOSED,
  [REQUEST_STATUS.FAILED]: PROVIDERS_STATUSES.CLOSED,
};

export const getMappedStatus = (status: number) => {
  return requestStatusMap[status] ?? PROVIDERS_STATUSES.CLOSED;
};
