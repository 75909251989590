import { ButtonWrapper, StyledButton, StyledLink } from 'pages/details/styles';
import { ROOT_PATH } from 'utils/constants';

interface CTAProps {
  requestId: string;
  buttonText: string;
  showCancelButton: boolean;
}

export const DetailsPageCTA = ({ requestId, buttonText, showCancelButton }: CTAProps): JSX.Element => {
  return (
    <ButtonWrapper className="button-wrapper">
      <StyledLink to={`${ROOT_PATH}/${requestId}/cancel`}>
        {showCancelButton && (
          <StyledButton text={buttonText} variant="secondary" className="cancel-button" data-testid="cancel-button" />
        )}
      </StyledLink>
    </ButtonWrapper>
  );
};
