import { PROVIDERS_STATUSES } from '@hqo/react-components-library/dist/utils';
import { useIntl, IntlShape } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  selectCurrentServiceRequest,
  selectCurrentServiceRequestStatus,
  selectMetadataFields,
  selectSubmitFeedbackStatus,
} from 'store/serviceRequests/selectors';
import { ACTION_STATUSES, FIELD_NAMES, REQUEST_STATUS } from 'utils/constants';
import { getMappedStatus } from 'utils/getMappedStatus';
import { selectUpdateEstimateStatus } from '../../store/serviceRequests/selectors';
import { REQUEST_STATUS_TO_NAME } from '../../utils/constants';

export const useServiceRequestLoading = (): boolean => {
  const { requestId } = useParams<{ requestId: string }>();
  const currentServiceRequestStatus = useSelector(selectCurrentServiceRequestStatus);
  const updateEstimateStatus = useSelector(selectUpdateEstimateStatus);
  const currentServiceRequest = useSelector(selectCurrentServiceRequest);
  const submitFeedbackStatus = useSelector(selectSubmitFeedbackStatus);

  return (
    (currentServiceRequestStatus === ACTION_STATUSES.PENDING && requestId !== currentServiceRequest?.id) ||
    updateEstimateStatus === ACTION_STATUSES.PENDING ||
    submitFeedbackStatus === ACTION_STATUSES.PENDING
  );
};

const useRequesterName = () => {
  const currentServiceRequest = useSelector(selectCurrentServiceRequest);
  const metadataFields = useSelector(selectMetadataFields);

  if (!currentServiceRequest?.is_created_on_behalf_of) {
    return null;
  }

  const field = metadataFields?.find(({ name }) => name === FIELD_NAMES.REPORT_ON_BEHALF_OF);
  return field?.options?.find(({ value }) => value === currentServiceRequest.requester_id)?.name;
};

interface DetailsPageHeaderInfo {
  title: string;
  subtitle: string;
  warning?: string;
  isOnBehalfOf: boolean;
}

export const useIsOnBehalfOf = () => {
  const currentServiceRequest = useSelector(selectCurrentServiceRequest);
  return currentServiceRequest?.is_created_on_behalf_of ?? false;
};

export const getDetailsPageHeaderSubtitle = (
  intl: IntlShape,
  serviceRequestMappedStatus: string,
  currentServiceRequest?: CurrentServiceRequest,
) => {
  if (serviceRequestMappedStatus === PROVIDERS_STATUSES.CLOSED.toLowerCase()) {
    const subtitleKey = currentServiceRequest?.status_pending // to handle Kode pending operations
      ? 'subtitles.pending'
      : `subtitles.closed.${REQUEST_STATUS_TO_NAME[currentServiceRequest?.status] ?? 'default'}`;

    return intl.formatMessage({ id: subtitleKey });
  }

  if (currentServiceRequest?.estimate?.id && serviceRequestMappedStatus === PROVIDERS_STATUSES.PENDING.toLowerCase()) {
    return null;
  }

  return intl.formatMessage({ id: `subtitles.${serviceRequestMappedStatus}` });
};

export const getWarningMessage = (intl: IntlShape, currentServiceRequest?: CurrentServiceRequest) => {
  if (currentServiceRequest?.progress_status?.length) {
    const failedCancelOperation = currentServiceRequest.progress_status.find(
      progress => progress.action === 'cancel' && progress.status === 'failed',
    );

    if (failedCancelOperation) {
      return intl.formatMessage({ id: 'cancel_failed_warning' });
    }
  }
  return null;
};

export const getTitleKey = (currentServiceRequest: CurrentServiceRequest, serviceRequestStatus: string) => {
  let titleKey;
  if (currentServiceRequest?.status_pending && serviceRequestStatus === PROVIDERS_STATUSES.CLOSED.toLowerCase()) {
    titleKey = 'titles.pending_operation.canceling';
  } else if (currentServiceRequest?.status_pending) {
    titleKey = 'titles.pending_operation.creating';
  } else if (currentServiceRequest?.status === REQUEST_STATUS.FAILED) {
    titleKey = 'titles.failed';
  } else {
    titleKey = `titles.${serviceRequestStatus}`;
  }
  return titleKey;
};

export const useDetailsPageHeaderInfo = (): DetailsPageHeaderInfo | null => {
  const intl = useIntl();
  const currentServiceRequest = useSelector(selectCurrentServiceRequest);
  const isOnBehalfOf = useIsOnBehalfOf();
  const isLoading = useServiceRequestLoading();
  const requesterName = useRequesterName();

  if (isLoading) {
    return null;
  }

  const serviceRequestStatus = getMappedStatus(currentServiceRequest?.status).toLowerCase();
  const titleKey = getTitleKey(currentServiceRequest, getMappedStatus(currentServiceRequest?.status).toLowerCase());
  const title = intl.formatMessage({ id: titleKey });

  const warningMessage = getWarningMessage(intl, currentServiceRequest);

  return {
    title,
    subtitle: getDetailsPageHeaderSubtitle(intl, serviceRequestStatus, currentServiceRequest),
    warning: requesterName ? intl.formatMessage({ id: 'behalf_of_warning' }, { requesterName }) : warningMessage,
    isOnBehalfOf,
  };
};
