export enum ACTION_STATUSES {
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
}

export enum FIELD_TYPES {
  SELECT = 'select',
  TEXT = 'text',
  FILE = 'file',
  SWITCH = 'switch',
}

export const STATUS_CODES = {
  OK: 200,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  FORBIDDEN: 403,
};

export const API_PATHS = {
  SERVICE_REQUEST: '/service-request/v1/buildings',
  USER: '/auth/current',
};
export const ERROR_PATH = '/error' as const;

export const ROOT_PATH = '/service-requests';

export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const DIMENSIONS = {
  SMALL: '480px',
  MEDIUM: '769px',
  LARGE: '1025px',
  XLARGE: '1440px',
};

export const DIMENSIONS_NUMBERS = {
  SMALL: 480,
  MEDIUM: 769,
  LARGE: 1025,
  XLARGE: 1440,
};

export enum FIELD_NAMES {
  REQUEST_TYPE = 'request_type',
  SECONDARY_TYPE = 'secondary_type_id',
  LOCATION = 'location',
  DESCRIPTION = 'description',
  ENTRY_ACCESS_GRANTED = 'entry_access_granted',
  ENTRY_ACCESS_NOTES = 'entry_access_notes',
  REPORT_ON_BEHALF_OF = 'report_on_behalf_of',
  SCHEDULE = 'schedule',
}

export enum REQUEST_STATUS {
  NEW = 0,
  PENDING = 1,
  COMPLETED = 2,
  DENIED = 3,
  CANCELED = 4,
  ASSIGNED = 5,
  FAILED = 6,
}

export const REQUEST_STATUS_TO_NAME: Record<number, string> = {
  0: 'new',
  1: 'pending',
  2: 'completed',
  3: 'denied',
  4: 'canceled',
  5: 'assigned',
  6: 'failed',
};

export const UPCOMING_REQUEST_STATUSES = ['new', 'pending', 'assigned'];
export const CANCELLABLE_REQUEST_STATUSES = ['new', 'pending'];

export const PAST_REQUEST_STATUSES = ['denied', 'canceled', 'completed', 'failed'];

export const Z_INDEX = {
  SUB_HEADER: 11,
  HEADER: 12,
  MODAL_BACKGROUND: 13,
  MODAL_CONTAINER: 14,
};

export const FILE_TYPES = '.jpeg, .png';

export const API_URL_LS_KEY = 'apiUrl';
export const APP_BRAND_LS_KEY = 'appBrand';
export const BUILDING_UUID_LS_KEY = 'buildingUuid';
export const LOCALE_LS_KEY = 'locale';
export const AUTH_TOKEN_LS_KEY = 'authToken';
export const SDK_APP_UUID = '0d9949ac-f107-4410-a54a-8e4004cdcd48';

export enum FontLocation {
  TITLE = 'title',
  HEADING = 'heading',
  BODY = 'body',
}

export const SERVICE_REQUEST_VERTICAL_NAME = 'SERVICE_REQUEST';

export const DEFAULT_APP_BRAND = 'HQO';

export const ERR_DEFAULT = 'default';
export const BAD_INTEGRATION_CONFIGURATION = 'BAD_INTEGRATION_CONFIGURATION';
export const BAD_SERVICE_REQUEST_OPERATION_ERROR = 'BAD_SERVICE_REQUEST_OPERATION_ERROR';
export const BUILDING_NOT_FOUND = 'BUILDING_NOT_FOUND';
export const FORBIDDEN_ERROR = 'FORBIDDEN_ERROR';
export const OPERATION_IS_NOT_SUPPORTED_BY_ADAPTER = 'OPERATION_IS_NOT_SUPPORTED_BY_ADAPTER';
export const UNEXPECTED_ERROR = 'UNEXPECTED_ERROR';
export const SERVICE_REQUEST_NOT_FOUND = 'SERVICE_REQUEST_NOT_FOUND';

export const LAUNCH_DARKLY_ANONYMOUS_EMAIL = 'anonymous@hqo.co';

export const DAY_START_TIME = '00:00';
export const DAY_FINAL_TIME = '23:45';

export const WHEEL_PICKER_ITEM_HEIGHT = 38;
