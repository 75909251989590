import * as actions from './actions';

import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { ERR_DEFAULT } from 'utils/constants';
import { selectBuilding } from 'store/building/selectors';

export const uploadFilesEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.uploadFiles.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]): Observable<RootAction> => {
      const building = selectBuilding(state);

      return apiClient(state)
        .uploadFiles(payload, building.uuid)
        .pipe(
          map(xhrPayload => {
            return actions.uploadFiles.success(xhrPayload.response.data);
          }),
          catchError(error => of(actions.uploadFiles.failure({ error, errorCode: ERR_DEFAULT }))),
        );
    }),
  );
