import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { ServiceRequestMobileModal } from './service-request-mobile-modal';
import { ServiceRequestDesktopModal } from './service-request-desktop-modal';
import { ServiceRequestModalProps } from './service-request-modal-props.types';
import { useMobileHeaderVisibility } from 'hooks/use-mobile-header-visibility.hook';

export const ServiceRequestModal = ({ requestData }: ServiceRequestModalProps): JSX.Element => {
  const isMobile = useIsSmallViewportWidth();

  useMobileHeaderVisibility();

  if (isMobile) {
    return <ServiceRequestMobileModal requestData={requestData} />;
  }

  return <ServiceRequestDesktopModal requestData={requestData} />;
};
