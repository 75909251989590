import { ButtonStyled } from './styles';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { ScheduleRequestFormValues } from './schedule-request-form.hooks';
import { MouseEventHandler } from 'react';
import { StartDateField } from './start-date-field';
import { StartTimeField } from './start-time-field';

export const Body = (): JSX.Element => {
  const intl = useIntl();
  const { handleSubmit } = useFormikContext<ScheduleRequestFormValues>();

  return (
    <>
      <StartDateField fieldName="startDate" />
      <StartTimeField fieldName="startTime" />
      <ButtonStyled
        data-testid="schedule-form-button"
        text={intl.formatMessage({ id: 'save' })}
        onClick={handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
      />
    </>
  );
};
