import { createAction, createAsyncAction } from 'typesafe-actions';

import { FailureActionPayload } from 'store/errors/types';

export const getServiceRequests = createAsyncAction(
  'GET_SERVICE_REQUESTS',
  'GET_SERVICE_REQUESTS_SUCCESS',
  'GET_SERVICE_REQUESTS_FAILURE',
)<void, Array<ServiceRequest>, FailureActionPayload>();

export const createServiceRequest = createAsyncAction(
  'CREATE_SERVICE_REQUEST',
  'CREATE_SERVICE_REQUEST_SUCCESS',
  'CREATE_SERVICE_REQUEST_FAILURE',
)<unknown, CurrentServiceRequest, FailureActionPayload>();

export const cancelServiceRequest = createAsyncAction(
  'CANCEL_SERVICE_REQUEST',
  'CANCEL_SERVICE_REQUEST_SUCCESS',
  'CANCEL_SERVICE_REQUEST_FAILURE',
)<string, void, FailureActionPayload>();

export const getServiceRequestMetadata = createAsyncAction(
  'GET_SERVICE_REQUEST_METADATA',
  'GET_SERVICE_REQUEST_METADATA_SUCCESS',
  'GET_SERVICE_REQUEST_METADATA_FAILURE',
)<void, ServiceRequestMetadata, FailureActionPayload>();

export const getCurrentServiceRequest = createAsyncAction(
  'GET_CURRENT_SERVICE_REQUEST',
  'GET_CURRENT_SERVICE_REQUEST_SUCCESS',
  'GET_CURRENT_SERVICE_REQUEST_FAILURE',
)<string, ServiceRequest, FailureActionPayload>();

export const updateLastReadTimestampRequest = createAsyncAction(
  'UPDATE_LAST_READ_TIMESTAMP_REQUEST',
  'UPDATE_LAST_READ_TIMESTAMP_REQUEST_SUCCESS',
  'UPDATE_LAST_READ_TIMESTAMP_REQUEST_FAILURE',
)<LastReadTimestampRequest, void, FailureActionPayload>();

export const createMessageRequest = createAsyncAction(
  'CREATE_MESSAGE_REQUEST',
  'CREATE_MESSAGE_SUCCESS',
  'CREATE_MESSAGE_FAILURE',
)<CreateMessageRequest, ServiceRequestMessage[], CreateMessageRequestFailure>();

export const resendFailedMessage = createAction('RESEND_FAILED_MESSAGE')<ResendFailedMessagePayload>();

export const getMessagesRequest = createAsyncAction(
  'GET_MESSAGES_REQUEST',
  'GET_MESSAGES_SUCCESS',
  'GET_MESSAGES_FAILURE',
)<GetMessagesRequest, Array<ServiceRequestMessage>, FailureActionPayload>();

export const updateEstimateStatus = createAsyncAction(
  'UPDATE_ESTIMATE_STATUS_REQUEST',
  'UPDATE_ESTIMATE_STATUS_SUCCESS',
  'UPDATE_ESTIMATE_STATUS_FAILURE',
)<UpdateEstimateStatusRequestPayload, ServiceRequest, FailureActionPayload>();

export const updateServiceRequest = createAsyncAction(
  'UPDATE_SERVICE_REQUEST',
  'UPDATE_SERVICE_REQUEST_SUCCESS',
  'UPDATE_SERVICE_REQUEST_FAILURE',
)<UpdateServiceRequestPayload, ServiceRequest, FailureActionPayload>();

export const submitFeedback = createAsyncAction(
  'SUBMIT_FEEDBACK_REQUEST',
  'SUBMIT_FEEDBACK_SUCCESS',
  'SUBMIT_FEEDBACK_FAILURE',
)<SubmitFeedbackPayload, Feedback, FailureActionPayload>();

export const addPinToFloorPlan = createAsyncAction(
  'ADD_PIN_TO_FLOOR_PLAN_REQUEST',
  'ADD_PIN_TO_FLOOR_PLAN_SUCCESS',
  'ADD_PIN_TO_FLOOR_PLAN_FAILURE',
)<AddPinToFloorPlanPayload, FloorPlan, FailureActionPayload>();

export const updateFloorPlanPin = createAsyncAction(
  'UPDATE_FLOOR_PLAN_PIN_REQUEST',
  'UPDATE_FLOOR_PLAN_PIN_SUCCESS',
  'UPDATE_FLOOR_PLAN_PIN_FAILURE',
)<UpdateFloorPlanPinPayload, FloorPlan, FailureActionPayload>();

export const removePinFromFloorPlan = createAsyncAction(
  'REMOVE_PIN_FROM_FLOOR_PLAN_REQUEST',
  'REMOVE_PIN_FROM_FLOOR_PLAN_SUCCESS',
  'REMOVE_PIN_FROM_FLOOR_PLAN_FAILURE',
)<void, void, FailureActionPayload>();

export const getSecondaryTypes = createAsyncAction(
  'GET_SECONDARY_TYPES_REQUEST',
  'GET_SECONDARY_TYPES_SUCCESS',
  'GET_SECONDARY_TYPES_FAILURE',
)<string, SecondaryRequestTypesResponse, FailureActionPayload>();

export const updateSetRecurringServiceRequest = createAction('UPDATE_SET_RECURRING_SERVICE_REQUEST')<boolean>();

export const updateSetScheduleServiceRequest = createAction('UPDATE_SET_SCHEDULE_SERVICE_REQUEST')<boolean>();

export const updateShowAttachment = createAction('UPDATE_SHOW_ATTACHMENT')<ShowAttachment>();

export const updateShowFeed = createAction('UPDATE_SHOW_FEED')<boolean>();

export const resetUiFlags = createAction('RESET_UI_FLAGS')();

export const resetCreateServiceRequest = createAction('RESET_CREATE_SERVICE_REQUEST')();

export const initializeServiceRequests = createAction('INITIALIZE_SERVICE_REQUESTS')<void>();

export const updateShowFloorPlanPinner = createAction('UPDATE_SHOW_FLOOR_PLAN_PINNER')<boolean>();

export const clearSecondaryTypes = createAction('CLEAR_SECONDARY_TYPES')<void>();
