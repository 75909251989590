import React from 'react';
import { Wrapper, Title } from './styles';
import { Body } from './body';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import { ScheduleRequestFormValues, useFormikData, defaultValues } from './schedule-request-form.hooks';

export type ScheduleFormSubmit = (values: ScheduleRequestFormValues) => void;

interface ScheduleRequestProps {
  onSubmit: ScheduleFormSubmit;
}

export const ScheduleRequestForm: React.FC<ScheduleRequestProps> = ({ onSubmit }): JSX.Element => {
  const intl = useIntl();
  const { handleSubmit, validationSchema } = useFormikData(onSubmit);

  return (
    <Formik<ScheduleRequestFormValues>
      validationSchema={validationSchema}
      initialValues={defaultValues}
      onSubmit={handleSubmit}
    >
      <Wrapper data-testid="schedule-form-wrapper">
        <Title>{intl.formatMessage({ id: 'schedule.title' })}</Title>
        <Body />
      </Wrapper>
    </Formik>
  );
};
