import { goBack } from 'connected-react-router';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { clearSecondaryTypes, resetUiFlags } from 'store/serviceRequests/actions';

export const useGoBack = (): VoidFunction => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(goBack());
    dispatch(resetUiFlags());
    dispatch(clearSecondaryTypes());
  }, [dispatch]);
};
