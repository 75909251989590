import { apiUrlSelector, currentToken, getBuildingUuid } from 'store/config/selectors';

import { SERVICE_REQUEST_VERTICAL_NAME } from '../utils/constants';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export interface WebTrackingData {
  buildingUuid: string;
  appUuid: string;
  token: string;
  apiUrl: string;
}
export const useWebTrackingAnalytics = (): WebTrackingData | null => {
  const buildingUuid = useSelector(getBuildingUuid);
  const token = useSelector(currentToken);
  const currentApiUrl = useSelector(apiUrlSelector);
  return useMemo(() => {
    if (!buildingUuid || !token || !currentApiUrl) {
      return null;
    }
    return {
      buildingUuid,
      appUuid: SERVICE_REQUEST_VERTICAL_NAME,
      token: token.replace('Bearer ', ''),
      apiUrl: currentApiUrl,
    };
  }, [buildingUuid, token, currentApiUrl]);
};
