import React, { useCallback } from 'react';
import { IconButtonWrapper, Icon } from 'pages/details/styles';
import { FeedIcon } from 'pages/details/details-mobile-page/feed-icon';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getMessagesRequest } from 'store/serviceRequests/actions';

interface DetailsMobilePageProps {
  onOpenFeed: VoidFunction;
  isFeedOpened: boolean;
}

export const DetailsMobilePageRightIcon: React.FC<DetailsMobilePageProps> = ({
  onOpenFeed: openFeed,
  isFeedOpened,
}: DetailsMobilePageProps): JSX.Element => {
  const { requestId } = useParams<{ requestId: string }>();
  const dispatch = useDispatch();
  const onRefreshClick = useCallback(() => {
    dispatch(getMessagesRequest.request({ request_uuid: requestId }));
  }, [requestId, dispatch]);

  return isFeedOpened ? (
    <IconButtonWrapper onClick={onRefreshClick} data-testid="refresh-feed-button" type="button">
      <Icon icon={['fas', 'redo']} />
    </IconButtonWrapper>
  ) : (
    <FeedIcon onClick={openFeed} />
  );
};
