import { DIMENSIONS } from 'utils/constants';
import styled from 'styled-components';
import { ServiceRequestForm } from 'components/service-request-form';

export const Content = styled.div`
  padding: 60px;

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 16px 16px 35px 16px;
    height: 100%;
    box-sizing: border-box;
  }
`;

export const StyledForm = styled(ServiceRequestForm)<{ isTextFocused?: boolean }>`
  .form-container {
    @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
      padding-bottom: ${({ isTextFocused }) => (isTextFocused ? `40vh` : '0px')};
    }
  }
  color: ${({ theme }) => theme.colors.fontColor};

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    height: 100%;
  }
`;
