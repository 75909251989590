import {
  RecurrenceEnd,
  RecurringRequestFormValues,
} from 'pages/service-requests/serviceRequestModal/service-request-mobile-modal/recurring-request-form/recurring-request-form.hooks';
import { ServiceRequestFrequencyEnum } from 'store/serviceRequests/enums';
import moment from 'moment';
import { Item } from '@hqo/react-components-library/dist/molecules/wheel-picker-view';

export interface MappedRecurringData {
  frequency: ServiceRequestFrequencyEnum;
  start_date: string;
  end_date?: string;
  total_run_count?: number;
}

interface Option extends Item<string> {
  form_id?: string;
}

export interface MetadataFieldView extends Omit<MetadataField, 'options'> {
  value?: string | boolean | number | string[];
  options?: Option[];
  switchDetailsLabel?: null | JSX.Element;
  switchDetailsEditButtonLabel?: string;
}

export const mapRecurringData = (recurringData?: Partial<RecurringRequestFormValues>) => {
  if (!recurringData) {
    return undefined;
  }

  let mappedRecurringData: MappedRecurringData = {
    frequency: recurringData.frequency,
    start_date: moment().format(),
  };

  if (recurringData.recurrenceEnds === RecurrenceEnd.DATE) {
    mappedRecurringData = { ...mappedRecurringData, end_date: recurringData.endDate };
  }

  if (recurringData.recurrenceEnds === RecurrenceEnd.OCCURRENCES_COUNT) {
    mappedRecurringData = { ...mappedRecurringData, total_run_count: Number(recurringData.count) };
  }

  return mappedRecurringData;
};
export const mapRecurringDataLabel = (recurringData?: Partial<RecurringRequestFormValues>) => {
  const mappedData = mapRecurringData(recurringData);
  if (!mappedData) {
    return undefined;
  }
  return {
    frequency: mappedData.frequency,
    start_date: new Date(mappedData.start_date),
    end_date: mappedData.end_date && new Date(mappedData.end_date),
    total_run_count: mappedData?.total_run_count,
  };
};
