import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'utils/constants';
import { FilesState } from 'store/files/types';

export const INITIAL_STATE: FilesState = {
  files: null,
  fileType: null,
  containerId: null,
  uploadFiles: {
    status: null,
    error: null,
  },
};

const uploadFilesRequestHandler = (state: FilesState): FilesState => ({
  ...state,
  uploadFiles: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const uploadFilesSuccessHandler = (
  state: FilesState,
  { payload }: ActionType<typeof actions.uploadFiles.success>,
): FilesState => ({
  ...state,
  files: payload.files,
  fileType: payload.type,
  containerId: payload.container_id,
  uploadFiles: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const uploadFilesFailureHandler = (
  state: FilesState,
  { payload }: ActionType<typeof actions.uploadFiles.failure>,
): FilesState => ({
  ...state,
  uploadFiles: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const resetFilesHandler = (state: FilesState) => ({
  ...state,
  ...INITIAL_STATE,
});

const FilesReducer = createReducer(INITIAL_STATE)
  .handleAction(actions.uploadFiles.request, uploadFilesRequestHandler)
  .handleAction(actions.uploadFiles.success, uploadFilesSuccessHandler)
  .handleAction(actions.uploadFiles.failure, uploadFilesFailureHandler)
  .handleAction(actions.resetFiles, resetFilesHandler);

export default FilesReducer;
