import styled from 'styled-components';
import { skeletonAnimation } from 'utils/skeleton-animation';

export const Container = styled.div`
  padding: 16px 16px 0 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HeaderSkeleton = styled.div`
  height: 36px;
  margin: 16px 16px 8px;
  ${skeletonAnimation}
`;

export const Header2Skeleton = styled.div`
  height: 36px;
  margin: 0 auto 16px 16px;
  width: 65%;
  ${skeletonAnimation}
`;

export const SubtitleSkeleton = styled.div`
  height: 22px;
  margin: 0 auto 24px 16px;
  width: 85%;
  ${skeletonAnimation}
`;

export const BodySkeleton = styled.div`
  height: 224px;
  margin: 0 16px auto;
  ${skeletonAnimation}
`;
