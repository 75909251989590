import { getBuildingTheme } from 'store/theme/actions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { configSelector } from 'store/config/selectors';

export const useLoadBuildingTheme = () => {
  const dispatch = useDispatch();
  const { buildingUuid } = useSelector(configSelector);

  useEffect(() => {
    if (buildingUuid) {
      dispatch(getBuildingTheme.request(buildingUuid));
    }
  }, [buildingUuid, dispatch]);
};
