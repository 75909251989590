import { Message } from 'pages/details/feed-content/message-bubble';
import { MessageTypes } from 'pages/details/utils';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { currentUser } from 'store/user/selectors';
import { MessageResendHandler } from './use-message-resend-handler.hook';

interface DisplayedMessageInfo {
  message: Message;
  errorText?: string;
  handleMessageClick?: VoidFunction;
}

const mapFailedMessage = (failedMessage: ServiceRequestFailedMessage, author: Message['author']) => ({
  id: failedMessage.id,
  text: failedMessage.text,
  created_at: failedMessage.created_at,
  type: MessageTypes.USER,
  read: true,
  author,
});

const useCurretUserMessageAuthor = (): Message['author'] => {
  const user = useSelector(currentUser);

  return useMemo(
    () => ({
      id: user.uuid,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      full_name: `${user.first_name} ${user.last_name}`,
    }),
    [user],
  );
};

export const useDisplayedMessages = (
  messages: ServiceRequestMessage[],
  failedMessages: ServiceRequestFailedMessage[],
  handleFailedMessageClick?: MessageResendHandler,
): DisplayedMessageInfo[] => {
  const author = useCurretUserMessageAuthor();
  const intl = useIntl();
  const errorText = intl.formatMessage({ id: 'feed_message_sending_error' });

  return useMemo(() => {
    const formattedFailedMessages = failedMessages.map(failedMessage => {
      const message = mapFailedMessage(failedMessage, author);

      return {
        message,
        errorText,
        handleMessageClick: () => handleFailedMessageClick(message),
      };
    });

    return [...formattedFailedMessages, ...messages.map(message => ({ message }))].sort(
      ({ message: firstMessage }, { message: secondMessage }) =>
        new Date(secondMessage.created_at).valueOf() - new Date(firstMessage.created_at).valueOf(),
    );
  }, [messages, failedMessages, author, errorText, handleFailedMessageClick]);
};
