import { ActionType, createReducer } from 'typesafe-actions';
import * as lookupDataActions from './actions';

import { LookupDataState } from './types';
import { ACTION_STATUSES } from 'utils/constants';
import { LookupDataId, LookupDataItem, MultilevelSelectItems } from 'types/lookup-data';

const mapLookupDataToItems = (data: LookupDataItem[]): MultilevelSelectItems =>
  data.map(({ id, name, hasChildren }) => ({ value: id, label: name, hasChildren }));

export const createCacheKey = (fieldName: string, primaryUuid: LookupDataId) => `${fieldName}_${primaryUuid}`;

export const initialState: LookupDataState = {
  cache: {},
  getLookupDataRequest: {
    status: null,
    error: null,
  },
};

const getLookupDataRequestHandler = (state: LookupDataState): LookupDataState => ({
  ...state,
  getLookupDataRequest: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getLookupDataSuccessHandler = (
  state: LookupDataState,
  { payload }: ActionType<typeof lookupDataActions.getLookupData.success>,
): LookupDataState => ({
  ...state,
  cache: {
    ...state.cache,
    [createCacheKey(payload.fieldName, payload.primaryUuid)]: mapLookupDataToItems(payload.data),
  },
  getLookupDataRequest: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getLookupDataFailureHandler = (
  state: LookupDataState,
  { payload }: ActionType<typeof lookupDataActions.getLookupData.failure>,
): LookupDataState => ({
  ...state,
  getLookupDataRequest: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

export default createReducer(initialState)
  .handleAction(lookupDataActions.getLookupData.request, getLookupDataRequestHandler)
  .handleAction(lookupDataActions.getLookupData.success, getLookupDataSuccessHandler)
  .handleAction(lookupDataActions.getLookupData.failure, getLookupDataFailureHandler);
