import { FeedInputWrapper, RequestFeedWrapper, StyledMessageBar } from 'pages/details/feed-content/feed-content.styles';

import { Messages } from 'pages/details/feed-content/messages';
import { MetadataFlags } from 'shared/consts/metadata-flags-enum';
import { useFieldMetadata } from 'hooks/use-ui-metadata.hook';
import { useIntl } from 'react-intl';
import { useRequestFeed } from 'hooks/use-request-feed.hook';

interface ServiceRequestFeedContentProps {
  isVisible?: boolean;
}

export const ServiceRequestFeedContent = ({ isVisible }: ServiceRequestFeedContentProps): JSX.Element => {
  const { onSubmit, onChange, text, disabled } = useRequestFeed();
  const { formatMessage } = useIntl();
  const isMessageSendingVisible = useFieldMetadata(MetadataFlags.MESSAGE_SENDING_ENABLED);
  return (
    <RequestFeedWrapper data-testid="request-feed-wrapper" class-name="request-feed-wrapper">
      <Messages isVisible={isVisible} />
      {isMessageSendingVisible && (
        <FeedInputWrapper>
          <StyledMessageBar
            onClick={onSubmit}
            disabled={disabled}
            onChange={onChange}
            value={text}
            placeholder={formatMessage({ id: 'send_a_message' })}
          />
        </FeedInputWrapper>
      )}
    </RequestFeedWrapper>
  );
};
