import { MessagesBubble } from 'pages/details/feed-content/message-bubble';
import { useDisplayedMessages } from 'pages/details/feed-content/use-displayed-messages.hook';
import { MessagesDate } from 'pages/details/styles';
import { formatDate } from 'utils';
import { MessageResendHandler } from './use-message-resend-handler.hook';

interface Props {
  messages: ServiceRequestMessage[];
  failedMessages: ServiceRequestFailedMessage[];
  onFailedMessageClick?: MessageResendHandler;
}
export const MessagesContent = ({
  messages,
  failedMessages,
  onFailedMessageClick: handleFailedMessageClick,
}: Props) => {
  const displayedMessages = useDisplayedMessages(messages, failedMessages, handleFailedMessageClick);

  return (
    <>
      {displayedMessages.flatMap(({ message, errorText, handleMessageClick }, i) => {
        const { id, created_at } = message;
        const messageDate = formatDate(created_at);
        const nextDate = formatDate(displayedMessages[i + 1]?.message?.created_at);

        return [
          <MessagesBubble key={id} message={message} errorText={errorText} onMessageClick={handleMessageClick} />,
          messageDate !== nextDate ? (
            <MessagesDate key={`${id}-date`} data-testid="messages-date">
              {messageDate}
            </MessagesDate>
          ) : null,
        ];
      })}
    </>
  );
};
