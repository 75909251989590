import { useFormikContext } from 'formik';
import { EndDateField } from './end-date-field';
import { FrequencyField } from './frequency-field';
import { RecurrenceEndsField } from './recurrence-ends-field';
import { OccurrencesCountField } from './occurrences-count-field';
import { RecurrenceEnd, RecurringRequestFormValues } from './recurring-request-form.hooks';
import { useIntl } from 'react-intl';
import { ButtonStyled } from './styles';
import { MouseEventHandler } from 'react';

export const Body = (): JSX.Element => {
  const intl = useIntl();
  const { values, handleSubmit } = useFormikContext<RecurringRequestFormValues>();

  return (
    <>
      <FrequencyField fieldName="frequency" />
      <RecurrenceEndsField fieldName="recurrenceEnds" />
      {values.recurrenceEnds === RecurrenceEnd.DATE && <EndDateField fieldName="endDate" />}
      {values.recurrenceEnds === RecurrenceEnd.OCCURRENCES_COUNT && <OccurrencesCountField fieldName="count" />}
      <ButtonStyled
        data-testid="recurring-form-button"
        text={intl.formatMessage({ id: 'save' })}
        onClick={handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
      />
    </>
  );
};
