import { useIntl } from 'react-intl';
import { SwitchField, CheckboxWrapper } from '@hqo/react-components-library/dist/checkbox';
import { SwitchContainer } from './styles';

interface ScheduleSwitchProps {
  openScheduleStep?: () => void;
}
export const ScheduleSwitch: React.FC<ScheduleSwitchProps> = ({ openScheduleStep }): JSX.Element => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'schedule.title' });

  return (
    <SwitchContainer data-testid="switch-container">
      <CheckboxWrapper title={title} data-testid="schedule-switch">
        <SwitchField onChange={openScheduleStep} />
      </CheckboxWrapper>
    </SwitchContainer>
  );
};
