import { ConfigState } from 'store/config/types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';

export const configSelector = (state: RootState): ConfigState => state.config;
export const hasToken = createSelector(configSelector, ({ authToken }: RootState['config']): boolean => !!authToken);
export const getBuildingUuid = createSelector(
  configSelector,
  ({ buildingUuid }: RootState['config']): string => buildingUuid,
);
export const apiUrlSelector = createSelector(configSelector, (state: RootState['config']): string => state.apiUrl);
export const currentToken = createSelector(configSelector, (state: RootState['config']): string => state.authToken);
