import { useCallback, useMemo, useRef } from 'react';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks/use-boolean-state';
import { Item } from '@hqo/react-components-library/dist/molecules/wheel-picker-view';
import { Value } from './interfaces';

const useItemClick = (closePicker: VoidFunction, selectedValue?: Value) =>
  useCallback(
    (value: Value) => {
      if (value === selectedValue) {
        closePicker();
      }
    },
    [selectedValue, closePicker],
  );

export const usePicker = (items: Item<Value>[], selectedValue?: Value) => {
  const { value: isPickerActive, setFalse: closePicker, toggle: tooglePickerActive } = useBooleanState(false);
  const pickerLabel = useMemo(() => items?.find(({ value }) => value === selectedValue)?.label, [items, selectedValue]);
  const wheelPickerRef = useRef<HTMLDivElement>(null);

  return {
    isPickerActive,
    pickerLabel,
    selectedValue,
    onItemClick: useItemClick(closePicker, selectedValue),
    closePicker,
    tooglePickerActive,
    wheelPickerRef,
  };
};
