import { DynamicFormDto, FieldType } from '@hqo/dynamic-forms-sdk/types';
import { DynamicFormBuilder } from '@hqo/dynamic-forms-sdk/builder';
import { formatDate } from '../../utils/dateFormatting';
import { FormField } from '@hqo/dynamic-forms-sdk/form';

export interface FormAnswer {
  question: string;
  answer: string;
}

const prepareFieldValue = (field: FormField) => {
  const stringValue = field.value.toString();

  return field.type === FieldType.DATE ? formatDate(stringValue) : stringValue;
};

export const useFormAnswers = (form: DynamicFormDto): FormAnswer[] => {
  const formData = DynamicFormBuilder.fromJSON(form).build();
  const fields = formData.steps.flatMap(step => step.fields);

  return fields
    .filter(field => field.value !== null)
    .map(field => ({ question: field.label, answer: prepareFieldValue(field) }));
};
