import { useMiniappSdk } from 'hooks/use-miniapp-sdk.hook';
import { useEffect } from 'react';

export const useMobileHeaderVisibility = () => {
  const sdk = useMiniappSdk();

  useEffect(() => {
    sdk.header.hideHeader();

    return () => {
      sdk.header.showHeader();
    };
  }, [sdk]);
};
