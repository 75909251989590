import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFiles as uploadFilesAction } from 'store/files/actions';
import { filesStateSelector } from 'store/files/selectors';
import { FileUploadResponseType } from 'store/files/types';

export const useUploadFiles = (attachments: File[]) => {
  const dispatch = useDispatch();
  const { files, fileType, containerId } = useSelector(filesStateSelector);
  const [uploading, setUploading] = useState<boolean>(false);
  const uploadFiles = useCallback(() => {
    setUploading(true);
    const formData = new FormData();
    attachments.forEach(file => {
      formData.append('files', file);
    });
    dispatch(uploadFilesAction.request(formData));
  }, [attachments, dispatch]);
  const fileValueVariants = useMemo(
    () => ({
      [FileUploadResponseType.FILE]: files,
      [FileUploadResponseType.CONTAINER]: containerId,
    }),
    [containerId, files],
  );
  const setFileUpload = useCallback((upload: boolean) => {
    setUploading(upload);
  }, []);
  return {
    uploadFiles,
    fileUploaded: !!((files || containerId) && fileType),
    fileValueVariants: fileValueVariants[fileType],
    uploading,
    setUploading: setFileUpload,
  };
};
