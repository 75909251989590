export enum ChatBubbleVariants {
  SENDER = 'sender',
  RECEIVER = 'receiver',
  SYSTEM = 'system',
}

export enum MessageTypes {
  USER = 'user',
  SYSTEM = 'system',
}

export const assignMessageVariant = (
  message: Pick<ServiceRequestMessage, 'author' | 'type'>,
  email: string,
): ChatBubbleVariants => {
  let variant: ChatBubbleVariants;
  const { author, type } = message;

  if (type === MessageTypes.SYSTEM) {
    variant = ChatBubbleVariants.SYSTEM;
  } else {
    variant =
      author.email.toLowerCase() === email.toLowerCase() ? ChatBubbleVariants.SENDER : ChatBubbleVariants.RECEIVER;
  }

  return variant;
};

export enum ChatSkeletonVariants {
  SENDER = 'sender',
  RECEIVER = 'receiver',
}

export enum DetailsSkeletonVariants {
  TITLE = 'title',
  SUBTITLE = 'subtitle',
  SECTION = 'section',
  ICON = 'icon',
  LONG_DETAILS = 'long_details',
  MEDIUM_DETAILS = 'medium_details',
  SHORT_DETAILS = 'short_details',
}

export enum FileTypes {
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'music',
  TEXT = 'file',
}

export const getFileType = (mimeType: string) => {
  const fileType = mimeType.split('/')[0];
  const fileTypeMapped = fileType.toUpperCase() as keyof typeof FileTypes;

  return FileTypes[fileTypeMapped] ?? FileTypes.TEXT;
};

export const formatFileName = (fileName: string) => {
  if (fileName.length <= 25) return fileName;

  return fileName.replace(fileName.slice(15, -7), '...');
};
