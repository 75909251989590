export const formatCurrency = (currencyValue: number, currencyType: string, digits: number, locale: string): string => {
  const value = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyType,
    minimumFractionDigits: digits,
  }).format(currencyValue);

  return value;
};

export const formatCurrencyNoSign = (currencyValue: number, digits: number, locale: string): string => {
  const value = new Intl.NumberFormat(locale, {
    minimumFractionDigits: digits,
  }).format(currencyValue);

  return value;
};
