import { createMessageRequestStatus, selectCurrentServiceRequest } from 'store/serviceRequests/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { ACTION_STATUSES } from 'utils/constants';
import { PROVIDERS_STATUSES } from '@hqo/react-components-library/dist/utils';
import { createMessageRequest } from 'store/serviceRequests/actions';
import { getMappedStatus } from 'utils/getMappedStatus';
import { useCallback } from 'react';
import { useInputValue } from '@hqo/react-components-library/dist/hooks';
import { useParams } from 'react-router-dom';

interface RequestFeedHook {
  onSubmit(): void;
  onChange(text: React.ChangeEvent<HTMLTextAreaElement>): void;
  text: string;
  disabled: boolean;
}
export const useRequestFeed = (): RequestFeedHook => {
  const [text, handleChange] = useInputValue('');
  const { requestId } = useParams<{ requestId: string }>();
  const currentServiceRequest = useSelector(selectCurrentServiceRequest);
  const dispatch = useDispatch();
  const messageStatus = useSelector(createMessageRequestStatus);
  const onSubmit = useCallback((): void => {
    if (text && messageStatus !== ACTION_STATUSES.PENDING) {
      dispatch(
        createMessageRequest.request({
          requestUuid: requestId,
          text,
        }),
      );
      handleChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
    }
  }, [requestId, dispatch, messageStatus, text, handleChange]);

  const serviceRequestStatus = getMappedStatus(currentServiceRequest?.status).toLowerCase();
  const isMessageCanceled = serviceRequestStatus === PROVIDERS_STATUSES.CLOSED.toLowerCase();

  return { onSubmit, onChange: handleChange, text, disabled: isMessageCanceled };
};
