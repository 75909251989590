import moment from 'moment';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { IntlShape, useIntl } from 'react-intl';

export interface ScheduleRequestFormValues {
  startDate: string;
  startTime: string;
}

export const defaultValues: ScheduleRequestFormValues = {
  startDate: moment().format(),
  startTime: null,
};

const getValidationSchema = (intl: IntlShape) =>
  yup.object().shape({
    startDate: yup
      .string()
      .nullable()
      .required(intl.formatMessage({ id: 'field_errors.schedule_date_required' })),
    startTime: yup
      .string()
      .nullable()
      .required(intl.formatMessage({ id: 'field_errors.schedule_time_required' })),
  });

export const useFormikData = (submitForm: (values: ScheduleRequestFormValues) => void) => {
  const intl = useIntl();

  const handleSubmit = useCallback(
    (values: ScheduleRequestFormValues) => {
      submitForm(values);
    },
    [submitForm],
  );
  return {
    handleSubmit,
    validationSchema: useMemo(() => getValidationSchema(intl), [intl]),
  };
};
