import { SectionTitle, StyledIconBlock } from 'pages/details/styles';
import { Building } from 'store/building/types';
import { useIntl } from 'react-intl';
import { getTileTitle } from 'utils/getTileTitle';
import { useSelector } from 'react-redux';
import { selectMetadataFields } from 'store/serviceRequests/selectors';
import { ScheduleSwitch } from 'pages/details/schedule-request-form/schedule-switch';
import { useFieldScheduleServiceRequest } from 'hooks/use-ui-metadata.hook';
import { useScheduleData } from 'hooks/use-schedule-data';
import { getMappedStatus } from 'utils/getMappedStatus';
import { PROVIDERS_STATUSES } from '@hqo/react-components-library/dist/utils';
import { LocationSubtitle } from 'pages/details/location-subtitle';
import { CategoryDetailsBlock } from 'pages/details/category-details-block';
import { AccessBlock } from 'pages/details/access-block';

interface requestDetailsProps {
  currentServiceRequest: ServiceRequest;
  building: Building;
  openScheduleStep?: () => void;
  openFloorPlanStep?: () => void;
}

// eslint-disable-next-line max-lines-per-function
export const RequestDetails = ({
  currentServiceRequest,
  building,
  openScheduleStep,
  openFloorPlanStep,
}: requestDetailsProps): JSX.Element => {
  const intl = useIntl();
  const metadataFields = useSelector(selectMetadataFields);
  const requestType = getTileTitle(currentServiceRequest, metadataFields, intl);
  const status = getMappedStatus(currentServiceRequest.status);
  const isScheduleEnabled =
    useFieldScheduleServiceRequest() && !currentServiceRequest.schedule && status !== PROVIDERS_STATUSES.CLOSED;
  const { text, icon, title } = useScheduleData(currentServiceRequest.schedule);
  const building_name = building?.display_name ?? building?.name;
  return (
    <>
      <SectionTitle>{intl.formatMessage({ id: 'details' })}</SectionTitle>
      <StyledIconBlock
        title={requestType}
        titleType="bold"
        icon="wrench"
        subtitle={<CategoryDetailsBlock currentServiceRequest={currentServiceRequest} />}
        iconType="fal"
      />
      <StyledIconBlock
        title={building_name}
        titleType="bold"
        icon="map-marker-alt"
        subtitle={
          <LocationSubtitle currentServiceRequest={currentServiceRequest} openFloorPlanStep={openFloorPlanStep} />
        }
        iconType="far"
      />
      {currentServiceRequest.entry_access_granted !== undefined && (
        <AccessBlock currentServiceRequest={currentServiceRequest} />
      )}
      {isScheduleEnabled && <ScheduleSwitch openScheduleStep={openScheduleStep} />}
      {currentServiceRequest.schedule && (
        <StyledIconBlock
          dataTestId="schedule-data"
          title={title}
          titleType="bold"
          icon={icon}
          iconType="far"
          subtitle={text}
        />
      )}
    </>
  );
};
