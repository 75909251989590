import * as userActions from '../user/actions';
import {
  API_URL_LS_KEY,
  APP_BRAND_LS_KEY,
  AUTH_TOKEN_LS_KEY,
  BUILDING_UUID_LS_KEY,
  LOCALE_LS_KEY,
} from 'utils/constants';

import { ConfigState } from './types';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { getDefaultApiUrl } from 'store/config/get-default-url.util';
import qs from 'qs';
import { saveExternalConfig } from './actions';

export function getInitialState(): ConfigState {
  const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) as unknown as ConfigState;

  return {
    apiUrl: queryParams.apiUrl ?? localStorage.getItem(API_URL_LS_KEY) ?? getDefaultApiUrl(),
    appBrand: queryParams.appBrand ?? localStorage.getItem(APP_BRAND_LS_KEY) ?? null,
    buildingUuid: queryParams.buildingUuid ?? localStorage.getItem(BUILDING_UUID_LS_KEY) ?? null,
    locale: queryParams.locale?.replace('_', '-') ?? localStorage.getItem(LOCALE_LS_KEY) ?? null,
    authToken: queryParams.authToken ?? localStorage.getItem(AUTH_TOKEN_LS_KEY) ?? null,
  };
}

export const initialState: ConfigState = getInitialState();

const getExternalConfigSaveReducer = <K extends keyof ConfigState>(key: K, lsKey: string) => {
  return (state: ConfigState[K], action: ReturnType<typeof saveExternalConfig>): ConfigState[K] => {
    let value: string | undefined | null = action.payload[key];

    if (value) {
      if (key === 'locale') {
        value = value.toLowerCase().replace('_', '-');
      }
      localStorage.setItem(lsKey, value);
    }

    return value ?? state;
  };
};

const apiUrlReducer = createReducer(initialState.apiUrl)
  .handleAction(saveExternalConfig, getExternalConfigSaveReducer('apiUrl', API_URL_LS_KEY))
  .handleAction(userActions.getCurrentUser.failure, () => {
    localStorage.removeItem(API_URL_LS_KEY);

    return getDefaultApiUrl();
  });

const appBrandReducer = createReducer(initialState.appBrand).handleAction(
  saveExternalConfig,
  getExternalConfigSaveReducer('appBrand', APP_BRAND_LS_KEY),
);

const buildingUuidReducer = createReducer(initialState.buildingUuid).handleAction(
  saveExternalConfig,
  getExternalConfigSaveReducer('buildingUuid', BUILDING_UUID_LS_KEY),
);

const localeReducer = createReducer(initialState.locale).handleAction(
  saveExternalConfig,
  getExternalConfigSaveReducer('locale', LOCALE_LS_KEY),
);

const authTokenReducer = createReducer(initialState.authToken)
  .handleAction(saveExternalConfig, getExternalConfigSaveReducer('authToken', AUTH_TOKEN_LS_KEY))
  .handleAction(userActions.getCurrentUser.failure, () => {
    localStorage.removeItem(AUTH_TOKEN_LS_KEY);

    return null;
  });

export default combineReducers({
  apiUrl: apiUrlReducer,
  appBrand: appBrandReducer,
  buildingUuid: buildingUuidReducer,
  locale: localeReducer,
  authToken: authTokenReducer,
});
