import styled from 'styled-components';
import { WheelPicker } from '@hqo/react-components-library/dist/molecules/wheel-picker-view';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  .picker-field {
    box-sizing: border-box;
  }
`;

export const WheelPickerStyled = styled(WheelPicker)`
  position: fixed;
  bottom: 0;
  width: calc(100% - 32px);
  margin: 0;
  background-color: ${({ theme }) => theme.colors.universal.white};
  z-index: 99;

  .wheel-picker-item {
    font-size: 23px;
  }
` as typeof WheelPicker;
