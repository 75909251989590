import { ButtonsContainer, StyledButton } from 'components/estimates-box/styles';

import { useIntl } from 'react-intl';

interface Props {
  onApprove: VoidFunction;
  onDecline: VoidFunction;
}

export const Buttons = ({ onApprove, onDecline }: Props): JSX.Element => {
  const intl = useIntl();
  return (
    <ButtonsContainer>
      <StyledButton
        variant="secondary"
        text={intl.formatMessage({ id: 'approve' })}
        data-testid="estimate-approve-button"
        className="estimate-approve-button"
        onClick={onApprove}
      />
      <StyledButton
        variant="secondary"
        text={intl.formatMessage({ id: 'decline' })}
        data-testid="estimate-decline-button"
        onClick={onDecline}
      />
    </ButtonsContainer>
  );
};
