import * as actions from './actions';

import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { ERR_DEFAULT } from '../../utils/constants';
import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { configSelector } from '../config/selectors';
import { isActionOf } from 'typesafe-actions';

export const getLookupDataEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getLookupData.request)),
    withLatestFrom(state$),
    switchMap(([action, state]): Observable<RootAction> => {
      const { buildingUuid } = configSelector(state);
      const { fieldName, primaryUuid } = action.payload;
      return apiClient(state)
        .getLookupData(buildingUuid, fieldName, primaryUuid)
        .pipe(
          map(xhrPayload => actions.getLookupData.success({ ...xhrPayload.response, fieldName, primaryUuid })),
          catchError(error => of(actions.getLookupData.failure({ error, errorCode: ERR_DEFAULT }))),
        );
    }),
  );
