export enum MetadataFlags {
  CUSTOM_REQUEST_TYPES = 'custom_request_types',
  RECURRING_REQUEST_ENABLED = 'recurring_requests_enabled',
  SCHEDULE_REQUEST_ENABLED = 'scheduled_requests_enabled',
  FEEDBACK_ENABLED = 'feedback_enabled',
  MAX_RATING = 'max_rating',
  MAX_COMMENT_LENGTH = 'max_comment_length',
  FLOOR_PLAN_PINNING_ENABLED = 'floor_plan_pinning_enabled',
  CANCELING_REQUESTS_ENABLED = 'canceling_requests_enabled',
  MESSAGE_FEED_ENABLED = 'message_feed_enabled',
  MESSAGE_SENDING_ENABLED = 'message_sending_enabled',
}
