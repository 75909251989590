import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getErrorNotifications } from 'store/errors/selectors';
import { hideErrorNotifications } from 'store/errors/actions';
import { MessageFormatElement, useIntl } from 'react-intl';
import { ErrorNotification as ErrorNotificationData } from 'store/errors/types';
import { ErrorNotification } from '@hqo/react-components-library/dist/molecules/errorNotification';
import { Modal } from 'components/shared/modal';
import { ERR_DEFAULT } from 'utils/constants';

export function getErrorCode(
  errors: ErrorNotificationData[],
  messages: Record<string, string> | Record<string, MessageFormatElement[]>,
  errorType: 'errors' | 'criticalErrors',
): string {
  const errorCode = errors[0]?.errorCode?.toLowerCase();
  if (messages[`notifications.${errorType}.${errorCode}.title`] !== undefined) {
    return errorCode;
  }
  return ERR_DEFAULT;
}

export const ErrorModal = (): JSX.Element | null => {
  const { formatMessage, messages } = useIntl();
  const { hasErrorNotifications, errors } = useSelector(getErrorNotifications);
  const dispatch = useDispatch();
  const handleClose = useCallback(() => {
    dispatch(hideErrorNotifications());
  }, [dispatch]);

  if (!hasErrorNotifications) {
    return null;
  }

  const errorCode = hasErrorNotifications ? getErrorCode(errors, messages, 'errors') : '';

  return (
    <Modal onClose={handleClose} variant="small">
      <ErrorNotification
        title={formatMessage({ id: `notifications.errors.${errorCode}.title` })}
        description={formatMessage({ id: `notifications.errors.${errorCode}.description` })}
        buttonText={formatMessage({ id: `notifications.errors.${errorCode}.buttonText` })}
        onClick={handleClose}
      />
    </Modal>
  );
};
