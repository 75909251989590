import { useIntl } from 'react-intl';
import { FileUpload } from '@hqo/react-components-library/dist/molecules/file-upload';
import { AdditionalFieldsProps, FileField as FileFieldProps } from '../../interfaces';
import { ReadonlyFileField } from './readonly-file';
import { DEFAULT_ACCEPTED_FILE_TYPES } from '@hqo/react-components-library/dist/utils';
import { FileUploadContainer } from './styles';

interface Props {
  field: FileFieldProps;
  additionalProps: AdditionalFieldsProps;
}

export const FileField = ({ field, additionalProps: { onFileDelete, onFileUpload, readOnly } }: Props) => {
  const intl = useIntl();
  const { value, label, name, accept, fileLimit, maxSizeTotal, maxSizePerFile, required } = field;
  const fileUploadSubtitle = intl.formatMessage({ id: 'accepted_file_types' }, { numFiles: fileLimit });

  if (readOnly) {
    return <ReadonlyFileField value={value} label={label} />;
  }

  return (
    <FileUploadContainer key={name}>
      <FileUpload
        title={label}
        buttonLabel={intl.formatMessage({ id: 'upload_file' })}
        subtitle={`${fileUploadSubtitle} ${accept}`}
        fileLimit={fileLimit}
        maxSizeTotal={maxSizeTotal}
        maxSizePerFile={maxSizePerFile}
        onDelete={onFileDelete}
        required={required}
        acceptedFileTypes={accept || DEFAULT_ACCEPTED_FILE_TYPES}
        onUploadComplete={onFileUpload}
        data-testid="form-fileupload"
      />
    </FileUploadContainer>
  );
};
