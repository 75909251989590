import styled from 'styled-components';
import { HqoDatePicker } from '@hqo/react-components-library/dist/molecules/hqo-date-picker';

export const HqoDatePickerStyled = styled(HqoDatePicker)`
  .day-cell,
  .days-view {
    border: 0 none;
  }

  .date-picker-paper {
    padding: 16px;
  }

  .sc-iqcoie {
    margin-left: 0;
  }

  .day-cell {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin: 8px;
  }

  .calendar-header {
    padding: 0 12px;
  }

  .button-view {
    margin-top: 0;
    padding-top: 24px;
    margin-left: auto;
  }

  .date-picker-select {
    padding-left: 0;
  }

  .button-view {
    flex-direction: row-reverse;
  }

  .popover-body {
    z-index: 99;
  }
`;
