import styled from 'styled-components';

import { IconBlock } from '@hqo/react-components-library/dist/molecules/iconBlock';

export const Container = styled.div<{ disabled?: boolean }>`
  color: ${({ theme }) => theme.colors.universal.white};
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  padding: 16px;

  ${({ theme, disabled }) =>
    disabled
      ? 'border: solid 1px rgba(216, 216, 216, 0.3)'
      : `&:hover {
      box-shadow: 2px 0px 10px ${theme.colors.greys.adminBlack}1A;
      cursor: pointer;
    }
    box-shadow: 0px 2px 10px ${theme.colors.greys.adminBlack}1A`};
`;

export const TitleText = styled.div<{ disabled?: boolean }>`
  font: 500 16px ${({ theme }) => theme.fonts.join()};
  line-height: 22px;
  ${({ theme, disabled }) =>
    disabled ? `color: ${theme.colors.greys.sharedMediumGrey}` : `color: ${theme.colors.fontColor}}`};
  overflow-wrap: break-word;
  padding-bottom: 9px;
`;

export const StatusCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
`;

export const StatusIndicatorWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const StatusIndicator = styled.span`
  width: 12px;
  height: 12px;
  border-radius: 100px;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const StyledIconBlock = styled(IconBlock)`
  // TODO: create wrapper for svg in component with fixed width and add auto right marging
  .fa-map-marker-alt {
    margin-right: 12px;
  }

  .fa-repeat {
    margin-top: 2px;
    margin-right: 8px;
  }

  .fa-calendar {
    margin-right: 10px;
  }

  .icon-block-title {
    line-height: 22px;
  }
`;
