import { selectBuildingLocale, selectBuildingTimeZone } from 'store/building/selectors';

import { Avatar } from 'pages/details/feed-content/avatar';
import { BubbleWrapper } from 'pages/details/styles';
import { ChatBubbleStyled } from 'pages/details/feed-content/message-bubble.styles';
import { ChatBubbleVariants } from '../utils';
import { assignMessageVariant } from 'pages/details/utils';
import { currentUser } from 'store/user/selectors';
import { formatTime } from 'utils';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export type Message = Pick<ServiceRequestMessage, 'id' | 'read' | 'created_at' | 'text' | 'author' | 'type'>;

interface Props {
  message: Message;
  errorText?: string;
  onMessageClick?: VoidFunction;
}
export const MessagesBubble = ({ message, errorText, onMessageClick: handleMessageClick }: Props) => {
  const { id, text, created_at, author } = message;
  const { formatMessage } = useIntl();
  const automaticResponseTitle = formatMessage({ id: 'automatic_response' });
  const user = useSelector(currentUser);
  const locale = useSelector(selectBuildingLocale);
  const timezone = useSelector(selectBuildingTimeZone);
  const variant = assignMessageVariant(message, user.email);
  const messageUserName = variant === ChatBubbleVariants.SYSTEM ? automaticResponseTitle : author.full_name;
  const { avatar } = message.author;

  return (
    <BubbleWrapper variant={variant} data-messagereadstatus={JSON.stringify(message.read)}>
      <ChatBubbleStyled
        key={id}
        messageBody={text}
        timeStamp={formatTime(created_at, timezone, locale)}
        userName={messageUserName}
        variant={variant}
        avatar={<Avatar first_name={author.first_name} last_name={author.last_name} avatar_src={avatar} />}
        errorText={errorText}
        onMessageClick={handleMessageClick}
      />
    </BubbleWrapper>
  );
};
