import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentServiceRequest, selectUpdateLastReadMessageStatus } from 'store/serviceRequests/selectors';
import { useEffect } from 'react';
import { ACTION_STATUSES } from '../../../utils/constants';
import { updateLastReadTimestampRequest } from 'store/serviceRequests/actions';

export const useReadMessages = (isFeedOpened: boolean) => {
  const dispatch = useDispatch();
  const request = useSelector(selectCurrentServiceRequest);
  const requestUuid = request?.id;

  const messages = request?.messages ?? [];
  const updateLastReadMessageStatus = useSelector(selectUpdateLastReadMessageStatus);
  const lastMessage = messages[0];
  const hasUnreadMessages = messages.some(message => !message.read) && !!lastMessage;

  useEffect(() => {
    if (isFeedOpened && hasUnreadMessages && requestUuid && updateLastReadMessageStatus !== ACTION_STATUSES.PENDING) {
      dispatch(
        updateLastReadTimestampRequest.request({
          request_uuid: requestUuid,
          payload: {
            message_created_at: lastMessage.created_at,
            message_uuid: lastMessage.id,
          },
        }),
      );
    }
  }, [isFeedOpened, lastMessage, hasUnreadMessages, dispatch, requestUuid, updateLastReadMessageStatus]);
};
