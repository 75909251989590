import { DetailsPageBody } from 'pages/details/details-body';
import { DetailsPageCTA } from 'pages/details/details-page-cta';
import { DetailsPageHeader } from 'pages/details/details-header';
import { DetailsPageLoader } from 'pages/details/details-loader';
import { DetailsPageWrapper } from 'pages/details/styles';
import { PROVIDERS_STATUSES } from '@hqo/react-components-library/dist/utils';
import { getMappedStatus } from 'utils/getMappedStatus';
import { selectCurrentServiceRequest } from '../../store/serviceRequests/selectors';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIsOnBehalfOf, useServiceRequestLoading } from 'pages/details/details-page.hooks';
import { EstimateBox } from 'components/estimates-box/estimates-box';
import { REQUEST_STATUS } from '../../utils/constants';
import { useFlag } from 'hooks/use-flag.hook';
import { LDFlags } from 'shared/consts';
import { MetadataFlags } from 'shared/consts/metadata-flags-enum';
import { useFieldMetadata } from 'hooks/use-ui-metadata.hook';
import { DetailsFeedBackBox } from 'pages/details/details-feedback-box';

interface DetailsPageContentsProps {
  openScheduleStep?: () => void;
  openFloorPlanStep?: () => void;
}

const showEstimateBox = (LDFlag: boolean, currentServiceRequest: CurrentServiceRequest) => {
  return (
    LDFlag &&
    currentServiceRequest?.status === REQUEST_STATUS.NEW &&
    currentServiceRequest?.estimate?.id &&
    currentServiceRequest?.estimate?.min_estimate !== null &&
    currentServiceRequest?.estimate?.max_estimate !== null
  );
};

export const DetailsPageContents = ({ openScheduleStep, openFloorPlanStep }: DetailsPageContentsProps): JSX.Element => {
  const intl = useIntl();
  const cancelCTA = intl.formatMessage({ id: 'cancel_request' });
  const { requestId } = useParams<{ requestId: string }>();
  const currentServiceRequest = useSelector(selectCurrentServiceRequest);
  const isOnBehalfOf = useIsOnBehalfOf();
  const isCancelingRequestsEnabled = useFieldMetadata(MetadataFlags.CANCELING_REQUESTS_ENABLED);
  const serviceRequestStatus = getMappedStatus(currentServiceRequest?.status).toLowerCase();
  const showCancelButton = serviceRequestStatus !== PROVIDERS_STATUSES.CLOSED.toLowerCase();
  const isLoading = useServiceRequestLoading();
  const isEstimatesBoxVisible = showEstimateBox(useFlag(LDFlags.SHOW_SERVICE_REQUEST_ESTIMATE), currentServiceRequest);

  if (isLoading) {
    return <DetailsPageLoader />;
  }
  return (
    <DetailsPageWrapper className="details-page-wrapper" data-testid="details-modal" data-cy="details-modal">
      <DetailsPageHeader />
      <DetailsFeedBackBox serviceRequest={currentServiceRequest} />
      {isEstimatesBoxVisible && <EstimateBox />}
      <DetailsPageBody openScheduleStep={openScheduleStep} openFloorPlanStep={openFloorPlanStep} />
      {!isOnBehalfOf && isCancelingRequestsEnabled && (
        <DetailsPageCTA requestId={requestId} buttonText={cancelCTA} showCancelButton={showCancelButton} />
      )}
    </DetailsPageWrapper>
  );
};
