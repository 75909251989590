import { StyledModalWrapper, Wrapper } from './styles';

import { ErrorNotification } from '@hqo/react-components-library/dist/molecules/errorNotification';
import { useIntl } from 'react-intl';
import { useGoBack } from 'hooks/use-go-back-handler.hook';

export const ErrorRequestModal = (): JSX.Element => {
  const intl = useIntl();
  const handleClose = useGoBack();

  return (
    <StyledModalWrapper
      visible
      onClose={handleClose}
      content={
        <Wrapper>
          <ErrorNotification
            title={intl.formatMessage({ id: 'unable_to_complete' })}
            description={intl.formatMessage({ id: 'please_try_again_later' })}
            buttonText={intl.formatMessage({ id: 'okay' })}
            onClick={handleClose}
            iconName="exclamation-circle"
          />
        </Wrapper>
      }
    />
  );
};
