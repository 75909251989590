import { useCallback } from 'react';
import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { AdditionalFieldsProps, SwitchFieldProperties } from '../../interfaces';
import { useSwitchProps } from './hooks';
import { SwitchFieldWrapper, SwitchField } from '@hqo/react-components-library/dist/checkbox';
import { SwitchContainer, SwitchDetailsContainer } from './styles';

interface Props {
  field: SwitchFieldProperties;
  additionalProps: AdditionalFieldsProps;
}

export const Switch = ({
  field,
  field: { name, label, switchDetailsLabel, switchDetailsEditButtonLabel },
  additionalProps: { onRecurrenceSwitchChange, onSwitchDetailsEdit, onStatefulSwitchChange },
}: Props) => {
  const onDetailsEdit = useCallback(() => onSwitchDetailsEdit(name), [name, onSwitchDetailsEdit]);
  const { handleChange, checked, showSwitchDetails } = useSwitchProps(
    field,
    onStatefulSwitchChange,
    onRecurrenceSwitchChange,
  );

  return (
    <SwitchContainer key={name}>
      <SwitchFieldWrapper title={label}>
        <div data-testid={`${name}-switch-field`}>
          <SwitchField checked={checked} onChange={handleChange} />
        </div>
      </SwitchFieldWrapper>

      {showSwitchDetails && (
        <SwitchDetailsContainer>
          {switchDetailsLabel}

          <Button onClick={onDetailsEdit} text={switchDetailsEditButtonLabel} size="xs" variant="link" />
        </SwitchDetailsContainer>
      )}
    </SwitchContainer>
  );
};
