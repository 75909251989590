import { useCallback, useMemo, useState } from 'react';

export const useSteps = <T extends string>(steps: T[], defaultStep?: T) => {
  const [step, setStep] = useState<T>(defaultStep ?? steps[0]);
  const index = useMemo(() => steps.indexOf(step), [steps, step]);
  const openNextStep = useCallback(() => {
    setStep(currentStep => {
      const nextIndex = steps.indexOf(currentStep) + 1;

      return steps[nextIndex] ?? currentStep;
    });
  }, [setStep, steps]);
  const openPreviousStep = useCallback(() => {
    setStep(currentStep => {
      const nextIndex = steps.indexOf(currentStep) - 1;

      return steps[nextIndex] ?? currentStep;
    });
  }, [setStep, steps]);
  const openStepByIndex = useCallback(
    (stepIndex: number) => {
      if (steps[stepIndex]) {
        setStep(steps[stepIndex]);
      }
    },
    [steps, setStep],
  );

  return { step, index, openNextStep, openPreviousStep, openStepByIndex };
};
