import styled from 'styled-components';
import { skeletonAnimation } from 'utils/skeleton-animation';

export const SkeletonTile = styled.div`
  height: 116px;
  width: 100%;
  border-radius: 12px;
  margin-bottom: 16px;
  ${skeletonAnimation}
`;
