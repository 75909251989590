import { AppThemeProvider } from '@hqo/react-components-library/dist/tenant-web/elements/theme';
import { HqoTheme } from '@hqo/react-components-library/dist/hqo-theme-provider';

import React from 'react';
import { useBuilding } from 'hooks/use-building.hook';
import { useBuildingTheme } from 'hooks/use-building-theme.hook';
import { CustomFonts } from 'components/custom-fonts';
import { AppContentWrapperStyles } from 'components/app-content-wrapper/app-content-wrapper.styles';
import { ErrorModal } from 'components/error-modal';
import { useFetchUiMetadata } from 'hooks/use-ui-metadata.hook';

interface AppContentWrapperProps {
  children: React.ReactNode;
}

export const AppContentWrapper = ({ children }: AppContentWrapperProps): JSX.Element => {
  const theme = useBuildingTheme();
  useBuilding();
  useFetchUiMetadata();

  return (
    <>
      <AppContentWrapperStyles customThemeFonts={theme.fonts} />
      <CustomFonts />
      <AppThemeProvider theme={theme as HqoTheme}>
        {children}
        <ErrorModal />
      </AppThemeProvider>
    </>
  );
};
