import * as actions from './actions';

import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { getErrorCode } from 'store/utils';
import { selectBuilding } from 'store/building/selectors';

export const getDynamicFormDefinitionEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getDynamicFormDefinition.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]): Observable<RootAction> => {
      const building = selectBuilding(state);

      return apiClient(state)
        .getDynamicFormDefinition(building.uuid, payload.formId)
        .pipe(
          map(xhrPayload =>
            actions.getDynamicFormDefinition.success({
              params: payload,
              response: xhrPayload.response.data.form_definition,
            }),
          ),
          catchError(error =>
            of(actions.getDynamicFormDefinition.failure({ params: payload, error, errorCode: getErrorCode(error) })),
          ),
        );
    }),
  );
