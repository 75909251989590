import { WarningWrapper, WarningTitle, WarningIcon, Warning } from 'pages/details/styles';
import { FormattedMessage } from 'react-intl';

interface DetailsSubtitleWarningProps {
  warning: string;
}

export const DetailsSubtitleWarning = ({ warning }: DetailsSubtitleWarningProps): JSX.Element => (
  <WarningWrapper data-testid="details-subtitle-warning">
    <WarningTitle>
      <WarningIcon icon={['fas', 'exclamation-triangle']} />
      <Warning>
        <FormattedMessage id="warning" />
      </Warning>
    </WarningTitle>
    {warning}
  </WarningWrapper>
);
