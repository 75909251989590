import { DeeplinkActions, DeeplinkParams } from 'components/shared/types';
import { useDispatch } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { ROOT_PATH } from 'utils/constants';
import { updateShowFeed } from '../../store/serviceRequests/actions';

export const Deeplink = (): JSX.Element | null => {
  const { params, requestId } = useParams<DeeplinkParams>();
  const dispatch = useDispatch();

  if (!(params && requestId)) {
    return <Redirect to={`${ROOT_PATH}`} />;
  }

  const base64Params = atob(params);
  const parsedParams = JSON.parse(base64Params);
  const { action } = parsedParams;

  if ((action as DeeplinkActions) === DeeplinkActions.VIEW_FEED) dispatch(updateShowFeed(true));

  return <Redirect to={`${ROOT_PATH}/${requestId}/view`} />;
};
