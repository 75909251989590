import { useAvatarLoading } from 'hooks/use-avatar-loading.hook';
import { StyledAvatar, Image, AvatarImageWrapper } from 'pages/details/styles';

interface Props {
  first_name: string;
  last_name: string;
  avatar_src?: string;
  assignee?: boolean;
}

export const Avatar = ({ first_name, last_name, avatar_src, assignee }: Props): JSX.Element => {
  const { imageLoaded, imageErrored, handleImageLoaded, handleImageError } = useAvatarLoading();
  return (
    <>
      {(!imageLoaded || !avatar_src) && (
        <StyledAvatar
          data-testId="default-avatar"
          assignee={assignee}
        >{`${first_name[0]}${last_name[0]}`}</StyledAvatar>
      )}
      <AvatarImageWrapper data-testId="image-avatar" display={imageLoaded && !imageErrored}>
        <Image src={avatar_src} onLoad={handleImageLoaded} onError={handleImageError} alt="avatar" />
      </AvatarImageWrapper>
    </>
  );
};
