import { FORM_FIELDS } from './interfaces';
import { MultilevelSelect } from './components/multilevel-select';
import { FileField as FileFieldComponent } from './components/file-field';
import { Switch } from './components/switch-field';
import { Select } from './components/select-field';
import { TextField as TextFieldComponent } from './components/text-field';
import { TextareaField as TextareaFieldComponent } from './components/textarea-field';
import { ComponentsMap } from './components-map.interfaces';

export const componentsMap: ComponentsMap = {
  [FORM_FIELDS.TEXT]: TextFieldComponent,
  [FORM_FIELDS.TEXTAREA]: TextareaFieldComponent,
  [FORM_FIELDS.SELECT]: Select,
  [FORM_FIELDS.FILE]: FileFieldComponent,
  [FORM_FIELDS.SWITCH]: Switch,
  [FORM_FIELDS.MULTILEVEL_SELECT]: MultilevelSelect,
};
