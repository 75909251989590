import { ACTION_STATUSES } from 'utils/constants';
import { useMemo } from 'react';
import { selectCurrentServiceRequestStatus } from '../../../store/serviceRequests/selectors';
import { useSelector } from 'react-redux';
import { DetailsPageLoader } from 'pages/details/details-loader';
import { useMetadataFields } from './use-metadata-fields.hook';
import { CreateServiceRequestStepContent, CreateServiceRequestStepProps } from './create-service-request-step-content';

export const CreateServiceRequestStep = (props: CreateServiceRequestStepProps): JSX.Element => {
  const metadataFields = useMetadataFields();
  const currentRequestStatus = useSelector(selectCurrentServiceRequestStatus);

  const isInitialDataLoading = useMemo(
    () => currentRequestStatus === ACTION_STATUSES.PENDING || metadataFields === undefined,
    [currentRequestStatus, metadataFields],
  );

  return isInitialDataLoading ? (
    <DetailsPageLoader />
  ) : (
    <CreateServiceRequestStepContent {...props} metadataFields={metadataFields} />
  );
};
