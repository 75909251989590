import { getAltBuildings, setCurrentAltBuilding } from '../store/alt-buildings/actions';
import { selectAltBuildings, selectAltBuildingsByUuid } from '../store/alt-buildings/selectors';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AltBuilding } from '../store/alt-buildings/types';
import { SelectBuildingEventType } from '../types/alt-building';
import { configSelector } from '../store/config/selectors';

export const useAltBuildings = (): AltBuilding[] => {
  const dispatch = useDispatch();
  const altBuildings = useSelector(selectAltBuildings);
  const { buildingUuid } = useSelector(configSelector);

  useEffect(() => {
    if (!altBuildings) {
      dispatch(getAltBuildings.request(buildingUuid));
    }
  }, [dispatch, buildingUuid, altBuildings]);

  return altBuildings;
};

export const useSetAltBuildingState = (selectedAltBuilding: AltBuilding, onClose: VoidFunction): (() => void) => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(setCurrentAltBuilding(selectedAltBuilding));
    onClose();
  }, [dispatch, onClose, selectedAltBuilding]);
};

export const useSetSelectedAltBuilding = (
  setSelectedAltBuilding: React.Dispatch<React.SetStateAction<AltBuilding>>,
): ((event: SelectBuildingEventType) => void) => {
  const altBuildingsByUuid = useSelector(selectAltBuildingsByUuid);

  return useCallback(
    (event: SelectBuildingEventType) => {
      setSelectedAltBuilding(altBuildingsByUuid[event.target.value]);
    },
    [setSelectedAltBuilding, altBuildingsByUuid],
  );
};
