import { useCallback } from 'react';
import moment, { Moment } from 'moment';
import { BUTTON_VARIANT } from '@hqo/react-components-library/dist/utils';
import { useIntl } from 'react-intl';

export const useDatePickerButtons = (handleChange: (value: Moment) => void) => {
  const intl = useIntl();
  const onClear = useCallback(() => {
    handleChange(moment());
  }, [handleChange]);

  return [
    { title: intl.formatMessage({ id: 'save' }), size: 'xs' },
    {
      title: intl.formatMessage({ id: 'clear' }),
      variant: BUTTON_VARIANT.PRIMARY_LINK,
      handler: onClear,
      size: 'xxs',
    },
  ];
};
