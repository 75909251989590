import { MobileModal } from './mobile-modal';
import { ModalPortal } from './modal-portal';
import { StyledModal } from './modal.styles';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { ModalProps } from 'components/shared/modal/modal.interface';

const maxWidthVariants: Record<NonNullable<ModalProps['variant']>, number> = {
  small: 424,
  normal: 612,
  wide: 984,
};

export const Modal = ({
  children,
  onClose: handleClose,
  hideMobileCloseButton,
  variant = 'normal',
}: ModalProps): JSX.Element => {
  const isSmallViewportWidth = useIsSmallViewportWidth();

  return (
    <ModalPortal>
      {isSmallViewportWidth ? (
        <MobileModal onClose={handleClose} hideMobileCloseButton={hideMobileCloseButton}>
          {children}
        </MobileModal>
      ) : (
        <StyledModal
          opened
          maxWidth={maxWidthVariants[variant]}
          onClose={handleClose}
          dataTestId="modal-window"
          data-cy="modal-window"
          variant={variant}
        >
          {children}
        </StyledModal>
      )}
    </ModalPortal>
  );
};
