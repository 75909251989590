import { EstimateContainer, EstimateWrapper } from './styles';

import { Buttons } from 'components/estimates-box/buttons';
import { EstimatesBoxText } from 'components/estimates-box/estimates-box-text';
import { useEstimate } from 'hooks/use-estimate.hook';

export const EstimateBox = () => {
  const { handleApprove, handleDecline, estimate } = useEstimate();

  return (
    <EstimateContainer>
      <EstimateWrapper data-testid="estimate-box-wrapper">
        <EstimatesBoxText estimate={estimate} />
        <Buttons onApprove={handleApprove} onDecline={handleDecline} />
      </EstimateWrapper>
    </EstimateContainer>
  );
};
