import { useFlag } from 'hooks/use-flag.hook';
import { useCallback, useMemo, useState } from 'react';
import { LDFlags } from 'shared/consts';

export const useDynamicFormIds = () => {
  const [dynamicForms, setDynamicForms] = useState<Record<string, string | null>>({});
  const isDynamicFormsEnabled = useFlag(LDFlags.SHOW_SERVICE_REQUEST_DYNAMIC_FORMS);
  const handleFormIdSelect = useCallback(
    (fieldName: string, formId: string | null) => {
      if (isDynamicFormsEnabled) {
        setDynamicForms(formIds => ({
          ...formIds,
          [fieldName]: formId,
        }));
      }
    },
    [isDynamicFormsEnabled, setDynamicForms],
  );
  const firstDynamicFormId = useMemo(() => {
    const dynamicFormIds = Object.values(dynamicForms).filter(formId => formId != null);

    return dynamicFormIds[0] ?? null;
  }, [dynamicForms]);

  return {
    handleFormIdSelect,
    dynamicForms,
    firstDynamicFormId,
  };
};
