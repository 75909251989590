import { useIntl } from 'react-intl';
import { NumberField } from 'components/shared/fields/number-field';
import { FieldWrapper } from './styles';
import { useField } from 'formik';

interface OccurrencesCountFieldProps {
  fieldName: string;
}

export const OccurrencesCountField = ({ fieldName }: OccurrencesCountFieldProps) => {
  const intl = useIntl();
  const [{ value }, { error, touched }, { setValue }] = useField<number>(fieldName);

  return (
    <FieldWrapper>
      <NumberField
        label={intl.formatMessage({ id: 'recurrence_ends_after' })}
        placeholder={intl.formatMessage({ id: 'enter_number' })}
        required
        value={value}
        onChange={setValue}
        errorText={touched && error}
      />
    </FieldWrapper>
  );
};
