import { EditFloorPlanButton, OpenFloorPlanButton, PinAddedWrapper } from 'pages/details/styles';
import { useIntl } from 'react-intl';
import { FloorPlanButtonWrapper } from './styles';

interface Props {
  openFloorPlanStep: () => void;
  pin?: FloorPlanPin;
}

export const ExactLocationPin = ({ openFloorPlanStep, pin }: Props): JSX.Element => {
  const intl = useIntl();
  return (
    <FloorPlanButtonWrapper>
      {pin ? (
        <PinAddedWrapper>
          <div data-testId="pin-added-copy">{intl.formatMessage({ id: 'pin_added' })}</div>
          <EditFloorPlanButton onClick={openFloorPlanStep} data-testId="pin-edit-button">
            {intl.formatMessage({ id: 'edit' })}
          </EditFloorPlanButton>
        </PinAddedWrapper>
      ) : (
        <OpenFloorPlanButton onClick={openFloorPlanStep}>{intl.formatMessage({ id: 'add_a_pin' })}</OpenFloorPlanButton>
      )}
    </FloorPlanButtonWrapper>
  );
};
