import * as actions from './actions';

import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { ERR_DEFAULT } from '../../utils/constants';
import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { configSelector } from '../config/selectors';
import { isActionOf } from 'typesafe-actions';
import { track } from '@hqo/web-tracking';
import { UserEvents } from 'shared/consts';
import { serviceRequestStateSelector } from './selectors';
import { messageFailedTrack, messageSubmittedTrack } from 'utils/trackingUtils';
import { AjaxResponse } from 'rxjs/ajax';
import { mapSRLocationField } from 'utils/mapSRLocationField';
import { mapSRArrayLocationFields } from 'utils/mapSRArrayLocationFields';

export const getServiceRequestsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getServiceRequests.request)),
    withLatestFrom(state$),
    switchMap(([, state]): Observable<RootAction> => {
      const { buildingUuid } = configSelector(state);

      return apiClient(state)
        .getServiceRequests(buildingUuid)
        .pipe(
          map(payload =>
            actions.getServiceRequests.success(mapSRArrayLocationFields(payload.response.data.service_requests)),
          ),
          catchError(error => of(actions.getServiceRequests.failure({ error, errorCode: ERR_DEFAULT }))),
        );
    }),
  );

export const getCurrentServiceRequestEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getCurrentServiceRequest.request)),
    withLatestFrom(state$),
    switchMap(([action, state]): Observable<RootAction> => {
      const { buildingUuid } = configSelector(state);
      return apiClient(state)
        .getCurrentServiceRequest(buildingUuid, action.payload)
        .pipe(
          map(payload =>
            actions.getCurrentServiceRequest.success(mapSRLocationField(payload.response.data.service_request)),
          ),
          catchError(error => of(actions.getCurrentServiceRequest.failure({ error, errorCode: ERR_DEFAULT }))),
        );
    }),
  );

const getMapSuccessCreateRequestCallback =
  (report_on_behalf_of?: string) => (payload: AjaxResponse<ServiceRequestResponse>) => {
    const isCreatedOnBehalfOf = !!report_on_behalf_of;
    const serviceRequest = payload.response.data.service_request;
    track(UserEvents.REQUEST_SUBMITTED, {
      type: 'action',
      request_status: serviceRequest?.status,
      request_type: serviceRequest?.request_type,
      request_uuid: serviceRequest?.id,
    });
    return actions.createServiceRequest.success({
      ...mapSRLocationField(serviceRequest),
      is_created_on_behalf_of: isCreatedOnBehalfOf,
    });
  };

export const createServiceRequestEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.createServiceRequest.request)),
    withLatestFrom(state$),
    switchMap(([action, state]): Observable<RootAction> => {
      const { buildingUuid } = configSelector(state);
      return apiClient(state)
        .createServiceRequest(buildingUuid, action.payload)
        .pipe(
          map(getMapSuccessCreateRequestCallback(action.payload.report_on_behalf_of)),
          catchError(error => of(actions.createServiceRequest.failure({ error, errorCode: ERR_DEFAULT }))),
        );
    }),
  );

export const cancelServiceRequestEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.cancelServiceRequest.request)),
    withLatestFrom(state$),
    switchMap(([action, state]): Observable<RootAction> => {
      const { buildingUuid } = configSelector(state);
      const { currentServiceRequest } = serviceRequestStateSelector(state);
      return apiClient(state)
        .cancelServiceRequest(buildingUuid, action.payload)
        .pipe(
          map(() => {
            track(UserEvents.REQUEST_CANCELED, {
              type: 'action',
              request_type: currentServiceRequest?.request_type,
              request_uuid: currentServiceRequest?.id,
            });
            return actions.cancelServiceRequest.success();
          }),
          catchError(error => of(actions.cancelServiceRequest.failure({ error, errorCode: ERR_DEFAULT }))),
        );
    }),
  );

export const getServiceRequestMetadataEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getServiceRequestMetadata.request)),
    withLatestFrom(state$),
    switchMap(([, state]): Observable<RootAction> => {
      const { buildingUuid } = configSelector(state);

      return apiClient(state)
        .getServiceRequestMetadata(buildingUuid)
        .pipe(
          map(payload => actions.getServiceRequestMetadata.success(payload.response.data.config)),
          catchError(error => of(actions.getServiceRequestMetadata.failure({ error, errorCode: ERR_DEFAULT }))),
        );
    }),
  );

export const updateLastReadTimestampEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.updateLastReadTimestampRequest.request)),
    withLatestFrom(state$),
    switchMap(([action, state]): Observable<RootAction> => {
      const { buildingUuid } = configSelector(state);
      const { payload } = action;
      return apiClient(state)
        .updateLastReadTimestamp(buildingUuid, payload)
        .pipe(
          map(() => actions.updateLastReadTimestampRequest.success()),
          catchError(error => of(actions.updateLastReadTimestampRequest.failure({ error, errorCode: ERR_DEFAULT }))),
        );
    }),
  );

// eslint-disable-next-line max-lines-per-function
export const createMessageEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf([actions.createMessageRequest.request, actions.resendFailedMessage])),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]): Observable<RootAction> => {
      const { buildingUuid } = configSelector(state);
      const { currentServiceRequest } = serviceRequestStateSelector(state);
      const { requestUuid, text } = payload;
      return apiClient(state)
        .createMessage(buildingUuid, requestUuid, { text })
        .pipe(
          switchMap(() => {
            return apiClient(state)
              .getMessages(buildingUuid, requestUuid)
              .pipe(
                map(xhrPayload => {
                  messageSubmittedTrack(currentServiceRequest);
                  return actions.createMessageRequest.success(xhrPayload?.response?.data?.messages);
                }),
              );
          }),
          catchError(error => {
            messageFailedTrack(currentServiceRequest);
            return of(actions.createMessageRequest.failure({ requestUuid, text, error, errorCode: ERR_DEFAULT }));
          }),
        );
    }),
  );

export const getMessagesRequestEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getMessagesRequest.request)),
    withLatestFrom(state$),
    switchMap(([action, state]): Observable<RootAction> => {
      const { buildingUuid } = configSelector(state);
      const {
        payload: { request_uuid },
      } = action;
      return apiClient(state)
        .getMessages(buildingUuid, request_uuid)
        .pipe(
          map(xhrPayload => actions.getMessagesRequest.success(xhrPayload?.response?.data?.messages)),
          catchError(error => of(actions.getMessagesRequest.failure({ error, errorCode: ERR_DEFAULT }))),
        );
    }),
  );

export const updateEstimateStatusEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.updateEstimateStatus.request)),
    withLatestFrom(state$),
    switchMap(([action, state]): Observable<RootAction> => {
      const { buildingUuid } = configSelector(state);
      const { request_uuid, estimate_uuid, payload } = action.payload;
      return apiClient(state)
        .patchUpdateEstimateStatus(buildingUuid, request_uuid, estimate_uuid, payload)
        .pipe(
          map(responsePayload =>
            actions.updateEstimateStatus.success(mapSRLocationField(responsePayload.response.data.service_request)),
          ),
          catchError(error => of(actions.updateEstimateStatus.failure({ error, errorCode: ERR_DEFAULT }))),
        );
    }),
  );

export const updateServiceRequestEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.updateServiceRequest.request)),
    withLatestFrom(state$),
    switchMap(([action, state]): Observable<RootAction> => {
      const { buildingUuid } = configSelector(state);
      const { currentServiceRequest } = serviceRequestStateSelector(state);
      const { request, closeSwipeCallback } = action.payload;
      const requestPayload = {
        description: currentServiceRequest?.description,
        request_type: currentServiceRequest?.request_type_id,
        ...request,
      };
      return apiClient(state)
        .updateServiceRequest(buildingUuid, currentServiceRequest.id, requestPayload)
        .pipe(
          map(payload => {
            const serviceRequest = payload.response.data.service_request;
            if (closeSwipeCallback) closeSwipeCallback();
            return actions.updateServiceRequest.success(mapSRLocationField(serviceRequest));
          }),
          catchError(error => of(actions.updateServiceRequest.failure({ error, errorCode: ERR_DEFAULT }))),
        );
    }),
  );

export const submitFeedbackEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.submitFeedback.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]): Observable<RootAction> => {
      const { requestId, ...feedback } = payload;
      const { buildingUuid } = configSelector(state);
      return apiClient(state)
        .submitFeedback(buildingUuid, requestId, feedback)
        .pipe(
          map(xhrPayload => actions.submitFeedback.success(xhrPayload.response.data)),
          catchError(error => of(actions.submitFeedback.failure({ error, errorCode: ERR_DEFAULT }))),
        );
    }),
  );

export const addPinToFloorPlanEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.addPinToFloorPlan.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]): Observable<RootAction> => {
      const { buildingUuid } = configSelector(state);
      const { currentServiceRequest } = serviceRequestStateSelector(state);
      return apiClient(state)
        .addPinToFloorPlan(buildingUuid, currentServiceRequest.id, payload)
        .pipe(
          map(xhrPayload => actions.addPinToFloorPlan.success(xhrPayload.response.data.floor_plan)),
          catchError(error => of(actions.addPinToFloorPlan.failure({ error, errorCode: ERR_DEFAULT }))),
        );
    }),
  );

export const updateFloorPlanPinEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.updateFloorPlanPin.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]): Observable<RootAction> => {
      const { buildingUuid } = configSelector(state);
      const { currentServiceRequest } = serviceRequestStateSelector(state);
      return apiClient(state)
        .updateFloorPlanPin(buildingUuid, currentServiceRequest.id, payload)
        .pipe(
          map(xhrPayload => actions.updateFloorPlanPin.success(xhrPayload.response.data.floor_plan)),
          catchError(error => of(actions.updateFloorPlanPin.failure({ error, errorCode: ERR_DEFAULT }))),
        );
    }),
  );

export const removePinFromFloorPlanEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.removePinFromFloorPlan.request)),
    withLatestFrom(state$),
    switchMap(([, state]): Observable<RootAction> => {
      const { buildingUuid } = configSelector(state);
      const { currentServiceRequest } = serviceRequestStateSelector(state);
      return apiClient(state)
        .removePinFromFloorPlan(buildingUuid, currentServiceRequest.id)
        .pipe(
          map(() => actions.removePinFromFloorPlan.success()),
          catchError(error => of(actions.removePinFromFloorPlan.failure({ error, errorCode: ERR_DEFAULT }))),
        );
    }),
  );

export const getSecondaryTypesEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getSecondaryTypes.request)),
    withLatestFrom(state$),
    switchMap(([action, state]): Observable<RootAction> => {
      const { buildingUuid } = configSelector(state);
      const primaryType = action.payload;
      return apiClient(state)
        .getSecondaryTypes(buildingUuid, primaryType)
        .pipe(
          map(xhrPayload => actions.getSecondaryTypes.success(xhrPayload.response.data)),
          catchError(error => of(actions.getSecondaryTypes.failure({ error, errorCode: ERR_DEFAULT }))),
        );
    }),
  );
