import * as actions from './actions';

import { ACTION_STATUSES, REQUEST_STATUS } from 'utils/constants';
import { ActionType, createReducer } from 'typesafe-actions';

import { v4 as uuidV4 } from 'uuid';

export const INITIAL_STATE: ServiceRequestState = {
  serviceRequests: null,
  currentServiceRequest: null,
  serviceRequestsInitialized: false,
  getServiceRequests: {
    status: null,
    error: null,
  },
  getCurrentServiceRequest: {
    status: null,
    error: null,
  },
  createServiceRequest: {
    status: null,
    error: null,
  },
  cancelServiceRequest: {
    status: null,
    error: null,
  },
  getServiceRequestMetadata: {
    status: null,
    error: null,
  },
  updateLastReadTimestampRequest: {
    status: null,
    error: null,
  },
  createMessageRequest: {
    status: null,
    error: null,
    sendingMessageText: null,
  },
  getMessagesRequest: {
    status: null,
    error: null,
  },
  updateEstimateStatusRequest: {
    status: null,
    error: null,
  },
  updateServiceRequest: {
    status: null,
    error: null,
  },
  submitFeedbackRequest: {
    status: null,
    error: null,
  },
  floorPlanPinAction: {
    status: null,
    error: null,
  },
  serviceRequestMetadata: null,
  failedMessages: {},
  showSetRecurringServiceRequest: false,
  showSetScheduleServiceRequest: false,
  showAttachment: {
    attachmentOpened: false,
    attachmentRef: null,
    attachmentName: null,
  },
  showFloorPlanPinner: false,
  showFeed: false,
  getSecondaryTypesRequest: {
    status: null,
    error: null,
  },
};

const getServiceRequestsRequestHandler = (state: ServiceRequestState): ServiceRequestState => ({
  ...state,
  getServiceRequests: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getServiceRequestsSuccessHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.getServiceRequests.success>,
): ServiceRequestState => ({
  ...state,
  serviceRequests: payload,
  getServiceRequests: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getServiceRequestsFailureHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.getServiceRequests.failure>,
): ServiceRequestState => ({
  ...state,
  getServiceRequests: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const getCurrentServiceRequestRequestHandler = (state: ServiceRequestState): ServiceRequestState => ({
  ...state,
  getCurrentServiceRequest: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getRequestsListWithReplaceRequest = (serviceRequest: ServiceRequest, serviceRequests?: ServiceRequest[]) =>
  serviceRequests?.map(request => (request.id === serviceRequest.id ? serviceRequest : request)) ?? [serviceRequest];

const getCurrentServiceRequestSuccessHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.getCurrentServiceRequest.success>,
): ServiceRequestState => {
  const isServiceRequestCreatedBehalfOf =
    payload.id === state.currentServiceRequest?.id ? state.currentServiceRequest.is_created_on_behalf_of : false;

  return {
    ...state,
    serviceRequests: isServiceRequestCreatedBehalfOf
      ? state.serviceRequests
      : getRequestsListWithReplaceRequest(payload, state.serviceRequests),
    currentServiceRequest: isServiceRequestCreatedBehalfOf ? { ...payload, is_created_on_behalf_of: true } : payload,
    getCurrentServiceRequest: {
      error: null,
      status: ACTION_STATUSES.FULFILLED,
    },
  };
};

const getCurrentServiceRequestFailureHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.getCurrentServiceRequest.failure>,
): ServiceRequestState => ({
  ...state,
  getCurrentServiceRequest: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const createServiceRequestRequestHandler = (state: ServiceRequestState): ServiceRequestState => ({
  ...state,
  createServiceRequest: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const createServiceRequestSuccessHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.createServiceRequest.success>,
): ServiceRequestState => ({
  ...state,
  serviceRequests: payload.is_created_on_behalf_of
    ? state.serviceRequests
    : [payload, ...(state.serviceRequests ?? [])],
  currentServiceRequest: payload,
  createServiceRequest: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const createServiceRequestFailureHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.createServiceRequest.failure>,
): ServiceRequestState => ({
  ...state,
  createServiceRequest: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const cancelServiceRequestRequestHandler = (state: ServiceRequestState): ServiceRequestState => ({
  ...state,
  cancelServiceRequest: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const cancelServiceRequestSuccessHandler = (state: ServiceRequestState): ServiceRequestState => {
  const updatedCurrentServiceRequest = { ...state.currentServiceRequest, status: REQUEST_STATUS.CANCELED };
  return {
    ...state,
    serviceRequests: state.serviceRequests?.map(request =>
      request.id === state.currentServiceRequest.id ? updatedCurrentServiceRequest : request,
    ),
    currentServiceRequest: { ...state.currentServiceRequest, status: REQUEST_STATUS.CANCELED },
    cancelServiceRequest: {
      error: null,
      status: ACTION_STATUSES.FULFILLED,
    },
  };
};

const cancelServiceRequestFailureHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.cancelServiceRequest.failure>,
): ServiceRequestState => ({
  ...state,
  cancelServiceRequest: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const getServiceRequestMetadataRequestHandler = (state: ServiceRequestState): ServiceRequestState => ({
  ...state,
  getServiceRequestMetadata: {
    ...state.getServiceRequestMetadata,
    status: ACTION_STATUSES.PENDING,
  },
});

const getServiceRequestMetadataSuccessHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.getServiceRequestMetadata.success>,
): ServiceRequestState => ({
  ...state,
  serviceRequestMetadata: payload,
  getServiceRequestMetadata: {
    ...state.getServiceRequestMetadata,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getServiceRequestMetadataFailureHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.getServiceRequestMetadata.failure>,
): ServiceRequestState => ({
  ...state,
  getServiceRequestMetadata: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const resetCreateServiceRequestHandler = (state: ServiceRequestState) => ({
  ...state,
  createServiceRequest: {
    ...INITIAL_STATE.createServiceRequest,
  },
});

const updateLastReadTimestampRequestHandler = (state: ServiceRequestState): ServiceRequestState => ({
  ...state,
  updateLastReadTimestampRequest: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const updateLastReadTimestampSuccessHandler = (state: ServiceRequestState): ServiceRequestState => ({
  ...state,
  updateLastReadTimestampRequest: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
  currentServiceRequest: {
    ...state.currentServiceRequest,
    has_new_messages: false,
    messages: state.currentServiceRequest.messages?.map(message => ({ ...message, read: true })),
  },
  serviceRequests: state.serviceRequests.map(serviceRequest =>
    serviceRequest.id === state.currentServiceRequest.id
      ? {
          ...serviceRequest,
          has_new_messages: false,
          messages: state.currentServiceRequest.messages?.map(message => ({ ...message, read: true })),
        }
      : serviceRequest,
  ),
});

const updateLastReadTimestampFailureHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.updateLastReadTimestampRequest.failure>,
): ServiceRequestState => ({
  ...state,
  updateLastReadTimestampRequest: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const updateMessages = (state: ServiceRequestState, messages: ServiceRequestMessage[]): ServiceRequestState => {
  const hasNewMessages = messages.some(({ read }) => !read);

  return {
    ...state,
    currentServiceRequest: {
      ...state.currentServiceRequest,
      has_new_messages: hasNewMessages,
      messages: [...messages],
    },
    serviceRequests: state.serviceRequests.map(serviceRequest =>
      serviceRequest.id === state.currentServiceRequest.id
        ? {
            ...serviceRequest,
            has_new_messages: hasNewMessages,
            messages: [...messages],
          }
        : serviceRequest,
    ),
  };
};

const createMessageRequestHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.createMessageRequest.request>,
): ServiceRequestState => ({
  ...state,
  createMessageRequest: {
    error: null,
    status: ACTION_STATUSES.PENDING,
    sendingMessageText: payload.text,
  },
});

const createMessageSuccessHandler = (
  state: ServiceRequestState,
  action: ActionType<typeof actions.createMessageRequest.success>,
): ServiceRequestState =>
  updateMessages(
    {
      ...state,
      createMessageRequest: {
        error: null,
        status: ACTION_STATUSES.FULFILLED,
        sendingMessageText: null,
      },
    },
    action.payload,
  );

const createMessageFailureHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.createMessageRequest.failure>,
): ServiceRequestState => ({
  ...state,
  createMessageRequest: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
    sendingMessageText: null,
  },
  failedMessages: {
    ...state.failedMessages,
    [payload.requestUuid]: [
      ...(state.failedMessages[payload.requestUuid] || []),
      {
        id: uuidV4(),
        text: payload.text,
        created_at: new Date().toISOString(),
      },
    ],
  },
});

const getMessagesRequestHandler = (state: ServiceRequestState): ServiceRequestState => ({
  ...state,
  getMessagesRequest: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getMessagesSuccessHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.getMessagesRequest.success>,
): ServiceRequestState =>
  updateMessages(
    {
      ...state,
      getMessagesRequest: {
        error: null,
        status: ACTION_STATUSES.FULFILLED,
      },
    },
    payload,
  );

const getMessagesFailureHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.getMessagesRequest.failure>,
): ServiceRequestState => ({
  ...state,
  getMessagesRequest: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const updateEstimateStatusRequestHandler = (state: ServiceRequestState): ServiceRequestState => ({
  ...state,
  updateEstimateStatusRequest: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const updateEstimateStatusSuccessHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.updateEstimateStatus.success>,
): ServiceRequestState => ({
  ...state,
  serviceRequests: getRequestsListWithReplaceRequest(payload, state.serviceRequests),
  currentServiceRequest: payload,
  updateEstimateStatusRequest: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const updateEstimateStatusFailureHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.updateEstimateStatus.failure>,
): ServiceRequestState => ({
  ...state,
  updateEstimateStatusRequest: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const serviceRequestsInitializedHandler = (state: ServiceRequestState) => ({
  ...state,
  serviceRequestsInitialized: true,
});

const resendFailedMessageHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.resendFailedMessage>,
): ServiceRequestState =>
  createMessageRequestHandler(
    {
      ...state,
      failedMessages: {
        ...state.failedMessages,
        [payload.requestUuid]:
          state.failedMessages[payload.requestUuid]?.filter(({ id }) => id !== payload.messageId) ?? [],
      },
    },
    { payload: { requestUuid: payload.requestUuid, text: payload.text }, type: 'CREATE_MESSAGE_REQUEST' },
  );

const showSetRecurringServiceRequestHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.updateSetRecurringServiceRequest>,
): ServiceRequestState => ({
  ...state,
  showSetRecurringServiceRequest: payload,
});

const showSetScheduleServiceRequestHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.updateSetScheduleServiceRequest>,
): ServiceRequestState => ({
  ...state,
  showSetScheduleServiceRequest: payload,
});

const showAttachmentHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.updateShowAttachment>,
): ServiceRequestState => ({
  ...state,
  showAttachment: payload,
});

const updateServiceRequestHandler = (state: ServiceRequestState): ServiceRequestState => ({
  ...state,
  updateServiceRequest: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const updateServiceRequestSuccessHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.updateServiceRequest.success>,
): ServiceRequestState => ({
  ...state,
  serviceRequests: getRequestsListWithReplaceRequest(payload, state.serviceRequests),
  currentServiceRequest: payload,
  updateServiceRequest: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const updateServiceRequestFailureHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.updateServiceRequest.failure>,
): ServiceRequestState => ({
  ...state,
  updateServiceRequest: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const submitFeedbackRequestHandler = (state: ServiceRequestState): ServiceRequestState => ({
  ...state,
  submitFeedbackRequest: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const submitFeedbackSuccessHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.submitFeedback.success>,
): ServiceRequestState => ({
  ...state,
  currentServiceRequest: {
    ...state.currentServiceRequest,
    feedback: payload,
  },
  submitFeedbackRequest: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const submitFeedbackFailureHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.submitFeedback.failure>,
): ServiceRequestState => ({
  ...state,
  submitFeedbackRequest: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const showFloorPlanPinnerHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.updateShowFloorPlanPinner>,
): ServiceRequestState => ({
  ...state,
  showFloorPlanPinner: payload,
});

const floorPlanPinActionRequestHandler = (state: ServiceRequestState): ServiceRequestState => ({
  ...state,
  floorPlanPinAction: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const floorPlanPinActionSuccessHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.addPinToFloorPlan.success | typeof actions.updateFloorPlanPin.success>,
): ServiceRequestState => ({
  ...state,
  currentServiceRequest: {
    ...state.currentServiceRequest,
    floor_plan: payload,
  },
  floorPlanPinAction: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const removePinFromFloorPlanSuccessHandler = (state: ServiceRequestState): ServiceRequestState => ({
  ...state,
  currentServiceRequest: {
    ...state.currentServiceRequest,
    floor_plan: {
      ...state.currentServiceRequest.floor_plan,
      pin: undefined,
    },
  },
  floorPlanPinAction: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const floorPlanPinActionFailureHandler = (
  state: ServiceRequestState,
  {
    payload,
  }: ActionType<
    | typeof actions.addPinToFloorPlan.failure
    | typeof actions.updateFloorPlanPin.failure
    | typeof actions.removePinFromFloorPlan.failure
  >,
): ServiceRequestState => ({
  ...state,
  floorPlanPinAction: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const showFeedHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.updateShowFeed>,
): ServiceRequestState => ({
  ...state,
  showFeed: payload,
});

const resetUiFlagsHandler = (state: ServiceRequestState): ServiceRequestState => ({
  ...state,
  showSetRecurringServiceRequest: false,
  showSetScheduleServiceRequest: false,
  showAttachment: {
    ...state.showAttachment,
    attachmentOpened: false,
  },
  showFloorPlanPinner: false,
  showFeed: false,
});

const getSecondaryTypesRequestHandler = (state: ServiceRequestState): ServiceRequestState => ({
  ...state,
  getSecondaryTypesRequest: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getSecondaryTypesSuccessHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.getSecondaryTypes.success>,
): ServiceRequestState => ({
  ...state,
  serviceRequestMetadata: {
    ...state.serviceRequestMetadata,
    secondaryTypes: payload.secondary_types,
  },
  getSecondaryTypesRequest: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getSecondaryTypesFailureHandler = (
  state: ServiceRequestState,
  { payload }: ActionType<typeof actions.getSecondaryTypes.failure>,
): ServiceRequestState => ({
  ...state,
  getSecondaryTypesRequest: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const clearSecondaryTypesHandler = (state: ServiceRequestState): ServiceRequestState => ({
  ...state,
  serviceRequestMetadata: {
    ...state.serviceRequestMetadata,
    secondaryTypes: [],
  },
});

const ServiceRequestsReducer = createReducer(INITIAL_STATE)
  .handleAction(actions.getServiceRequests.request, getServiceRequestsRequestHandler)
  .handleAction(actions.getServiceRequests.success, getServiceRequestsSuccessHandler)
  .handleAction(actions.getServiceRequests.failure, getServiceRequestsFailureHandler)
  .handleAction(actions.getCurrentServiceRequest.request, getCurrentServiceRequestRequestHandler)
  .handleAction(actions.getCurrentServiceRequest.success, getCurrentServiceRequestSuccessHandler)
  .handleAction(actions.getCurrentServiceRequest.failure, getCurrentServiceRequestFailureHandler)
  .handleAction(actions.createServiceRequest.request, createServiceRequestRequestHandler)
  .handleAction(actions.createServiceRequest.success, createServiceRequestSuccessHandler)
  .handleAction(actions.createServiceRequest.failure, createServiceRequestFailureHandler)
  .handleAction(actions.cancelServiceRequest.request, cancelServiceRequestRequestHandler)
  .handleAction(actions.cancelServiceRequest.success, cancelServiceRequestSuccessHandler)
  .handleAction(actions.cancelServiceRequest.failure, cancelServiceRequestFailureHandler)
  .handleAction(actions.getServiceRequestMetadata.request, getServiceRequestMetadataRequestHandler)
  .handleAction(actions.getServiceRequestMetadata.success, getServiceRequestMetadataSuccessHandler)
  .handleAction(actions.getServiceRequestMetadata.failure, getServiceRequestMetadataFailureHandler)
  .handleAction(actions.resetCreateServiceRequest, resetCreateServiceRequestHandler)
  .handleAction(actions.updateLastReadTimestampRequest.request, updateLastReadTimestampRequestHandler)
  .handleAction(actions.updateLastReadTimestampRequest.success, updateLastReadTimestampSuccessHandler)
  .handleAction(actions.updateLastReadTimestampRequest.failure, updateLastReadTimestampFailureHandler)
  .handleAction(actions.resendFailedMessage, resendFailedMessageHandler)
  .handleAction(actions.createMessageRequest.request, createMessageRequestHandler)
  .handleAction(actions.createMessageRequest.success, createMessageSuccessHandler)
  .handleAction(actions.createMessageRequest.failure, createMessageFailureHandler)
  .handleAction(actions.getMessagesRequest.request, getMessagesRequestHandler)
  .handleAction(actions.getMessagesRequest.success, getMessagesSuccessHandler)
  .handleAction(actions.getMessagesRequest.failure, getMessagesFailureHandler)
  .handleAction(actions.initializeServiceRequests, serviceRequestsInitializedHandler)
  .handleAction(actions.updateEstimateStatus.request, updateEstimateStatusRequestHandler)
  .handleAction(actions.updateEstimateStatus.success, updateEstimateStatusSuccessHandler)
  .handleAction(actions.updateServiceRequest.request, updateServiceRequestHandler)
  .handleAction(actions.updateServiceRequest.success, updateServiceRequestSuccessHandler)
  .handleAction(actions.updateServiceRequest.failure, updateServiceRequestFailureHandler)
  .handleAction(actions.updateEstimateStatus.failure, updateEstimateStatusFailureHandler)
  .handleAction(actions.updateSetRecurringServiceRequest, showSetRecurringServiceRequestHandler)
  .handleAction(actions.updateSetScheduleServiceRequest, showSetScheduleServiceRequestHandler)
  .handleAction(actions.updateShowAttachment, showAttachmentHandler)
  .handleAction(actions.updateShowFloorPlanPinner, showFloorPlanPinnerHandler)
  .handleAction(actions.addPinToFloorPlan.request, floorPlanPinActionRequestHandler)
  .handleAction(actions.addPinToFloorPlan.success, floorPlanPinActionSuccessHandler)
  .handleAction(actions.addPinToFloorPlan.failure, floorPlanPinActionFailureHandler)
  .handleAction(actions.updateFloorPlanPin.request, floorPlanPinActionRequestHandler)
  .handleAction(actions.updateFloorPlanPin.success, floorPlanPinActionSuccessHandler)
  .handleAction(actions.updateFloorPlanPin.failure, floorPlanPinActionFailureHandler)
  .handleAction(actions.removePinFromFloorPlan.request, floorPlanPinActionRequestHandler)
  .handleAction(actions.removePinFromFloorPlan.success, removePinFromFloorPlanSuccessHandler)
  .handleAction(actions.removePinFromFloorPlan.failure, floorPlanPinActionFailureHandler)
  .handleAction(actions.updateShowFeed, showFeedHandler)
  .handleAction(actions.resetUiFlags, resetUiFlagsHandler)
  .handleAction(actions.submitFeedback.request, submitFeedbackRequestHandler)
  .handleAction(actions.submitFeedback.success, submitFeedbackSuccessHandler)
  .handleAction(actions.submitFeedback.failure, submitFeedbackFailureHandler)
  .handleAction(actions.getSecondaryTypes.request, getSecondaryTypesRequestHandler)
  .handleAction(actions.getSecondaryTypes.success, getSecondaryTypesSuccessHandler)
  .handleAction(actions.getSecondaryTypes.failure, getSecondaryTypesFailureHandler)
  .handleAction(actions.clearSecondaryTypes, clearSecondaryTypesHandler);

export default ServiceRequestsReducer;
