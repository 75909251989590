import styled from 'styled-components';

export interface LoadingContainerProps {
  isLoading?: boolean;
  isIframeLoading?: boolean;
}

export const Container = styled.div<LoadingContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ isLoading }) => isLoading && 'center'};
  height: ${({ isIframeLoading }) => (isIframeLoading ? '100%' : '100vh')};
`;
