import { createAction, createAsyncAction } from 'typesafe-actions';

import { UploadFilesSuccessResponse } from 'store/files/types';
import { FailureActionPayload } from 'store/errors/types';

export const uploadFiles = createAsyncAction(
  'UPLOAD_FILES_REQUEST',
  'UPLOAD_FILES_REQUEST_SUCCESS',
  'UPLOAD_FILES_REQUEST_FAILURE',
)<FormData, UploadFilesSuccessResponse, FailureActionPayload>();

export const resetFiles = createAction('RESET_FILES_REQUEST')();
