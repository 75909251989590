import { track } from '@hqo/web-tracking';
import { UserEvents } from 'shared/consts/user-events';

export const requestSelectTrack = (request: ServiceRequest) => {
  track(UserEvents.REQUEST_SELECTED, {
    type: 'action',
    request_status: request.status,
    request_type: request.request_type,
    request_uuid: request.id,
  });
};

export const messageSubmittedTrack = (request: ServiceRequest) => {
  track(
    UserEvents.REQUEST_MESSAGE_SUBMITTED,
    { type: 'action', request_type: request.request_type, request_uuid: request.id },
    { sendToPendo: true, sendToHqoTracking: false },
  );
};

export const messageFailedTrack = (request: ServiceRequest) => {
  track(UserEvents.REQUEST_MESSAGE_FAILED, {
    type: 'impression',
    request_type: request.request_type,
    request_uuid: request.id,
  });
};

export const openRecurringStep = () => {
  track(
    UserEvents.RECURRING_WORK_ORDER_SELECTED,
    {
      type: 'action',
    },
    { sendToPendo: true, sendToHqoTracking: false },
  );
};

export const openScheduleStep = () => {
  track(
    UserEvents.SCHEDULED_WORK_ORDER_SELECTED,
    {
      type: 'action',
    },
    { sendToPendo: true, sendToHqoTracking: false },
  );
};
