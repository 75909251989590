import { ACTION_STATUSES, CANCELLABLE_REQUEST_STATUSES, FIELD_NAMES, REQUEST_STATUS_TO_NAME } from 'utils/constants';
import { Content, StyledForm } from './styles';
import { IntlShape, useIntl } from 'react-intl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { resetCreateServiceRequest, getSecondaryTypes } from 'store/serviceRequests/actions';
import { resetFiles } from 'store/files/actions';
import {
  selectCurrentServiceRequest,
  selectCreateServiceRequestStatus,
  selectSecondaryTypes,
  getSecondaryTypesStatus,
} from '../../../store/serviceRequests/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { ServiceRequestModalProps } from './service-request-modal-props.types';
import { useResetRequestCreation } from './use-reset-request-creation.hook';
import { useUploadFiles } from 'pages/service-requests/serviceRequestModal/use-upload-files.hook';
import { RecurringRequestFormValues } from 'pages/service-requests/serviceRequestModal/service-request-mobile-modal/recurring-request-form/recurring-request-form.hooks';
import { useFieldMetadata } from 'hooks/use-ui-metadata.hook';
import { useFlag } from 'hooks/use-flag.hook';
import { LDFlags } from 'shared/consts';
import { useScheduleData } from 'hooks/use-schedule-data';
import { StyledIconBlock } from 'pages/details/styles';
import { mapRecurringData, mapRecurringDataLabel, MappedRecurringData, MetadataFieldView } from 'utils';
import { DetailsPageLoader } from '../../details/details-loader';
import { selectUploadFilesStatus } from '../../../store/files/selectors';
import { MetadataFlags } from 'shared/consts/metadata-flags-enum';
import { ButtonVariant, FORM_FIELDS, ServiceRequestField } from 'components/service-request-form';

const mapFieldNameToPlaceHolderLocaleKey = (name: string) => `fields.${name}.placeholder`;

const convertToKey = (label: string) => label.toLowerCase().replace(/ /g, '_');

const createRecurringSwitchField = (
  intl: IntlShape,
  order: number,
  scheduleLabelData: ScheduleLabelData,
): MetadataFieldView => {
  return {
    name: 'schedule',
    type: FORM_FIELDS.SWITCH,
    required: false,
    label: intl.formatMessage({ id: 'create_recurring_request' }),
    switchDetailsLabel: scheduleLabelData.text && scheduleLabelData.title && scheduleLabelData.icon && (
      <StyledIconBlock
        dataTestId="recurring-data"
        title={scheduleLabelData?.title}
        titleType="bold"
        icon={scheduleLabelData?.icon}
        iconType="far"
        subtitle={scheduleLabelData?.text}
      />
    ),
    switchDetailsEditButtonLabel: intl.formatMessage({ id: 'edit' }),
    order,
  };
};

const createModifiedOption = (fieldName: string, intl: IntlShape, option: Option, value: string) => {
  const name =
    fieldName === 'request_type'
      ? intl.formatMessage({ id: `issueTypes.${option.name}`, defaultMessage: option.name })
      : option.name;
  return {
    id: option.id,
    form_id: option.form_id,
    hasChildren: option.has_children,
    label: name,
    name,
    value,
  };
};

const mapSecondaryIssueTypes = (secondaryIssueTypes: SecondaryRequestType[]) => {
  return secondaryIssueTypes?.map(option => ({
    id: option.id,
    label: option.name,
    name: option.name,
    value: option.name,
  }));
};

const isStatefulSwitch = (field: MetadataField, draftValue?: string | number | boolean | string[]): boolean => {
  return field.type === FORM_FIELDS.SWITCH && field.persistValueToForm && draftValue === undefined;
};

const mapMetadataField = (
  field: MetadataField,
  intl: IntlShape,
  modifiedOptions?: Option[],
  placeholderLocaleKey?: string,
  draftValue?: number | string | string[] | boolean,
  visible?: boolean,
) => {
  return {
    ...field,
    label: field.label && intl.formatMessage({ id: convertToKey(field.label), defaultMessage: field.label }),
    options: modifiedOptions ?? field.options,
    placeholder:
      placeholderLocaleKey !== null ? intl.formatMessage({ id: placeholderLocaleKey, defaultMessage: ' ' }) : null,
    value: field.value || draftValue,
    visible,
  };
};

// eslint-disable-next-line max-lines-per-function
const massageMetadataFields = (
  metadataFields: Array<MetadataField>,
  intl: IntlShape,
  draft?: Array<MetadataFieldView>,
  showOnBehalfOfField?: boolean,
  secondaryTypeOptions?: Option[],
) => {
  return metadataFields?.map(field => {
    const draftValue = draft?.find(f => f.name === field.name)?.value || undefined;
    const parentField = metadataFields.find(currentField => currentField.name === field.visibilityControlledBy);
    const visible = parentField ? !!parentField?.value : undefined;
    const placeholder = mapFieldNameToPlaceHolderLocaleKey(field.name);

    if ([FORM_FIELDS.SELECT, FORM_FIELDS.MULTILEVEL_SELECT].includes(field.type)) {
      if (field.name === FIELD_NAMES.REPORT_ON_BEHALF_OF && !showOnBehalfOfField) {
        return {};
      }

      const options: Option[] =
        field.name === FIELD_NAMES.SECONDARY_TYPE && secondaryTypeOptions?.length > 0
          ? secondaryTypeOptions
          : field.options;

      const modifiedOptions = options?.map(option =>
        createModifiedOption(field.name, intl, option, option.value?.toString()),
      );
      const singleOptionValue = modifiedOptions?.length === 1 ? modifiedOptions[0].value?.toString() : undefined;
      const value = draftValue || singleOptionValue;
      return mapMetadataField(field, intl, modifiedOptions, placeholder, value);
    }

    if (isStatefulSwitch(field, draftValue)) {
      return mapMetadataField(field, intl, null, placeholder, false);
    }

    return mapMetadataField(field, intl, null, placeholder, draftValue, visible);
  }) as Array<MetadataFieldView>;
};

const getButtonLabel = (hasRequestData: boolean, isButtonDisabled: boolean, hasNextStep: boolean) => {
  if (hasRequestData) {
    return 'cancel_request';
  }
  if (isButtonDisabled) {
    return 'enter_required_fields';
  }
  if (hasNextStep) {
    return 'continue';
  }
  return 'submit';
};
interface ScheduleLabelData {
  text?: string;
  icon?: string;
  title?: string;
}

export type NewRequestData = Record<string, string | number | File[] | MappedRecurringData>;

export interface CreateServiceRequestStepProps extends ServiceRequestModalProps {
  onFormIdSelected?: (fieldName: string, formId: string | null) => void;
  handleStepSubmit: (data: NewRequestData) => void;
  hasNextStep?: boolean;
  openRecurringStep?: () => void;
  clearRecurringData?: () => void;
  recurringData?: null | Partial<RecurringRequestFormValues>;
}

interface CreateServiceRequestStepContentProps extends CreateServiceRequestStepProps {
  metadataFields: MetadataField[];
}

// eslint-disable-next-line max-lines-per-function
export const CreateServiceRequestStepContent = ({
  requestData,
  onFormIdSelected,
  handleStepSubmit,
  hasNextStep,
  openRecurringStep,
  clearRecurringData,
  recurringData,
  metadataFields,
}: CreateServiceRequestStepContentProps): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [attachments, setAttachments] = useState<Array<File>>();
  const [textInputFocused, setTextInputFocused] = useState<boolean>(false);
  const [fields, setFields] = useState<Array<MetadataFieldView>>([]);
  const currentServiceRequest = useSelector(selectCurrentServiceRequest);
  const { uploadFiles, fileUploaded, fileValueVariants, uploading, setUploading } = useUploadFiles(attachments);
  const createRequestStatus = useSelector(selectCreateServiceRequestStatus);
  const uploadFilesStatus = useSelector(selectUploadFilesStatus);
  const secondaryIssueTypes = useSelector(selectSecondaryTypes);
  const secondaryTypesStatus = useSelector(getSecondaryTypesStatus);
  const mappedSecondayIssueTypeOptions = mapSecondaryIssueTypes(secondaryIssueTypes);
  const history = useHistory();
  const isUiMetadataRecurringRequestsEnabled = useFieldMetadata(MetadataFlags.RECURRING_REQUEST_ENABLED);
  const showRecurringScheduleFields = useFlag(LDFlags.SHOW_RECURRING_SCHEDULED_SERVICE_REQUEST_FIELDS);
  const scheduleLabelData = useScheduleData(mapRecurringDataLabel(recurringData));
  const [submitted, setSubmitted] = useState<boolean>(false);
  const showReportOnBehalfOfField = useFlag(LDFlags.SHOW_SERVICE_REQUEST_REPORT_ON_BEHALF_OF_FIELD);
  const isRequestSubmitted = useMemo(
    () =>
      (submitted && !hasNextStep) ||
      uploadFilesStatus === ACTION_STATUSES.PENDING ||
      createRequestStatus === ACTION_STATUSES.PENDING,
    [createRequestStatus, hasNextStep, submitted, uploadFilesStatus],
  );
  const addUiFields = isUiMetadataRecurringRequestsEnabled && showRecurringScheduleFields;
  const massagedMetadataFields = massageMetadataFields(
    metadataFields,
    intl,
    fields,
    showReportOnBehalfOfField,
    mappedSecondayIssueTypeOptions,
  );

  const onClear = () => {
    setAttachments(null);
  };
  // eslint-disable-next-line max-lines-per-function
  useEffect(() => {
    if (requestData) {
      const fieldsWithValues = massagedMetadataFields.map(field => {
        const dataValue = requestData[field.name as keyof ServiceRequest];
        if (field.type === FORM_FIELDS.SELECT) {
          const selectedItem = field.options.find(option => option.value === dataValue.toString());
          return {
            ...field,
            value: selectedItem?.value ?? dataValue,
          };
        }
        if (field.type === FORM_FIELDS.FILE) {
          return {
            ...field,
            value: dataValue?.length ? dataValue.map((file: { path: string }) => file.path) : [],
          };
        }
        return {
          ...field,
          value: dataValue,
        };
      });
      setFields(fieldsWithValues);
    } else if (!addUiFields) {
      setFields(massagedMetadataFields);
    } else {
      const maxOrder = Math.max(...massagedMetadataFields.map(field => field.order));
      const switchField = createRecurringSwitchField(intl, maxOrder, scheduleLabelData);
      setFields([...massagedMetadataFields, switchField]);
    }
    // we don't want the useEffect triggered when scheduleLabelData is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUiFields, intl, metadataFields, recurringData, requestData]);

  useEffect(() => {
    if (createRequestStatus === ACTION_STATUSES.FULFILLED && submitted) {
      onClear();
      history.replace(`/service-requests/${currentServiceRequest.id}/view`);
      dispatch(resetCreateServiceRequest());
      dispatch(resetFiles());
    }
  }, [createRequestStatus, currentServiceRequest, dispatch, history, submitted]);

  const isCancellable =
    requestData && CANCELLABLE_REQUEST_STATUSES.includes(REQUEST_STATUS_TO_NAME[requestData.status]);

  const submitServiceRequestForm = useCallback(() => {
    const newRequestData: NewRequestData = {};
    fields.forEach((field: MetadataFieldView) => {
      if (field.type === FORM_FIELDS.SELECT) {
        // The fields options value is a string. Find the original value from metadataFields and assign it to the newRequestData.
        const selectedField = massagedMetadataFields.find(metaField => metaField.name === field.name);
        const selectedOption = selectedField?.options?.find(option => option?.value?.toString() === field.value);
        if (selectedOption) {
          newRequestData[field.name] = selectedOption.value;
        }
      } else if (field.type === FORM_FIELDS.TEXT) {
        newRequestData[field.name] = field.value?.toString()?.trim();
      } else if (field.type === FORM_FIELDS.FILE && fileUploaded) {
        newRequestData[field.name] = fileValueVariants;
      } else {
        newRequestData[field.name] = field.value as string | number;
      }
    });
    newRequestData.schedule = mapRecurringData(recurringData);

    handleStepSubmit(newRequestData);
  }, [fields, recurringData, handleStepSubmit, fileUploaded, massagedMetadataFields, fileValueVariants]);

  const handleOnSubmit = useCallback(() => {
    setSubmitted(true);
    if (attachments?.length) {
      uploadFiles();
    } else {
      submitServiceRequestForm();
    }
  }, [attachments?.length, setSubmitted, submitServiceRequestForm, uploadFiles]);

  useEffect(() => {
    if (uploadFilesStatus === ACTION_STATUSES.FULFILLED && uploading) {
      setUploading(false);
      submitServiceRequestForm();
    }
  }, [submitServiceRequestForm, uploading, uploadFilesStatus, setUploading]);

  useEffect(() => {
    if (secondaryTypesStatus === ACTION_STATUSES.FULFILLED) {
      setFields([...massagedMetadataFields]);
    }
    // the messagedMetadataFields dependency causes unwanted infinite rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondaryTypesStatus]);

  useResetRequestCreation(
    (uploadFilesStatus === ACTION_STATUSES.REJECTED || createRequestStatus === ACTION_STATUSES.REJECTED) && submitted,
  );

  const onCancelRequest = useCallback(() => {
    history.push(`/service-requests/${currentServiceRequest.id}/cancel`);
  }, [currentServiceRequest, history]);

  const onUpload = useCallback((files: Array<File>) => {
    setAttachments(files);
  }, []);

  const isButtonDisabled =
    fields?.some(field => {
      if (field.required && field.type === FORM_FIELDS.FILE) {
        return !attachments || attachments.length === 0;
      }
      if (field.required) {
        return !field.value;
      }
      return false;
    }) || createRequestStatus === ACTION_STATUSES.PENDING;

  const handleSelectChange = useCallback(
    (name: string, value?: string) => {
      const updatedFields = fields.map(field => {
        if (name === FIELD_NAMES.REQUEST_TYPE && field.name === FIELD_NAMES.SECONDARY_TYPE) {
          dispatch(getSecondaryTypes.request(value));
          return { ...field, value: null, options: mappedSecondayIssueTypeOptions };
        }

        if (field.name === name) {
          return { ...field, value };
        }
        return field;
      });

      if (onFormIdSelected) {
        const targetField = fields.find(field => field.name === name);
        const selectedOption = targetField?.options?.find(option => option?.value === value);
        onFormIdSelected(targetField?.name, selectedOption?.form_id ?? null);
      }

      setFields(updatedFields);
    },
    [fields, onFormIdSelected, dispatch, mappedSecondayIssueTypeOptions],
  );

  const handleInputChange = useCallback(
    (name: string, value?: string) => {
      const updatedFields = fields.map(field => {
        if (field.name === name) {
          return { ...field, value: value?.toString() };
        }
        return field;
      });
      setFields(updatedFields);
    },
    [fields],
  );

  const onTextInputFocus = useCallback(() => setTextInputFocused(true), []);

  const onTextInputBlur = useCallback(() => {
    const textFieldsHaveValue = fields?.every(field => {
      if (field?.type === FORM_FIELDS.TEXT) {
        return !!field.value?.toString()?.trim();
      }
      return true;
    });
    // eslint-disable-next-line no-unused-expressions
    textFieldsHaveValue && setTextInputFocused(false);
  }, [fields]);

  const onRecurrenceSwitchChange = useCallback(
    (name: string, value: boolean) => {
      if (name === FIELD_NAMES.SCHEDULE) {
        if (value && !recurringData) {
          openRecurringStep();
        } else {
          clearRecurringData();
        }
      }
    },
    [recurringData, openRecurringStep, clearRecurringData],
  );

  const onStatefulSwitchChange = useCallback(
    name => {
      const updatedFields = fields.map(field => {
        if (field.name === name) {
          return { ...field, value: !field.value };
        }

        if (field.visibilityControlledBy === name) {
          const parentField = fields.find(currentField => currentField.name === field.visibilityControlledBy);
          return { ...field, visible: !parentField?.value };
        }

        return field;
      });
      setFields(updatedFields);
    },
    [fields],
  );

  const onSwitchDetailsEdit = useCallback(
    (name: string) => {
      if (name === 'schedule') {
        openRecurringStep();
      }
    },
    [openRecurringStep],
  );

  return isRequestSubmitted ? (
    <DetailsPageLoader />
  ) : (
    <Content>
      {!!fields?.length && (
        <StyledForm
          title={
            requestData
              ? intl.formatMessage({ id: 'request_been_submitted' })
              : intl.formatMessage({ id: 'new_request' })
          }
          subtitle={intl.formatMessage({
            id: requestData ? 'receive_email_when_status_updated' : 'please_provide_requested_info',
          })}
          fields={fields as ServiceRequestField[]}
          buttonLabel={intl.formatMessage({ id: getButtonLabel(!!requestData, isButtonDisabled, hasNextStep) })}
          handleInputChange={handleInputChange}
          handleSelectChange={handleSelectChange}
          onFileDelete={onUpload}
          onFileUpload={onUpload}
          onSubmit={isCancellable ? onCancelRequest : handleOnSubmit}
          onTextInputFocus={onTextInputFocus}
          onTextInputBlur={onTextInputBlur}
          buttonDisabled={isButtonDisabled}
          readOnly={!!requestData}
          statusText={requestData ? `${intl.formatMessage({ id: 'status' })}:` : undefined}
          statusMessage={requestData && intl.formatMessage({ id: `status_display.${requestData.status_display}` })}
          buttonVariant={requestData ? ButtonVariant.SECONDARY : ButtonVariant.PRIMARY}
          hideActionButton={requestData && !isCancellable}
          isTextFocused={textInputFocused}
          onRecurrenceSwitchChange={onRecurrenceSwitchChange}
          onSwitchDetailsEdit={onSwitchDetailsEdit}
          onStatefulSwitchChange={onStatefulSwitchChange}
        />
      )}
    </Content>
  );
};
