import { Container, StatusCol, StatusIndicator, StatusIndicatorWrapper, TitleText, StyledIconBlock } from './styles';

import React from 'react';
import { useTileScheduleData } from './use-tile-schedule-data.hook';
import { StatusPillWrapper } from 'components/service-request-tile/status-pill-wrapper';

interface ServiceRequestTileProps {
  title: string;
  onClick: React.MouseEventHandler<HTMLElement>;
  location?: string;
  request: ServiceRequest;
  showStatus?: boolean;
}

// eslint-disable-next-line max-lines-per-function
export const ServiceRequestTile = ({
  title,
  location,
  showStatus,
  request,
  onClick,
}: ServiceRequestTileProps): JSX.Element => {
  const scheduleFrequency = request.is_scheduled ? request.frequency : request.schedule?.frequency;
  const { text, icon } = useTileScheduleData(scheduleFrequency);
  const locationSubtitle = request.location?.map(l => l.name).join(', ');

  return (
    <Container data-testid="booking-tile-container" onClick={onClick} data-cy="service-request-tile">
      <div>
        <TitleText>{title}</TitleText>
        <StyledIconBlock
          titleType="bold"
          onClick={onClick as VoidFunction}
          icon="map-marker-alt"
          title={location}
          iconType="far"
          subtitle={locationSubtitle}
        />
        {scheduleFrequency && (
          <StyledIconBlock
            dataTestId="schedule-data"
            onClick={onClick as VoidFunction}
            icon={icon}
            iconType="far"
            title={text}
          />
        )}
      </div>
      <StatusCol>
        <StatusPillWrapper status={request.status_display} />
        {showStatus && (
          <StatusIndicatorWrapper data-testid="booking-tile-status-indicator">
            <StatusIndicator />
          </StatusIndicatorWrapper>
        )}
      </StatusCol>
    </Container>
  );
};
