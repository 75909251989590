import { MutableRefObject, useEffect, useRef } from 'react';

const UNREAD_MESSAGES_SELECTOR = '[data-messagereadstatus="false"]';

const getTargetElement = (
  messagesContainerRef: MutableRefObject<HTMLDivElement | null>,
  isSkeletonVisible: boolean,
) => {
  if (isSkeletonVisible) {
    return messagesContainerRef.current?.firstElementChild;
  }

  const unreadMessages = messagesContainerRef.current?.querySelectorAll(UNREAD_MESSAGES_SELECTOR);

  return unreadMessages?.item((unreadMessages?.length ?? 0) - 1) ?? messagesContainerRef.current?.firstElementChild;
};

const scrollMessages = (messagesContainerRef: MutableRefObject<HTMLDivElement | null>, isSkeletonVisible: boolean) => {
  getTargetElement(messagesContainerRef, isSkeletonVisible)?.scrollIntoView({ behavior: 'smooth' });
};

export const useMessagesAutoScroll = (messagesCount: number, isVisible: boolean, isSkeletonVisible: boolean) => {
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isVisible) {
      scrollMessages(messagesContainerRef, isSkeletonVisible);
    }
  }, [isVisible, messagesContainerRef, messagesCount, isSkeletonVisible]);

  return messagesContainerRef;
};
