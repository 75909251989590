import { ApiClient } from './api-client';
import { RootState } from 'store/reducer';

export const apiClient = (
  state: RootState,
  baseUrlOverride?: string | null,
  authTokenOverride?: string | null,
): ApiClient => {
  const { buildingUuid, apiUrl, authToken } = state.config;
  const baseUrl = baseUrlOverride ?? apiUrl;
  const token = authTokenOverride ?? authToken;
  const altBuildingUuid = state.altBuildings?.currentAltBuilding?.alt_building_uuid;

  return new ApiClient(baseUrl, token, buildingUuid, altBuildingUuid);
};
