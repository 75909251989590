import { HqoPicker } from '@hqo/react-components-library/dist/molecules/hqo-picker';
import { Item } from '@hqo/react-components-library/dist/molecules/wheel-picker-view';
import { usePicker } from './use-picker.hook';
import { Container, WheelPickerStyled } from './styles';
import { IntlShape, useIntl } from 'react-intl';
import { WHEEL_PICKER_ITEM_HEIGHT } from 'utils/constants';

interface PickerProps {
  items: Item<string>[];
  label?: string;
  placeholder?: string;
  required?: boolean;
  value: string;
  onChange: (value: string) => void;
  error?: string;
}

export const generatePickerItem = (intl: IntlShape, translationPath: string) => (value: string) => ({
  value,
  label: intl.formatMessage({ id: `${translationPath}.${value}` }),
});

export const Picker = ({ items, label, placeholder, required, value, onChange, error }: PickerProps): JSX.Element => {
  const intl = useIntl();
  const { isPickerActive, pickerLabel, selectedValue, onItemClick, closePicker, tooglePickerActive, wheelPickerRef } =
    usePicker(items, value);

  return (
    <Container ref={wheelPickerRef}>
      <HqoPicker
        label={label}
        placeholder={placeholder}
        isActive={isPickerActive}
        value={pickerLabel}
        onClick={tooglePickerActive}
        required={required}
        error={error}
      />
      {isPickerActive && (
        <WheelPickerStyled<string>
          itemHeight={WHEEL_PICKER_ITEM_HEIGHT}
          defaultValue={selectedValue}
          items={items}
          onChange={onChange}
          onItemClick={onItemClick}
          outsideClickParams={{ onClickOutside: closePicker, outsideClickRef: wheelPickerRef }}
          doneButton={intl.formatMessage({ id: 'done' })}
        />
      )}
    </Container>
  );
};
