import { ACTION_STATUSES, FontLocation } from 'utils/constants';
/* eslint-disable max-lines-per-function */
import {
  Container,
  EmptyContainer,
  HeaderContainer,
  ListContainer,
  MobileButton,
  MobileButtonContainer,
  StyledLink,
  TabsContainer,
  WebButton,
} from './styles';
import {
  getCurrentServiceRequest,
  getServiceRequestMetadata,
  getServiceRequests,
} from '../../store/serviceRequests/actions';
import {
  selectClosedServiceRequests,
  selectCurrentServiceRequest,
  selectOpenServiceRequests,
  selectServiceRequestMetadataStatus,
  selectServiceRequestsStatus,
  serviceRequestsSelector,
} from '../../store/serviceRequests/selectors';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { CancelServiceRequestModal } from 'pages/service-requests/cancelServiceRequestModal';
import { EmptyStateTw } from '@hqo/react-components-library/dist/molecules/empty-state/emptyState';
import { ErrorRequestModal } from 'pages/service-requests/errorRequestModal';
import { PageTitle } from '@hqo/react-components-library/dist/page-header';
import { RequestSkeletonLoader } from '../../components/request-skeleton-loader.tsx/component';
import { ServiceRequestListItem } from 'components/service-request-list-item';
import { ServiceRequestModal } from './serviceRequestModal';
import { ToggleBlock } from '@hqo/react-components-library/dist/molecules/toggle-block';
import { UserEvents } from '../../shared/consts/user-events';
import { track } from '@hqo/web-tracking';
import { useIntl } from 'react-intl';
import { useThemeFont } from 'hooks/use-theme-font.hook';
import { useFieldMetadata } from 'hooks/use-ui-metadata.hook';
import { MetadataFlags } from 'shared/consts/metadata-flags-enum';

const renderEmptyState = (loading: boolean, length: number, title: string, description: string): JSX.Element =>
  !loading && !length ? (
    <EmptyContainer>
      <EmptyStateTw descriptions={[description]} icon="wrench" title={title} />
    </EmptyContainer>
  ) : null;

const renderLoading = (loading: boolean, requests: boolean) =>
  loading && !requests ? <RequestSkeletonLoader data-testid="request-skeleton-loader" /> : null;

const renderRequests = (
  serviceRequests: Array<ServiceRequest>,
  isFeedEnabled: boolean,
  redirectToDetailsView: (id: string) => void,
) =>
  serviceRequests.length
    ? serviceRequests.map((request: ServiceRequest, index: number) => (
        <ServiceRequestListItem
          showStatus={isFeedEnabled && request.has_new_messages}
          key={request.id}
          request={request}
          redirectToDetailsView={redirectToDetailsView}
          isLastItem={index === serviceRequests.length - 1}
        />
      ))
    : null;

export const ServiceRequests = (): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { requestId } = useParams<{ requestId: string }>();
  const themeFontTitle = useThemeFont(FontLocation.TITLE);

  const matchNewView = useRouteMatch('/service-requests/new');
  const matchErrorView = useRouteMatch('/service-requests/error');

  const { url, path } = useRouteMatch();
  const matchCancelPath = path === '/service-requests/:requestId/cancel';
  const history = useHistory();
  const [isServiceRequestFormVisible, setServiceRequestFormVisible] = useState<boolean>(false);
  const [isLeftTabActive, setIsLeftTabActive] = useState<boolean>(true);
  const [isCreateErrorVisible, setIsCreateErrorVisible] = useState<boolean>();
  const [currentRequestData, setCurrentRequestData] = useState<ServiceRequest>(undefined);
  const serviceRequestMetadataStatus = useSelector(selectServiceRequestMetadataStatus);
  const serviceRequestStatus = useSelector(selectServiceRequestsStatus);
  const currentServiceRequests = useSelector(isLeftTabActive ? selectOpenServiceRequests : selectClosedServiceRequests);
  const currentServiceRequest = useSelector(selectCurrentServiceRequest);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const redirectToDetailsView = useCallback(
    (serviceRequestId: string) => {
      history.push(`/service-requests/${serviceRequestId}/view`);
    },
    [history],
  );
  const loading = serviceRequestStatus === ACTION_STATUSES.PENDING;

  // remove lines 106-109 in MAIN-34943
  const messageSendingEnabled = useFieldMetadata(MetadataFlags.MESSAGE_SENDING_ENABLED);
  const messageFeedEnabled = useFieldMetadata(MetadataFlags.MESSAGE_FEED_ENABLED);
  const isFeedEnabled = messageSendingEnabled || messageFeedEnabled;

  /** If there's a requestId from the url params and it doesn't match cancel path, call getCurrentServiceRequest dispatch */
  useEffect(() => {
    if (requestId && !matchCancelPath) {
      dispatch(getCurrentServiceRequest.request(requestId));
      setServiceRequestFormVisible(true);
    }
  }, [dispatch, matchCancelPath, requestId]);

  useEffect(() => {
    if (requestId === currentServiceRequest?.id) {
      setCurrentRequestData(currentServiceRequest);
    }
  }, [currentServiceRequest, isServiceRequestFormVisible, requestId]);

  useEffect(() => {
    if (matchNewView) {
      setCurrentRequestData(undefined);
      setServiceRequestFormVisible(true);
    }
  }, [matchNewView]);

  useEffect(() => {
    if (matchCancelPath) {
      setIsCancelModalVisible(true);
    }
  }, [matchCancelPath]);

  useEffect(() => {
    if (matchErrorView) {
      setIsCreateErrorVisible(true);
    }
  }, [matchErrorView]);

  const handleTabChange = useCallback(
    (isLeftTab: boolean) => () => setIsLeftTabActive(isLeftTab),
    [setIsLeftTabActive],
  );

  const addRequestClickTrack = useCallback(() => {
    track(UserEvents.ADD_REQUEST_CLICK, { type: 'action' });
  }, []);

  useEffect(() => {
    if (serviceRequestMetadataStatus == null) {
      dispatch(getServiceRequestMetadata.request());
    }

    if (serviceRequestStatus == null) {
      dispatch(getServiceRequests.request());
    }

    setCurrentRequestData(undefined);
    // We only want this to run on initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayServiceRequestModal = isServiceRequestFormVisible && !isCancelModalVisible && !isCreateErrorVisible;
  const openLabel = intl.formatMessage({ id: 'open' });
  const closedLabel = intl.formatMessage({ id: 'closed' });
  const hasRequests = !!useSelector(serviceRequestsSelector)?.length;

  return (
    <Container>
      <HeaderContainer>
        <PageTitle data-cy="service-requests" font={themeFontTitle}>
          {intl.formatMessage({ id: 'service_requests' })}
        </PageTitle>
        <StyledLink to={`${url}/new`}>
          <WebButton
            size="md"
            text={intl.formatMessage({ id: 'new_request' })}
            variant="primary"
            onClick={addRequestClickTrack}
            data-cy="new-request-button-web"
          />
        </StyledLink>
      </HeaderContainer>
      <TabsContainer>
        <ToggleBlock
          leftLabel={openLabel}
          rightLabel={closedLabel}
          isLeftActive={isLeftTabActive}
          isLeftEnabled
          isRightEnabled
          onLeftLabelClick={handleTabChange(true)}
          onRightLabelClick={handleTabChange(false)}
        />
      </TabsContainer>
      <ListContainer>
        {renderLoading(loading, hasRequests)}
        {renderRequests(currentServiceRequests, isFeedEnabled, redirectToDetailsView)}
        {renderEmptyState(
          loading,
          currentServiceRequests.length,
          intl.formatMessage({ id: 'no_service_requests' }),
          intl.formatMessage(
            { id: 'no_request_submit_new_request' },
            {
              currentTab: isLeftTabActive ? openLabel.toLowerCase() : closedLabel.toLowerCase(),
            },
          ),
        )}
      </ListContainer>
      <MobileButtonContainer>
        <StyledLink to={`${url}/new`}>
          <MobileButton
            text={intl.formatMessage({ id: 'new_request' })}
            variant="primary"
            size="md"
            onClick={addRequestClickTrack}
            data-cy="new-request-button-mobile"
          />
        </StyledLink>
      </MobileButtonContainer>
      {displayServiceRequestModal && (
        <ServiceRequestModal key={currentRequestData?.id} requestData={currentRequestData} />
      )}
      {isCreateErrorVisible && <ErrorRequestModal />}
      {isCancelModalVisible && <CancelServiceRequestModal />}
    </Container>
  );
};
