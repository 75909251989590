import { formatCurrency, formatCurrencyNoSign } from 'utils/formatCurrency';

import { isFloat } from 'utils/numberFormatting';

export const useEstimateFormatting = (estimate: Estimate, locale: string) => {
  const currencyType = 'USD';
  const floatDigits = 2;
  const intDigits = 0;
  const digits = isFloat(estimate.min_estimate) || isFloat(estimate.max_estimate) ? floatDigits : intDigits;
  const minEstimate = formatCurrency(parseFloat(estimate.min_estimate), currencyType, digits, locale);
  const maxEstimate = formatCurrencyNoSign(parseFloat(estimate.max_estimate), digits, locale);
  const estimateRange =
    estimate.min_estimate === estimate.max_estimate ? `${minEstimate}` : `${minEstimate}-${maxEstimate}`;

  return estimateRange;
};
