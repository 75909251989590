import { MessagesContainer, MessagesWrapper } from 'pages/details/styles';
import {
  getMessagesRequestStatus,
  selectCurrentServiceRequest,
  selectFailedMessages,
} from 'store/serviceRequests/selectors';

import { ACTION_STATUSES } from '../../../utils/constants';
import { MessagesContent } from 'pages/details/feed-content/messages-content';
import { SendingMessageBubble } from 'pages/details/feed-content/sending-message-bubble';
import { SkeletonMessage } from 'pages/details/feed-content/skeleton-message';
import { ChatSkeletonVariants } from 'pages/details/utils';
import { createMessageRequestStatus } from '../../../store/serviceRequests/selectors';
import { useMessageResendHandler } from 'pages/details/feed-content/use-message-resend-handler.hook';
import { useMessagesAutoScroll } from './use-messages-auto-scroll.hook';
import { useReadMessages } from 'pages/details/details-mobile-page/messages.hooks';
import { useSelector } from 'react-redux';

interface MessagesProps {
  isVisible?: boolean;
}
export const Messages = ({ isVisible }: MessagesProps): JSX.Element => {
  const request = useSelector(selectCurrentServiceRequest);
  const messages = request?.messages ?? [];
  const getMessagesStatus = useSelector(getMessagesRequestStatus);
  const createRequestStatus = useSelector(createMessageRequestStatus);
  const failedMessagesMap = useSelector(selectFailedMessages);
  const showSenderSkeleton = createRequestStatus === ACTION_STATUSES.PENDING;
  const showReceiveSkeleton = getMessagesStatus === ACTION_STATUSES.PENDING;
  const messagesContainerRef = useMessagesAutoScroll(
    messages.length,
    isVisible,
    showSenderSkeleton || showReceiveSkeleton,
  );
  const failedMessages = failedMessagesMap[request?.id] ?? [];
  const resendMessage = useMessageResendHandler(request?.id);

  useReadMessages(isVisible);

  return (
    <MessagesContainer className="message-container">
      <MessagesWrapper ref={messagesContainerRef}>
        {showSenderSkeleton && <SendingMessageBubble />}
        {showReceiveSkeleton && <SkeletonMessage variant={ChatSkeletonVariants.RECEIVER} />}
        <MessagesContent messages={messages} failedMessages={failedMessages} onFailedMessageClick={resendMessage} />
      </MessagesWrapper>
    </MessagesContainer>
  );
};
