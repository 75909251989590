import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { Message } from 'pages/details/feed-content/message-bubble';
import { resendFailedMessage } from 'store/serviceRequests/actions';

export type MessageResendHandler = (message: Message) => void;

export const useMessageResendHandler = (requestUuid: string): MessageResendHandler => {
  const dispatch = useDispatch();

  return useCallback(
    (message: Message) => {
      dispatch(
        resendFailedMessage({
          messageId: message.id,
          text: message.text,
          requestUuid,
        }),
      );
    },
    [dispatch, requestUuid],
  );
};
