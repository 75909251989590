import { useCallback, useContext, useEffect, useMemo } from 'react';
import { HqoClientSdk } from '@hqo/hqo-miniapp-client-sdk';
import { MiniappSdkContext } from 'components/miniapp-sdk-provider/miniapp-sdk.context';
import { Handler } from '@hqo/hqo-miniapp-client-sdk/dist/sdk/types/sdk.interface';
import { SdkEvents } from '@hqo/hqo-miniapp-client-sdk/dist/sdk/types/sdk-handler.types';

export const useMiniappSdkClient = (app_uuid: string): HqoClientSdk =>
  useMemo(
    () =>
      new HqoClientSdk({
        app_uuid,
        develop: false,
        init_timeout: 7,
      }),
    [app_uuid],
  );

export const useMiniappSdk = (): HqoClientSdk | null => useContext(MiniappSdkContext);

export const useMiniappSdkEventHandler = (
  eventName: SdkEvents,
  handler: Handler,
  handlerDependencies: unknown[],
): void => {
  const client = useMiniappSdk();
  const eventHandler = useCallback(handler, [handler, ...handlerDependencies]);

  useEffect(() => {
    client?.on(eventName, eventHandler);
  }, [eventName, eventHandler, client]);
};
