import React from 'react';
import { Redirect } from 'react-router-dom';
import { ROOT_PATH } from 'utils/constants';
import { useSelector } from 'react-redux';
import { userState } from 'store/user/selectors';

export const DefaultRedirect: React.FC = (): JSX.Element => {
  const { user, loading } = useSelector(userState);

  if (user || loading) {
    return <Redirect to={`${ROOT_PATH}${location.search}`} />;
  }

  return <Redirect to="/error" />;
};
