import { DIMENSIONS } from '../../utils/constants';
import styled from 'styled-components';
import { FormField } from '@hqo/react-components-library/dist/atoms/form-field';
import { Button } from '@hqo/react-components-library/dist/atoms/button';

export const PageContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: 'space-between';
  margin-bottom: 40px;
  overflow-y: auto;

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    flex-direction: column;
    margin-bottom: 55px;
    justify-content: flex-start;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow-y: hidden;

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    height: 95vh;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding-left: 100px;
  width: 100%;
  height: 400px;

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    overflow-y: unset;
    height: 100%;
    margin-top: 32px;
    padding-left: 0px;
  }
  ::-webkit-scrollbar {
    display: none; /* Remove scrollbar space */ /* Optional: just make scrollbar invisible */
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledFormField = styled(FormField)`
  margin-bottom: 32px;
  .field {
    font-size: 16px;
  }
`;

export const Title = styled.div`
  font: 700 24px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const Subtitle = styled.div`
  margin-top: 16px;
  font: normal 14px/16px ${({ theme }) => theme.fonts.join()};
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  bottom: 0;
  position: absolute;
  justify-content: flex-end;
  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    justify-content: flex-start;
  }
`;

export const StyledButton = styled(Button)`
  font-size: 16px;
  min-width: 173px;
  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 100%;
    height: 48px;
  }
`;

export const StatusMessageContainer = styled.div`
  margin-bottom: 25px;
`;
