import { useMemo } from 'react';
import { useLocale } from 'hooks/use-locale.hook';
import { useTimeZone } from 'hooks/use-timezone.hook';
import { DAY_START_TIME, DAY_FINAL_TIME } from 'utils/constants';
import { formatTimeLabel, formatTimeValue } from 'utils';

export interface ScheduleTimeItem {
  label: string;
  value: string;
}

const timeIntervalInSeconds = 15 * 60;

const roundMinuteToInterval = (minute: number, interval: number): number => Math.ceil(minute / interval) * interval;

const isToday = (someDate: Date) => {
  const today = new Date();
  return today.toDateString() === someDate.toDateString();
};

const getStartTime = (date: Date | null, timeZone: string): string => {
  if (date && isToday(date)) {
    const currentDateTime = new Date();
    currentDateTime.setMinutes(roundMinuteToInterval(currentDateTime.getMinutes(), 15));

    return formatTimeValue(currentDateTime, timeZone);
  }

  return DAY_START_TIME;
};

const useTimeOptions = (): ScheduleTimeItem[] => {
  const locale = useLocale();

  return useMemo(() => {
    const timeOptions = [];
    const dateTime = new Date();
    const startDate = dateTime.getDate();
    dateTime.setHours(0, 0, 0);

    while (startDate === dateTime.getDate()) {
      timeOptions.push({
        value: formatTimeValue(dateTime),
        label: formatTimeLabel(dateTime, locale),
      });

      dateTime.setSeconds(dateTime.getSeconds() + timeIntervalInSeconds);
    }

    return timeOptions;
  }, [locale]);
};

const useTimeItems = (allTimeOptions: ScheduleTimeItem[], scheduleDate: Date | null) => {
  const timeZone = useTimeZone();
  return useMemo(() => {
    const startTime = getStartTime(scheduleDate, timeZone);
    const startTimeIndex = allTimeOptions.findIndex(({ value }) => value === startTime);
    const endTimeIndex = allTimeOptions.findIndex(({ value }) => value === DAY_FINAL_TIME);
    return allTimeOptions.slice(startTimeIndex, endTimeIndex + 1);
  }, [allTimeOptions, scheduleDate, timeZone]);
};

export const useScheduleTimeOptions = (scheduleDate: Date | null) => {
  const allTimeOptions = useTimeOptions();
  const currentTimeOptions = useTimeItems(allTimeOptions, scheduleDate);

  return { currentTimeOptions };
};

export const getHoursAndMinutes = (time: string) => {
  const [hours, minutes] = time.split(':');
  return { hours, minutes };
};
