import { FormField, FormFieldType } from '@hqo/react-components-library/dist/atoms/form-field';
import { useCallback } from 'react';

interface NumberFieldProps {
  label?: string;
  placeholder?: string;
  required?: boolean;
  value: number;
  onChange: (value: number) => void;
  errorText?: string;
}

export const NumberField = ({
  label,
  placeholder,
  required,
  value,
  onChange,
  errorText,
}: NumberFieldProps): JSX.Element => {
  const handleChange = useCallback(event => onChange(event.target.value), [onChange]);

  return (
    <FormField
      required={required}
      onChange={handleChange}
      value={value}
      label={label}
      placeholder={placeholder}
      fieldType={FormFieldType.INPUT}
      type="number"
      errorText={errorText}
      dataTestId="number-field"
    />
  );
};
