import { truncateFileName } from '@hqo/react-components-library/dist/molecules/file-upload';
import { FILE_NAME_TRUNCATED_LENGTH, MAX_FILE_NAME_LENGTH } from '@hqo/react-components-library/dist/utils';
import { FileLabel, FilesContainer, FileText } from './styles';

interface Props {
  value: string[];
  label: string;
}

export const ReadonlyFileField = ({ value, label }: Props) => (
  <>
    <FileLabel>{label}</FileLabel>
    <FilesContainer>
      {value?.map((file: string) => (
        <FileText key={file}>
          {file.length < MAX_FILE_NAME_LENGTH ? file : truncateFileName(file, FILE_NAME_TRUNCATED_LENGTH)}
        </FileText>
      ))}
    </FilesContainer>
  </>
);
