import { RecurrenceEnd } from './recurring-request-form.hooks';
import { Picker, generatePickerItem } from 'components/shared/fields/picker';
import { useIntl, IntlShape } from 'react-intl';
import { FieldWrapper } from './styles';
import { useField } from 'formik';

const getItems = (intl: IntlShape) =>
  [RecurrenceEnd.NEVER, RecurrenceEnd.OCCURRENCES_COUNT, RecurrenceEnd.DATE].map(
    generatePickerItem(intl, 'recurrence_ends_values'),
  );

interface RecurrenceEndsFieldProps {
  fieldName: string;
}

export const RecurrenceEndsField = ({ fieldName }: RecurrenceEndsFieldProps): JSX.Element => {
  const intl = useIntl();
  const [{ value }, { error, touched }, { setValue }] = useField<RecurrenceEnd>(fieldName);

  return (
    <FieldWrapper>
      <Picker
        label={intl.formatMessage({ id: 'recurrence_ends' })}
        placeholder={intl.formatMessage({ id: 'select_one' })}
        items={getItems(intl)}
        required
        value={value}
        onChange={setValue}
        error={touched && error}
      />
    </FieldWrapper>
  );
};
