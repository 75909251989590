import { ModalWrapper } from '@hqo/react-components-library/dist/molecules/modals/wrapper';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';
import styled from 'styled-components';

export const Container = styled.div`
  height: 180px;
  padding: 30px 50px 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.join()};

  @media (min-width: ${DIMENSIONS.MEDIUM}) {
    width: 480px;
    height: 280px;
    padding: 0 80px 0 32px;
  }
`;

export const Title = styled.div`
  font: 500 24px/24px ${({ theme }) => theme.fonts.join()};
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const Label = styled.div`
  font: normal 14px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.$greyDark};
  margin-bottom: 6px;
`;

export const StyledModalWrapper = styled(ModalWrapper)`
  box-shadow: ${({ theme }) => `0px 0px 4px ${theme.colors.greys.adminBlack}33`};
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: inherit;

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 100%;
    height: 180px;
    padding: 30px 0;
  }
`;
