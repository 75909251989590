import { useIntl } from 'react-intl';
import { DateSelect } from 'components/shared/fields/date-select';
import { FieldWrapper } from './styles';
import { useField } from 'formik';

interface EndDateFieldProps {
  fieldName: string;
}

export const EndDateField = ({ fieldName }: EndDateFieldProps) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ value }, _, { setValue }] = useField<string>(fieldName);

  return (
    <FieldWrapper>
      <DateSelect
        label={intl.formatMessage({ id: 'recurrence_ends_on' })}
        placeholder={intl.formatMessage({ id: 'select_date' })}
        required
        value={value}
        onChange={setValue}
      />
    </FieldWrapper>
  );
};
