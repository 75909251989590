import { UserDialogModal } from '@hqo/react-components-library/dist/molecules/modals/userDialogModal';
import { BUTTON_VARIANT } from '@hqo/react-components-library/dist/utils';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ACTION_STATUSES } from 'utils/constants';
import { cancelServiceRequest } from 'store/serviceRequests/actions';
import { selectCancelServiceRequestStatus } from '../../../store/serviceRequests/selectors';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useGoBack } from 'hooks/use-go-back-handler.hook';

// eslint-disable-next-line max-lines-per-function
export const CancelServiceRequestModal = (): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { requestId } = useParams<{ requestId: string }>();
  const handleClose = useGoBack();
  const cancelRequestStatus = useSelector(selectCancelServiceRequestStatus);
  const [canceling, setCanceling] = useState(false);

  const handleCancelServiceRequest = useCallback(() => {
    dispatch(cancelServiceRequest.request(requestId));
    setCanceling(true);
  }, [requestId, dispatch]);

  useEffect(() => {
    if (cancelRequestStatus === ACTION_STATUSES.FULFILLED && canceling) {
      handleClose();
    }
  }, [cancelRequestStatus, canceling, dispatch, handleClose]);

  return (
    <UserDialogModal
      buttons={[
        {
          handler: handleCancelServiceRequest,
          isLoading: cancelRequestStatus === ACTION_STATUSES.PENDING,
          disabled: cancelRequestStatus === ACTION_STATUSES.PENDING,
          title: intl.formatMessage({ id: 'yes_cancel' }),
          dataTestId: 'cancel-request-button',
        },
        {
          handler: handleClose,
          title: intl.formatMessage({ id: 'keep_request' }),
          disabled: cancelRequestStatus === ACTION_STATUSES.PENDING,
          variant: BUTTON_VARIANT.SECONDARY,
          dataTestId: 'keep-request-button',
        },
      ]}
      description={intl.formatMessage({
        id: 'are_you_sure_cancel_request',
      })}
      isVisible
      onClose={handleClose}
      title={intl.formatMessage({
        id: 'are_you_sure',
      })}
    />
  );
};
