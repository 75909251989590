import { ErrorNotification } from './types';
import { createAction } from 'typesafe-actions';

export const showErrorNotification = createAction('notifications/SHOW_ERROR')<Omit<ErrorNotification, 'uuid'>>();
export const hideErrorNotifications = createAction('notifications/HIDE_ERRORS')<void>();

export const showCriticalErrorNotification = createAction('notifications/SHOW_CRITICAL_ERROR')<
  Omit<ErrorNotification, 'uuid'>
>();
export const hideCriticalErrorNotifications = createAction('notifications/HIDE_CRITICAL_ERRORS')<void>();
