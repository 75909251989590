import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectMetadataFields } from 'store/serviceRequests/selectors';

const selectFieldsToAddNotApplicable = ['report_on_behalf_of'];

export const useMetadataFields = () => {
  const intl = useIntl();
  const metadataFields = useSelector(selectMetadataFields);

  return useMemo(() => {
    const notApplicableOption: MetadataField['options'][number] = {
      id: null,
      name: intl.formatMessage({ id: 'n/a' }),
      value: '',
    };

    return metadataFields?.map(metadataField => {
      if (metadataField.options && selectFieldsToAddNotApplicable.includes(metadataField.name)) {
        const updatedOptions = [notApplicableOption, ...metadataField.options];
        return { ...metadataField, options: updatedOptions };
      }

      return metadataField;
    });
  }, [intl, metadataFields]);
};
