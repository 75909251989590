export enum ServiceRequestEstimateStatusEnum {
  APPROVED = 'approved',
  DECLINED = 'declined',
}

export enum ServiceRequestFrequencyEnum {
  ONCE = 'once',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  SEMI_ANNUALLY = 'semi-annually',
  ANNUALLY = 'annual',
}

export enum StatusDisplay {
  Open = 'open',
  In_Progress = 'in_progress',
  Pending = 'pending',
  Complete = 'complete',
  Canceled = 'canceled',
  On_Hold = 'on_hold',
  Denied = 'denied',
  Failed = 'failed',
}
