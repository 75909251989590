import * as altBuildingsEpic from 'store/alt-buildings/epics';
import * as buildingEpics from 'store/building/epics';
import * as errorEpics from 'store/errors/epics';
import * as filesEpics from 'store/files/epics';
import * as serviceRequestsEpics from 'store/serviceRequests/epics';
import * as themeEpics from 'store/theme/epics';
import * as userEpics from 'store/user/epics';
import * as dynamicFormsEpics from 'store/dynamic-forms/epics';
import * as uiMetadataEpics from 'store/ui-metadata/epics';
import * as lookupDataEpics from 'store/lookup-data/epics';

import { combineEpics } from 'redux-observable';

export default combineEpics(
  ...Object.values(userEpics),
  ...Object.values(serviceRequestsEpics),
  ...Object.values(filesEpics),
  ...Object.values(buildingEpics),
  ...Object.values(altBuildingsEpic),
  ...Object.values(themeEpics),
  ...Object.values(errorEpics),
  ...Object.values(dynamicFormsEpics),
  ...Object.values(uiMetadataEpics),
  ...Object.values(lookupDataEpics),
);
