import { StateType } from 'typesafe-actions';
import altBuildingsReducer from 'store/alt-buildings/reducer';
import buildingReducer from 'store/building/reducer';
import { combineReducers } from 'redux';
import configReducer from 'store/config/reducer';
import errorsReducer from 'store/errors/reducer';
import filesReducer from 'store/files/reducer';
import routerReducer from 'store/router/reducer';
import serviceRequestsReducer from 'store/serviceRequests/reducer';
import themeReducer from 'store/theme/reducer';
import userReducer from 'store/user/reducer';
import dynamicFormsReducer from 'store/dynamic-forms/reducer';
import uiMetadataReducer from 'store/ui-metadata/reducer';
import lookupDataReducer from 'store/lookup-data/reducer';

const rootReducer = combineReducers({
  config: configReducer,
  router: routerReducer,
  serviceRequests: serviceRequestsReducer,
  user: userReducer,
  files: filesReducer,
  building: buildingReducer,
  altBuildings: altBuildingsReducer,
  errors: errorsReducer,
  theme: themeReducer,
  dynamicForms: dynamicFormsReducer,
  uiMetadata: uiMetadataReducer,
  lookupData: lookupDataReducer,
});

export type RootState = StateType<typeof rootReducer>;
export default rootReducer;
