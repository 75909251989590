import { SwipeModal as BaseswipeModal } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';

import styled from 'styled-components';

export const SwipeModal = styled(BaseswipeModal)`
  .content-wrapper {
    padding: 0;
  }

  .modal-title {
    padding: 0px 16px;
  }

  .modal-subtitle {
    padding: 16px 16px 0px;
  }

  .right-icon {
    z-index: 1;
    top: 24px;
  }
`;
