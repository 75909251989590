import { FormFieldType } from '@hqo/react-components-library/dist/atoms/form-field';
import { AdditionalFieldsProps, TextareaField as TextareaFieldProps } from '../../interfaces';
import { StyledFormField } from '../../styles';

interface Props {
  field: TextareaFieldProps;
  additionalProps: AdditionalFieldsProps;
}

export const TextareaField = ({
  field: { name, label, placeholder, value, required, visible },
  additionalProps: { readOnly, onInputChange, onTextInputFocus, onTextInputBlur },
}: Props): JSX.Element => (
  <StyledFormField
    key={name}
    label={label}
    fieldType={FormFieldType.TEXTAREA}
    required={readOnly ? false : required}
    disabled={readOnly}
    visible={visible}
    value={value}
    placeholder={placeholder}
    onChange={onInputChange(name)}
    onFocus={onTextInputFocus}
    onBlur={onTextInputBlur}
    className={`${name}-input`}
    data-testid={`${name}-textarea-field`}
  />
);
