import { uiMetadata, uiMetadataLoadingState } from 'store/ui-metadata/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { UiMetadata, ServiceRequestUiMetadata } from 'store/ui-metadata/types';
import { fetchUiMetadata } from 'store/ui-metadata/actions';
import { selectBuilding } from 'store/building/selectors';
import { OwnerTypesEnum, LDFlags } from 'shared/consts';
import { useFlag } from 'hooks/use-flag.hook';
import { useIsOnBehalfOf } from 'pages/details/details-page.hooks';
import { MetadataFlags } from 'shared/consts/metadata-flags-enum';

export const useFetchUiMetadata = (): boolean => {
  const loading = useSelector(uiMetadataLoadingState);
  const building = useSelector(selectBuilding);
  const ownerId = building?.uuid;
  const dispatch = useDispatch();
  useEffect(() => {
    if (ownerId) dispatch(fetchUiMetadata.request({ ownerId, ownerType: OwnerTypesEnum.BUILDING }));
  }, [dispatch, ownerId]);
  return loading;
};

export const useUiMetadata = (): UiMetadata | null => {
  const metadata = useSelector(uiMetadata);
  return useMemo(() => {
    if (!metadata) {
      return null;
    }
    return metadata;
  }, [metadata]);
};

export const useFieldMetadata = <K extends MetadataFlags>(key?: K): ServiceRequestUiMetadata[K] | null => {
  const metadata = useUiMetadata();

  if (!key) {
    return null;
  }

  return metadata?.ui_metadata[key] ?? null;
};

export const useFieldScheduleServiceRequest = (): boolean => {
  const isLDScheduleEnabled = useFlag(LDFlags.SHOW_RECURRING_SCHEDULED_SERVICE_REQUEST_FIELDS);
  const isUiFieldEnabled = useFieldMetadata(MetadataFlags.SCHEDULE_REQUEST_ENABLED);
  const isOnBehalfOf = useIsOnBehalfOf();

  return isLDScheduleEnabled && isUiFieldEnabled && !isOnBehalfOf;
};

export const useFloorPlanPinning = (): boolean => {
  const isLDPinningEnabled = useFlag(LDFlags.SHOW_FLOOR_PLAN_PINNING);
  const isUiFieldEnabled = useFieldMetadata(MetadataFlags.FLOOR_PLAN_PINNING_ENABLED);

  return isLDPinningEnabled && isUiFieldEnabled;
};
