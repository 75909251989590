import { Button } from '@hqo/react-components-library/dist/atoms/button';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
`;

export const SwitchBuildingButton = styled(Button)`
  font: normal 14px/16px ${({ theme }) => theme.fonts.join()};
  padding: 9px 20px;
  margin-right: 12px;
  background-color: ${({ theme }) => theme.colors.$primary};
`;

export const CloseButton = styled(Button)`
  font: normal 14px/16px ${({ theme }) => theme.fonts.join()};
  padding: 9px 20px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.$sharedGreyDark};
`;
