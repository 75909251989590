import { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { Collapsible } from '@hqo/react-components-library/dist/molecules/collapsible';
import { CollapsibleHeader, Question, Answer, FormAnswersDivider } from './styles';
import { DynamicFormDto } from '@hqo/dynamic-forms-sdk/types';
import { useFormAnswers } from './form-answers.hooks';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks';

interface FormAnswersProps {
  form: DynamicFormDto;
}

export const FormAnswers = ({ form }: FormAnswersProps): JSX.Element => {
  const intl = useIntl();
  const formAnswers = useFormAnswers(form);
  const { value: isOpened, toggle: toggleIsOpened } = useBooleanState(true);

  return (
    <>
      <FormAnswersDivider />
      <Collapsible
        title={<CollapsibleHeader>{intl.formatMessage({ id: 'additional_questions' })}</CollapsibleHeader>}
        isOpened={isOpened}
        onToggle={toggleIsOpened}
      >
        <>
          {formAnswers.map(({ question, answer }) => (
            <Fragment key={question}>
              <Question>{question}</Question>
              <Answer>{answer}</Answer>
            </Fragment>
          ))}
        </>
      </Collapsible>
    </>
  );
};
