import { IntlShape, useIntl } from 'react-intl';
import { ServiceRequestFrequencyEnum } from 'store/serviceRequests/enums';

enum RecurrenceVariant {
  SCHEDULE = 'schedule',
  RECURRENCE = 'recurrence',
}

const ICON_VARIANTS = {
  [RecurrenceVariant.SCHEDULE]: 'calendar',
  [RecurrenceVariant.RECURRENCE]: 'repeat',
};

const getScheduleVariant = (frequency: ServiceRequestFrequencyEnum) => {
  if (frequency === ServiceRequestFrequencyEnum.ONCE) {
    return RecurrenceVariant.SCHEDULE;
  }

  return RecurrenceVariant.RECURRENCE;
};

const getScheduleText = (intl: IntlShape) => intl.formatMessage({ id: 'scheduled' });

const getRecurringText = (intl: IntlShape, frequency: ServiceRequestFrequencyEnum) =>
  `${intl.formatMessage({ id: 'recurring' })} ${intl.formatMessage({ id: `frequency_values.${frequency}` })}`;

const TEXT_BUILDERS = {
  [RecurrenceVariant.SCHEDULE]: getScheduleText,
  [RecurrenceVariant.RECURRENCE]: getRecurringText,
};

export const useTileScheduleData = (frequency?: ServiceRequestFrequencyEnum) => {
  const intl = useIntl();

  if (!frequency) {
    return {};
  }
  const scheduleVariant = getScheduleVariant(frequency);

  return {
    text: TEXT_BUILDERS[scheduleVariant](intl, frequency),
    icon: ICON_VARIANTS[scheduleVariant],
  };
};
