import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';
import { LookupDataId, MappedLookupData } from 'types/lookup-data';
import { createCacheKey } from './reducer';

export const lookupDataState = (state: RootState): RootState['lookupData'] => state.lookupData;

export const selectCachedValue = (fieldName: string, primaryUuid: LookupDataId) =>
  createSelector(
    lookupDataState,
    (state: RootState['lookupData']): MappedLookupData | undefined =>
      state.cache[createCacheKey(fieldName, primaryUuid)],
  );
